import { reduxRequest } from "common/utils/reduxRequest";
import { makeFilterString } from "services/modules/utils/Helper";
import {
  GetReturnOrderSuccess,
  GetReturnOrderLoading,
  GetItemsOrderSuccess,
  SetCreateReturnRequest,
  GetReturnRequest,
  GetEmployeesSuccess,
  AssignToEmployeeSuccess,
  updateReturnRequestItemSuccess,
  getReturnRequestItemsSuccess,
  deleteReturnRequestSuccess,
  UnAssignReturnRequestDriverSuccess,
  SetOrederReadyForDeliverySuccess
} from "./Action";


export const getReturnOrderRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "return",
    successFunction: GetReturnOrderSuccess,
    url: `/admin/return_requests/return_requests${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const getReturnRequestItemsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "returnRequestItems",
    successFunction: getReturnRequestItemsSuccess,
    url: `/admin/return_requests/return_request_items/${variables?.id}?show_items=${variables?.show_items}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




export const getItemsOrderRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "items",
    successFunction: GetItemsOrderSuccess,
    url: `/admin/orders/${variables?.id}/items`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createReturnOrderRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "createReturnRequest",
    successFunction: SetCreateReturnRequest,
    action: variables?.action,
    url: `/admin/return_requests/return_requests`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const showReturnRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "details",
    successFunction: GetReturnRequest,
    url: `/admin/return_requests/return_requests/${variables?.id}?show_items=${variables?.show_items}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const getEmployeesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "listEmployees",
    successFunction: GetEmployeesSuccess,
    url: `/admin/return_requests/return_requests/${variables?.id}/employees${makeFilterString(variables?.params)}`,
    method: "get",
  }; 

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const assignToEmployeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "assignDriver",
    successFunction: AssignToEmployeeSuccess,
    url: `/admin/return_requests/return_requests/${variables?.body?.id}/assign_routes?route_type=${variables?.body?.route_type}&driver_id=${variables?.body?.driver_id}`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const unAssignReturnRequestDriverRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "unassignDriver",
    successFunction: UnAssignReturnRequestDriverSuccess,
    url: `/admin/return_requests/return_requests/${variables?.body?.request_id}/unassign_routes?route_type=${variables?.body?.route_type}&driver_id=${variables?.body?.driver_id}`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateReturnRequestItemRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "updateReturnRequest",
    successFunction: updateReturnRequestItemSuccess,
    url: `/admin/return_requests/return_request_items/${variables?.id}?show_return_request_status=true`,
    method: "patch",
    data: variables?.body,
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteReturnRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "cancel",
    successFunction: deleteReturnRequestSuccess,
    url: `/admin/return_requests/return_requests/${variables?.id}`,
    method: "delete",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const setOrederReadyForDeliveryRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetReturnOrderLoading,
    loadingType: "readyForDelivery",
    successFunction: SetOrederReadyForDeliverySuccess,
    url: `/admin/return_requests/return_requests/${variables?.body?.request_id}/mark_ready_for_delivery?show_routes=true`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
