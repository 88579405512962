import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";


const MapDirectionsRenderer = ({ places }) => {
  const [responses, setResponses] = useState([]);
  const directionsService = new window.google.maps.DirectionsService();

  useEffect(() => {
    const calculateDirections = (origin, destination) => {
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: "DRIVING",
        },
        (result, status) => {
          if (status === "OK") {
            setResponses((prevResponses) => [...prevResponses, result]);
          } else {
            console.error("Directions request failed due to " + status);
          }
        }
      );
    };

    for (let i = 0; i < places?.length - 1; i++) {
      const origin = places?.[i];
      const destination = places?.[i + 1];
      calculateDirections(origin, destination);
    }
  }, [places]);

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <GoogleMap
        id="directions-example"
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        zoom={14}
        center={places[0]}
      >
        {places.map((place, index) => (
          <Marker
            key={index}
            position={place}
            icon={{
              url: place.icon,
            }}
            // label={(index + 1).toString()} // Display numeric label for each marker
          />
        ))}
        {responses?.map((response, index) => (
          <DirectionsRenderer
            key={index}
            options={{
              directions: response,
              polylineOptions: {
                strokeColor: "#3FABAE",
                strokeWeight: 7,
              },
              suppressMarkers: true,
            }}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default MapDirectionsRenderer;

