const wrapsExtrasTypes = {
  // WRAPPERS
  // list
  GET_WRAP_EXTRAS_SUCCESS: "GET_WRAP_EXTRAS_SUCCESS",
  WRAP_EXTRAS_LOADING: "WRAP_EXTRAS_LOADING",
  //details 
  GET_WRAP_EXTRA_DETAILS_SUCCESS: "GET_WRAP_EXTRA_DETAILS_SUCCESS",
  // create
  ADD_WRAP_EXTRA_SUCCESS: "ADD_WRAP_EXTRA_SUCCESS",
  ADD_WRAP_EXTRA_FAILED: "ADD_WRAP_EXTRA_FAILED",
  // Delete
  DELETE_WRAP_EXTRA_SUCCESS: "DELETE_WRAP_EXTRA_SUCCESS",
  DELETE_WRAP_EXTRA_FAILED: "DELETE_WRAP_EXTRA_FAILED",
  // update
  UPDATE_WRAP_EXTRA_SUCCESS: "UPDATE_WRAP_EXTRA_SUCCESS",
  UPDATE_WRAP_EXTRA_FAILED: "UPDATE_WRAP_EXTRA_FAILED",
  GET_SCROLL_WRAP_EXTRA_SUCCESS:"GET_SCROLL_WRAP_EXTRA_SUCCESS"
};

export default wrapsExtrasTypes;
