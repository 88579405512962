import { useThemePalette } from "common/hooks/theme_palette";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import React from "react";

export default function AvailableGiftCardModel({
  openModel,
  setOpenModel,
  handleUpdate,
  title,
  isAvailable,
  type,
  msg,
}) {
  const { textGreen } = useThemePalette();
  return (
    <>
      <ConfirmationModal
        open={openModel?.state}
        setOpen={setOpenModel}
        handleConfirmation={(_) => {
          handleUpdate(isAvailable);
          setOpenModel({ state: false, giftCardId: false, action: false });
        }}
        text={[`${title} ${type}`, msg]}
        textStyle={{ color: textGreen, fontSize: "32px", fontWeight: 500 }}
        confirmBtnTitle={title}
        btnStyle={{
          backgroundColor: textGreen,
          "&:hover": {
            backgroundColor: textGreen,
          },
          color: "white",
        }}
      />
    </>
  );
}
