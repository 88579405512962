import { reduxRequest } from "common/utils/reduxRequest";
import {
  AddStoreBrancheSuccess,
  DeleteStoreBrancheSuccess,
  GetStoreBrancheDetailsSuccess,
  GetStoreBrancheLoading,
  GetStoreBranchesSuccess,
  UpdateStoreBrancheSuccess,
} from "./Actions";
import { makeFilterString } from "../../utils/Helper";

//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const getStoreBranchesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetStoreBrancheLoading,
    loadingType: "listStoreBranches",
    successFunction: GetStoreBranchesSuccess,
    url: `/admin/google_cities_stores${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteStoreBranchRequest = ({ ...variables }) => {
  console.log(variables, "variables");
  const requestData = {
    isLoading: GetStoreBrancheLoading,
    successFunction: DeleteStoreBrancheSuccess,
    loadingType: "deleteStoreBranch",
    action: variables?.action,
    url: `/admin/google_cities_stores/${variables?.store_id}/${variables?.google_city_id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const addStoreBranchRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetStoreBrancheLoading,
    loadingType: "addStoreBranch",
    successFunction: AddStoreBrancheSuccess,
    action: variables?.action,
    url: `/admin/google_cities_stores`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateStoreBranchRequest = ({ ...variables }) => {
  console.log(variables, "variables");
  const requestData = {
    isLoading: GetStoreBrancheLoading,
    loadingType: "updateStoreBranch",
    successFunction: UpdateStoreBrancheSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/google_cities_stores/${variables?.store_id}/${variables?.google_city_id}`,
    action: variables?.action,
    data: variables?.body,
    method: "patch",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStoreBranchDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetStoreBrancheLoading,
    loadingType: "details",
    successFunction: GetStoreBrancheDetailsSuccess,
    url: `/admin/google_cities_stores/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
