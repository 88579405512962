import * as Yup from "yup";
export const initialValues = (data) => {
    return {
        image: data?.image,
        images: data?.media?.map(image => image.url),
        wrap_id: data?.wrap_variant?.wrap?.id,
        wrap_variant_id: data?.wrap_variant?.id,
        ribbon_color_id: data?.ribbon_color?.id,
        wrap_extra_ids: data?.wrap_extras?.map((option) => {
            return {
                label: option?.name,
                value: option?.id,
            };
        }),
        wrappable_type: data?.wrappable_type === "cart" ? 1 : 2,
        ...data?.localized_data,

    }
};
export const structure = (wrappable_type, options) => [
    {
        width: "40%",

        list: [{
            name: `image`,
            kind: "upload",
            type: 'image',
            label: "Thumbnail Photo",
            placeholder: 'test',
            text: 'JPG and PNG,',
            width: "100%",

        }]
    },
    {
        head: "",
        width: "100%",
        whiteSpace: 'wrap',
        list: [
            {
                name: `images`,
                kind: "multiUpload",
                type: 'image',
                label: "Images",
                placeholder: 'test',
                text: 'JPG and PNG,',
                width: "100%",
                whiteSpace: 'wrap'
            },]
    },


    {
        head: "",
        width: "100%",
        list: [

            {
                name: `en.name`,
                kind: "input",
                type: "text",
                label: "Suggested Wrapper Name(EN)",
                width: "47%",
                placeholder: "Suggested Wrapper Name(EN)"
            },
            {
                name: `ar.name`,
                kind: "input",
                type: "text",
                label: "Suggested Wrapper Name(AR)",
                width: "47%",
                placeholder: "Suggested Wrapper Name(AR)"
            },
            {
                name: `wrap_id`,
                kind: "select",
                type: "text",
                label: "Wrap type",
                width: "47%",
                placeholder: "Wrap type",
                // options: wrapType,
                ...options?.wraps
            },
            {
                name: `wrap_variant_id`,
                kind: "select",
                type: "text",
                label: "Wrap variant",
                width: "47%",
                placeholder: "Wrap variant",
                ...options?.wrapVarinats
            },
            {
                name: `ribbon_color_id`,
                kind: "select",
                type: "text",
                label: "Ribbon color",
                width: "47%",
                placeholder: "Ribbon color",
                ...options?.ribbonColors
            },
            {
                name: `wrap_extra_ids`,
                kind: "multiSelect",
                type: "text",
                label: "Wrap extras",
                width: "47%",
                placeholder: "Wrap extras",
                // isMulti: true,
                ...options?.wrapExtra
            },
            {
                name: `en.description`,
                kind: "input",
                type: "text",
                multiline: true,
                rows: 3,
                label: "Description(EN)",
                width: "47%",
                placeholder: "Write Description(EN)"
            },
            {
                name: `ar.description`,
                kind: "input",
                type: "text",
                multiline: true,
                rows: 3,
                label: "Description(AR)",
                width: "47%",
                placeholder: "Write Description(AR)"
            },
            {
                name: `wrappable_type`,
                kind: "select",
                type: "text",
                label: "Option Wrap Type",
                width: "47%",
                placeholder: "Choose type",
                options: wrappable_type
            },
        ],
    },
];


export const validationSchema = () =>

    Yup.object({
        image: Yup.string().required('Required'),
        images: Yup.array().required('Required'),
        wrap_id: Yup.string().required('Required'),
        wrappable_type: Yup.string().required('Required'),
        wrap_extra_ids: Yup.array().required('Required'),
        ribbon_color_id: Yup.string().required('Required'),
        en: Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),

        }),
        ar: Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),

        }),

    });