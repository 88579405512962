import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  }
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `name`,
        kind: "input",
        type: "text",
        label: "Role Title",
        width: "100%",
      },
    ],
  },
];

//! test
export const additionalStructure = ()=> {
  
}
export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required("required"),
    
  });
