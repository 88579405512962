import { Box, Stack, Typography } from '@mui/material'
import { useThemePalette } from 'common/hooks/theme_palette'
import React from 'react'

export default function ComplaintStatus({ data }) {
    const { backgroundGreen,backgroundRed,textGreen,textRed } = useThemePalette()
    return <Stack direction='row' columnGap='8px' alignedItems={'center'}  >

        <Typography  width='100px' height='24px' textAlign={'center'}   bgcolor={data?.status==='closed'?backgroundGreen:backgroundRed}
                     color={data?.status==='closed'?textGreen:textRed}
                     fontFamily='Jost'>{data?.status==='open'?'Not Resolved':'Resolved'}</Typography>
    </Stack>
}