import { Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => {
  const quantity = data?.city_variant?.reduce((acc, current) => acc + current?.count_on_hand, 0);
  return [
    {
      head: "General Details",
      icon: icons?.details,
      details: [
        
        {
          label: "Vendor",
          name: data?.store?.name,
          width: "50%",
        },
        {
          label: "SKU",
          name: data?.sku,
          width: "35%",
        },
        {
          label: "Option Type",
          name: (
            <Stack direction="row">
              {data?.optionTypesFilter?.map((option, i) => (
                <Typography>
                  {option?.name}
                  {i < data?.optionTypesFilter.length - 1 && "/"}
                </Typography>
              ))}
            </Stack>
          ),
        },
      ],
    },
    {
      head: "Prices",
      icon: icons?.fees,
      details: [
        {
          label: "Price",
          name: data?.price,
          width: "50%",
        },
        {
          label: "Price after discount",
          name: data?.price_after_discount,
          width: "35%",
        },
        {
          label: "Quantity",
          name: quantity,
          width: "20%",
        },
      ],
    },
    {
      head: "Dimensions",
      icon: icons?.dimensions,
      details: [
        {
          label: "Width",
          name: data?.width,
          width: "50%",
        },
        {
          label: "Height",
          name: data?.height,
          width: "35%",
        },
        {
          label: "Length",
          name: data?.length,
          width: "50%",
        },
        {
          label: "Weight",
          name: data?.weight,
          width: "35%",
        },
        {
          label: "Volume",
          name: data?.volume,
          width: "35%",
        },
      ],
    },
    {
      head: "Dates",
      icon: icons?.dates,
      details: [
        {
          label: "Created At",
          name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
          width: "50%",
        },
        {
          label: "Updated At",
          name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a"),
          width: "35%",
        },
      ],
    },
  ];
};
