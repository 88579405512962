import { Button, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import ModelBox from "components/Atoms/Model/Model";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isOpenViewComplain } from "services/modules/modal/Actions";
import AvailableDriversCard from "../availableDriversCard";
import { icons } from "assets/AssetHelper";

export default function ViewComplainModal({
  complaintId,
  setOpenResplveModal,
  setUpdateComplaintData,
  complaint,
  getComplaintDetails,
  isCustomer = false,
}) {
  const { viewComplain } = useSelector((state) => state?.modal);
  const { gray9, graySix, gray10, orangeTwo, green, red, textGray } =
    useThemePalette();
  const dispatch = useDispatch();
  const complaintResons =
    complaint?.route_complaint_reasons || complaint?.order_complaint_reasons;
  useEffect(() => {
    const requestData = {
      id: complaintId,
    };
    complaintId && dispatch(getComplaintDetails({ ...requestData }));
  }, []);

  const handelComplain = (_) => {
    if (complaint?.status == "open") {
      setOpenResplveModal(true);
      dispatch(isOpenViewComplain());
      setUpdateComplaintData({
        complaintId: complaintId,
        status: "closed",
      });
    } else {
      setOpenResplveModal(true);
      setUpdateComplaintData({
        complaintId: complaintId,
        status: "open",
      });
      dispatch(isOpenViewComplain());
    }
  };
  return (
    <ModelBox
      openVar={viewComplain}
      closeFunc={() => dispatch(isOpenViewComplain())}
      title={"Complain details"}
      titleStyle={{
        fontSize: { lg: "32px", xs: "18px" },
        fontWeight: "500",
        px: { lg: "36px", xs: "15px" },
      }}
    >
      <Stack gap="24px">
        <Stack gap="22px">
          <Typography color={gray9} fontSize={"14px"}>
            {isCustomer ? "Customer Details" : "Driver details"}
          </Typography>
          {complaint?.driver && (
            <AvailableDriversCard
              data={complaint?.driver}
              displayDistance={false}
            />
          )}
          {complaint?.customer && (
            <AvailableDriversCard
              data={complaint?.customer}
              displayDistance={false}
              customer={true}
            />
          )}
        </Stack>
        <Stack gap="22px" borderTop={`1px solid ${graySix}`} pt="24px">
          <Typography color={gray9} fontSize={"14px"}>
            Complain reason
          </Typography>
          {complaintResons?.map((data) => (
            <Typography fontWeight="500" color={textGray}>
              {data?.name}
            </Typography>
          ))}
        </Stack>
        <Stack gap="22px" borderTop={`1px solid ${graySix}`} pt="24px">
          <Typography color={gray9} fontSize={"14px"}>
            Your feedback
          </Typography>
          <Typography color={gray10} fontSize="18px">
            {complaint?.feedback}
          </Typography>
        </Stack>
        <Stack gap="22px" borderTop={`1px solid ${graySix}`} pt="24px">
          <Typography color={gray9} fontSize={"14px"}>
            Status
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              color={complaint?.status == "open" ? orangeTwo : green}
              fontSize="18px"
            >
              {complaint?.status == "open" ? "Not resolved  " : "Resolved"}
            </Typography>

            <Button
              onClick={handelComplain}
              variant="outlined"
              sx={{
                py: 1,
                px: 5,
                textTransform: "none",
                textAlign: "center",
                borderColor: complaint?.status == "open" ? "#000" : red,
                color: complaint?.status == "open" ? "#000" : red,
                "&:hover": {
                  backgroundColor: "#fff",
                  borderColor: complaint?.status == "open" ? "#000" : red,
                },
              }}
            >
              {complaint?.status == "open" ? (
                <>
                  <img
                    src={icons?.checked}
                    alt="checked"
                    style={{ marginRight: "6px" }}
                  />
                  Mark as resolved
                </>
              ) : (
                "Unresolved"
              )}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </ModelBox>
  );
}
