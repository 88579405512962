import Types from "./Types";

export const GetPushNotificationsSuccess = (payload) => ({
  type: Types.GET_PUSH_NOTIFICATIONS_SUCCESS,
  payload,
});

export const PushNotificationsLoading = (payload) => ({
  type: Types.PUSH_NOTIFICATIONS_LOADING,
  payload,
});
