/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  countries: [],
  filterCountries:[],
  count: 0,
  listCount:0,
};

export default function countriesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.COUNTIRES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_COUNTIRES_SUCCESS: {
      //COUNTRIES
      return {
        ...state,
        countries: payload?.data?.countries,
      };
    }
    case Types.GET_SCROLL_COUNTRIES_SUCCESS: {
      return {
        ...state,
        filterCountries: payload?.extra?.page_number == 1
          ? payload?.data?.countries
          : [...state.filterCountries, ...payload?.data?.countries],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}