import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = (_, rolesOptions, getRoles, rolesCount, load) => {
  return [
    {
      head: "",
      width: "100%",
      list: [
        {
          name: `name`,
          kind: "input",
          type: "text",
          label: "User Name",
          width: "47%",
          placeholder: "Write User name",
        },
        {
          name: `role_id`,
          kind: "select",
          type: "text",
          label: "Role",
          width: "47%",
          options: rolesOptions,
          onOpen: rolesOptions <= 1 && getRoles,
          getList: getRoles,
          count: rolesCount,
          isLoading: load?.type == "filter-roles-list",
          height: "200px",
          placeholder: "Choose role",
        },
        {
          name: `email`,
          kind: "input",
          type: "text",
          label: "Email",
          width: "47%",
          placeholder: "Write Email",
        },
        {
          name: `phone_number`,
          kind: "input",
          type: "text",
          label: "Phone Number",
          width: "47%",
          placeholder: "Write Phone number",
        },
        {
          name: `password`,
          kind: "input",
          type: "text",
          label: "Password",
          width: "47%",
          placeholder: "Write Password",
        },
        {
          name: `password_confirmation`,
          kind: "input",
          type: "text",
          label: "Confirm Password",
          width: "47%",
          placeholder: "Confirm Password",
        },
      ],
    },
  ];
};
const phoneNumberRegex = /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required("required"),
    role_id: Yup.string().required("required"),
    email: Yup.string().email().required("required"),
    phone_number: Yup.string().matches(phoneNumberRegex, "invalid phone number").required("required"),
    password: Yup.string().required("required").min(6, "password can be at least 6 characters"),
    password_confirmation: Yup.string("this field is required")
      .required()
      .oneOf([Yup.ref("password"), null], `Passwords don't match`),
  });
