import { Stack, Typography } from "@mui/material";
import React from "react";

export default function ProductListing({ details, imageStyle }) {
  return (
    <Stack
      flexDirection={"row"}
      justifyContent="space-between"
      width="100%"
      alignItems={"center"}
      gap={2}
    >
      <Stack direction="row" gap={2} alignItems="center" width="44%">
        <img
          src={details?.variant?.image}
          style={{ borderRadius: "5px" }}
          alt={"products"}
          width={"129px"}
          height={"123px"}
        />
        <Stack sx={{ width:'100%'}} >
          <Typography>{details?.variant?.name}</Typography>
          <Typography>QTY:{details?.quantity}</Typography>
        </Stack>
      </Stack>

      {details?.wrapper && <Typography fontSize={32}>+</Typography>}
      <Stack direction="row" width={"44%"} alignItems="center" gap={2}>
        {details?.wrapper && (
          <>
            <img
              src={details?.wrapper?.wrapper_image}
              style={{ borderRadius: "5px" }}
              width={"129px"}
              height={"123px"}
              alt={"products"}
            />
            <Stack alignSelf="flex-start">
              <Typography fontSize={"20px"} mb={1}>
                {details?.wrapper?.title}
              </Typography>
              <Stack gap={1}>
                <Typography>
                  Color of wrap : {details?.wrapper?.wrap_variant?.color?.name}
                </Typography>
                <Typography>
                  Color of ribbion : {details?.wrapper?.ribbon?.name}
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      {/* {details?.wrapper ? (
        <Stack>
          <Typography>{details?.wrapper?.title}</Typography>
          <Typography>
            Color of wrap : {details?.wrapper?.wrap_variant?.color?.name}
          </Typography>
          <Typography>
            Color of ribbion : {details?.wrapper?.ribbon?.name}
          </Typography>
        </Stack>
      ) : null} */}
    </Stack>
  );
}
