import { Stack } from "@mui/material";
import CustomTabs from "components/Organisms/tabs/customTabs";
import FreelancerDetailsHeader from "components/Organisms/users/drivers/freelancers/freelancerDetailsHeader";

export default function FreelancerDetailsTemplate({
  details,
  tabs,
  selectedTab,
  handleTabChange,
  actionsList,
}) {
  return (
    <Stack>
      <FreelancerDetailsHeader
        data={details}
        actionsList={actionsList}
      />
      <CustomTabs
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
    </Stack>
  );
}
