import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function AvailableDriversCard({
  data,
  setSelectedEmployee,
  selectedEmployee,
  displayDistance = true,
  customer = false,
  click,
}) {
  const { graySix, blackTwo, orange } = useThemePalette();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        p={displayDistance && "8px 17px 8px"}
        border={
          displayDistance &&
          (data?.id == selectedEmployee?.id
            ? `1px solid ${orange}`
            : `1px solid ${graySix}`)
        }
        sx={{ cursor: (displayDistance || click) && "pointer" }}
        onClick={(_) =>
          (click && click()) || (displayDistance && setSelectedEmployee(data))
        }
      >
        <Stack direction="row" gap="8px">
          <img
            alt={customer ? "customer img" : "driver img"}
            src={data?.avatar}
            width={customer ? "92px" : "64px"}
            height={customer ? "92px" : "64px"}
            style={{ borderRadius: "4px" }}
          />
          <Stack gap="9px" justifyContent="center">
            <Typography color="black">{data?.name}</Typography>
            <Stack direction="row" gap="5px">
              <img alt="phone icon" src={icons?.phoneIcon} />
              <Typography color="black">{data?.phone_number}</Typography>
            </Stack>
            {customer && (
              <Stack direction="row" gap="5px">
                <img alt="email icon" src={icons?.emailIcon} />
                <Typography color="black">{data?.email}</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        {displayDistance && (
          <Stack>
            <Stack direction="row" gap="5px">
              <img alt="location icon" src={icons?.locationIcon} />
              <Typography color={blackTwo} fontSize="18px">
                {Math.floor(data?.distance_to)} M{" "}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
}
