import { makeFilterString } from "../../utils/Helper";
import {
  DeleteColorSuccess,
  AddColorSuccess,
  UpdateShapesSuccess,
  GetColorsSuccess,
  GetColorsLoading,
  GetColorDetailsSuccess,
  UpdateColorSuccess,
  GetScrollColorsSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetColorsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetColorsLoading,
    loadingType: "list",
    successFunction: GetColorsSuccess,
    url: `/admin/wrappers/colors${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetScrollColorsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetColorsLoading,
    loadingType: "colors-scroll-list",
    successFunction: GetScrollColorsSuccess,
    url: `/admin/wrappers/colors${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const deleteColorsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetColorsLoading,
    successFunction: DeleteColorSuccess,
    loadingType: "delete wrap",
    action: variables?.action,
    url: `/admin/wrappers/colors/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const addColorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetColorsLoading,
    loadingType: "add",
    successFunction: AddColorSuccess,
    action: variables?.action,
    url: `/admin/wrappers/colors`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateColorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetColorsLoading,
    loadingType: "update shape",
    successFunction: UpdateColorSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/wrappers/colors/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getColorDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetColorsLoading,
    loadingType: "details",
    successFunction: GetColorDetailsSuccess,
    url: `/admin/wrappers/colors/${variables?.color_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




