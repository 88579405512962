import { Box, Stack, Typography } from "@mui/material";
import ChatList from "components/Organisms/chat/chatList";
import ChatBox from "components/Organisms/chat/chatBox";
import { images } from "assets/AssetHelper";

const MessengerTemplate = ({
    chatRooms,
    messagesList,
    sendMessage,
    setMessage,
    message,
    handleFileInput,
    setSearchFilterObj,
    Files,
    searchChatRooms,
    createChatRoom,
    isStore,
    visitChatRoom,
    count,
    getList,
    setPageNumber,
    loading,
    searchChats,
    searchCount,
    searchChatRoomsLoading,
    searchChatsFilterObj,
    setIsSearchOpen,
    getChatRoom,
    loadFile,
    setFirstMessageCount,
    firstMessageCount,
    isSearchOpen,
    setMessagesList,
    setFilterObj,
    filterObj
}) => {
    return (
        <Stack
            direction='row'
            gap='20px'
            mt='3px'
            sx={{
                bgcolor: 'white',
            }}
        >
            <Stack width='30%' >
                <ChatList
                    chatRooms={chatRooms}
                    setSearchFilterObj={setSearchFilterObj}
                    searchChatRooms={searchChatRooms}
                    createChatRoom={createChatRoom}
                    isStore={isStore}
                    visitChatRoom={visitChatRoom}
                    count={count}
                    getList={getList}
                    setPageNumber={setPageNumber}
                    loading={loading}
                    searchChats={searchChats}
                    searchCount={searchCount}
                    searchChatRoomsLoading={searchChatRoomsLoading}
                    searchChatsFilterObj={searchChatsFilterObj}
                    setIsSearchOpen={setIsSearchOpen}
                    getChatRoom={getChatRoom}
                    messagesList={messagesList}
                    firstMessageCount={firstMessageCount}
                    setFirstMessageCount={setFirstMessageCount}
                    isSearchOpen={isSearchOpen}
                    setMessagesList={setMessagesList}
                    setFilterObj={setFilterObj}
                    filterObj={filterObj}
                    setMessage={setMessage}
                />
            </Stack>
            <Stack width='67%' sx={{ boxShadow: ' 0px 4px 20px 0px rgba(0, 0, 0, 0.10)' }} >
                {localStorage.getItem("Messages_node") ? (
                    <ChatBox
                        messagesList={messagesList}
                        sendMessage={sendMessage}
                        setMessage={setMessage}
                        message={message}
                        handleFileInput={handleFileInput}
                        Files={Files}
                        loadFile={loadFile}
                    />
                ) : (
                    <Stack justifyContent='center' height="80vh" alignItems='center'  >
                        <Stack justifyContent='center' width={{ xl: '45%', lg: '60%' }} gap={'24px'} alignItems='center'  >
                            <Box>
                                <Box
                                    component='img'
                                    src={images.emptyChatImage}
                                    width='112px'
                                    height='112px'
                                />
                            </Box>
                            <Typography component="h1" sx={{ textAlign: "center", fontSize: '20px', fontWeight: 600 }}>
                                Feel free to engage in conversations with vendors . Let's start the conversation!
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default MessengerTemplate;