import { Stack } from "@mui/material";
import CustomTabPanel from "components/Molecules/Tabs/customTabPanel";
import TabsTitles from "components/Molecules/Tabs/tabs";

export default function CustomTabs({
  tabs,
  selectedTab,
  handleTabChange,
  tabstyle,
  selectedTabStyle,
}) {
  return (
    <Stack>
      <TabsTitles
        handleChange={handleTabChange}
        value={selectedTab}
        tabs={tabs}
        tabstyle={tabstyle}
        selectedTabStyle={selectedTabStyle}
      />
      {tabs.map((t, i) => (
        <CustomTabPanel value={selectedTab} index={i}>
          {t?.children}
        </CustomTabPanel>
      ))}
    </Stack>
  );
}
