import { icons, images } from "assets/AssetHelper";
import { useThemePalette } from "../../../common/hooks/theme_palette";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Details",
    icon: icons?.detailsIcon,
    details: [
      {
        label: "Customer name ",
        name: data?.driver?.name,
      },
      {
        label: "Order number ",
        name: "#" + data?.route_id,
      },
      {
        label: "Email ",
        name: data?.complaint_email,
      },
      {
        label: "Complain reason ",
        name: data?.route_complaint_reasons
          ?.map((reason) => reason?.name)
          .join(", "),
      },
      {
        label: "Mobile number",
        name: `${data?.driver?.country_code}${data?.driver?.phone_number}`,
      },
      {
        label: "Date and time  ",
        name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Status",
        name: data?.status === "open" ? "Not Resolved" : "Resolved",
        color: data?.status === "open" ? "#D20000" : "#29B95F",
      },
      {
        label: "Feedback",
        name: data?.feedback,
      },
    ],
  },
];
