import { useThemePalette } from "common/hooks/theme_palette";
import SearchableSelect from "components/Atoms/Input/Select/SearchableSelect";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function SearchChat({
  searchChatRooms,
  searchChats,
  setIsSearchOpen,
  getChatRoom,
  createChatRoom,
  searchChatRoomsLoading,
  handleInputChange,
  searchKeywords,
  searchCount,
  setSearchFilterObj,
  searchChatsFilterObj,
  isSearchOpen,
  setMessage,
}) {
  const { orange } = useThemePalette();
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const dispatch = useDispatch();
  const handleMenuScrollToBottom = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    const timeout = setTimeout(() => {
      if (searchChatRooms?.length < searchCount) {
        setSearchFilterObj((s) => {
          return {
            ...s,
            page_size: 10,
            page_number: searchChatRooms?.length
              ? Math?.floor(searchChatRooms?.length / 10) + 1
              : 1,
          };
          //...filterObj, page_size: 10, page_number: Math?.floor(chatRooms?.length / 10) + 1 }
        });
      }
    }, 500);
    setScrollTimeout(timeout);
  };
  return (
    <>
      <SearchableSelect
        menuPlacement="bottom"
        name="nationality_id"
        options={searchChatRooms}
        dropdownStyle={{ display: "none", borderRaduis: "8px" }}
        onMenuOpen={(_) => {
          searchChats({ searchChatsFilterObj, dispatch, isSearchOpen });
          setIsSearchOpen(true);
        }}
        onMenuClose={(_) => setIsSearchOpen(false)}
        onChange={(e) => {
          e &&
            (e?.chat_room_url
              ? getChatRoom(e?.id)
              : createChatRoom({ userId: e?.value, setMessage }));
        }}
        placeholder={
          <span style={{ paddingLeft: "25px" }}>
            <SearchIcon
              style={{
                position: "absolute",
                left: "8px",
                pointerEvents: "none",
                color: orange,
              }}
            />
            Search for Vendors
          </span>
        }
        isLoading={
          searchChatRoomsLoading?.type == "search" &&
          searchChatRoomsLoading?.loading
        }
        onInputChange={(e) => handleInputChange(e)}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        value={searchKeywords}
        sx={{
          border: "1px solid #EEE",
          width: "100%",
          mx: "24px",
          "& .MuiOutlinedInput-input": {
            p: "12px 14px !important",
          },
        }}
      />
    </>
  );
}
