import { Stack, Box, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function CustomerInfo({ details }) {
  const customerInfo = {
    "Customer Name": details?.orderer?.customer_name,
    "Mobile number": `+(${details?.orderer?.customer_country_code}) ${details?.orderer?.customer_phone_number}`,
    Email: details?.orderer?.email,
    location: details?.delivery_address?.full_address,
  };

  const cardStyle = {
    width: "47%",
    backgroundColor: "white",
    p: 3,
    gap: 3,
    boxSizing: "border-box",
    borderRadius: "8px",
  };

  return (
    <Stack sx={cardStyle}>
      {/* ----------- CARD HEADER ----------- */}
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={2}>
          <Box component="img" width="46px" src={icons.userInfo} />
          <Typography fontWeight="500" fontSize="24px">
            Customer Info
          </Typography>
        </Stack>
        {details?.order_complaint && (
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{ cursor: "pointer" }}
          >
            <VisibilityOutlinedIcon />
            <Typography>View Complain</Typography>
          </Stack>
        )}
      </Stack>
      {/* ----------- CARD INFO ----------- */}
      <Stack gap={2}>
        {Object.keys(customerInfo).map((key, i) => (
          <Stack key={i} direction="row" justifyContent="space-between">
            <Typography sx={{ width: "20%", color: "#969696" }}>
              {key}
            </Typography>
            <Typography sx={{ width: "77%" }}>{customerInfo[key]}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
