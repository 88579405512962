import { useThemePalette } from 'common/hooks/theme_palette';
import ConfirmationModal from 'components/Molecules/Models/ConfirmationModal'
import React from 'react'

export default function ApproveModel({ openModel, setOpenModel, handleUpdate, userType }) {
    const { textGreen } = useThemePalette()
    return <>
        <ConfirmationModal
            open={openModel?.state}
            setOpen={setOpenModel}
            handleConfirmation={_ => {
                handleUpdate()
                setOpenModel({ state: false, customerId: false, action: false })
            }}
            text={[
                `Approve ${userType}`,
                `Are you sure you want to approve this ${userType} account?`
            ]}
            textStyle={{ color: textGreen, fontSize: '32px', fontWeight: 500 }}
            confirmBtnTitle="Approve"
            btnStyle={{
                backgroundColor: textGreen,
                "&:hover": {
                    backgroundColor: textGreen,
                },
                color: "white",
            }}
        />
    </>
}
