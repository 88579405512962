import { Avatar, Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Search from "components/Atoms/Input/Search/Search";
import { useThemePalette } from "common/hooks/theme_palette";
import Notifications from "components/Molecules/Notifications/Notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import { useState } from "react";
import { logoutRequest } from "services/modules/authenticate";
import setAuthToken from "services/modules/utils/handel_api";

const Header = ({ currentUserProfile, title, notificationUnseenCount }) => {
  const navigate = useNavigate();
  const { grayBorder } = useThemePalette();
  const isStore = localStorage?.getItem("user_type") == "Store";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // test
  const dispatch = useDispatch();

  const handleLogout = () => {
    handleClose();
    navigate("/");
    dispatch(logoutRequest({}));
    localStorage.clear();
    // window.location.reload();
    localStorage.removeItem("token");
    localStorage.removeItem("Messages_node");
    localStorage.removeItem("chatId");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_type");
    window.dispatchEvent(new Event("storage"));
    setAuthToken("");
  };
  const handleViewProfile = () => {
    handleClose();
    if (isStore) {
      navigate(`/vendors?type=details&id=${currentUserProfile?.id}`);
    } else {
      navigate(`/admin-profile?type=details&id=${currentUserProfile?.id}`);
    }
  };

  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  
  return (
    <>
      <ConfirmationModal
        open={openLogoutModal}
        setOpen={setOpenLogoutModal}
        handleConfirmation={handleLogout}
        handleCancellation={() => setOpenLogoutModal(false)}
        text={["Are you sure you want to logout?"]}
        confirmBtnTitle="Logout"
      />
      <Stack
        bgcolor="white"
        p={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        fontWeight="500"
        sx={{ boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}
      >
        {/* -------------- TITLE -------------- */}
        <Typography gutterBottom component="div" fontSize="20px" fontWeight="500">
          {title}
        </Typography>
        {/* -------------- SEARCH & NOTIFICATION & USER INFO -------------- */}
        <Stack direction="row" justifyContent="space-between" alignItems="center" width={{ sm: "100%", md: "75%", xl: "75%" }}>
          {/* -------------- SEARCH -------------- */}
          <Search placeholder="Search for orders..." orange />
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
            {/* -------------- NOTIFICATION  -------------- */}
            <Notifications notificationUnseenCount={notificationUnseenCount} />
            {/* --------------  USER INFO  -------------- */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              sx={{ borderLeft: `1px solid ${grayBorder}`, px: 3 }}
            >
              {/* -------------- AVATAR -------------- */}
              <Avatar
                alt="Remy Sharp"
                src={currentUserProfile?.logo || "/static/images/avatar/1.jpg"}
                sx={{ borderRadius: "50%" }}
              />
              {/* -------------- USERNAME -------------- */}
              <Typography fontFamily="Jost" fontWeight="500" fontSize="18px">
                {/* {userInfo?.email} */}
                {currentUserProfile?.name}
              </Typography>
              {/* -------------- DROP DOWN -------------- */}
              <Box>
                <Box
                  sx={{ display: "flex" }}
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <ArrowForwardIosIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
                </Box>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleViewProfile}>View Profile</MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenLogoutModal(true);
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
