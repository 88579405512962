import { Stack, Button } from "@mui/material";
import { useFormik } from "formik";
import {
  initialValues,
  validationSchema,
  structure,
} from "templates/vendor/vendorListing/structure";
import FormIndex from "components/Atoms/SharedComponents/Forms/main";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getScrollTagsRequest } from "services/modules/lookups/tags";

export default function EditVendorForm({
  id,
  details,
  tags,
  refsubmit,
  handleSubmit,
}) {
  const formik = useFormik({
    initialValues: !id ? {} : initialValues(details, tags),
    validationSchema: (_) => validationSchema(formik?.values ,id),
    onSubmit: async (values) => {
      handleSubmit(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const filterTags = useSelector((state) => state.tags.filterTags);
  const tagsCount = useSelector((state) => state.tags.listCount);
  const tagsLoad = useSelector((state) => state.tags.load);
  const dispatch = useDispatch()
  const getTags = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollTagsRequest(requestData));
  };
  useEffect(() => {
    if (Object.keys(formik?.errors).length !== 0 && formik?.isSubmitting) {
      const msg = Object.keys(formik?.errors).map(
        (err) =>
          `${err == "en" ? "English name" : err.split("_").join(" ")
          }  is required`
      );
      toast.error(msg?.join(", "));
    }
  }, [formik?.errors, formik?.isSubmitting]);
  const handleClose = () => {
    formik.resetForm();
  };
  let options = {
    tags: {
      options: filterTags,
      onOpen: filterTags?.length <= 1 && getTags,
      isLoading: tagsLoad?.loading && tagsLoad?.type == "list-filter-tags",
      getList: getTags,
      count: tagsCount,
    },
  };
  return (
    <Stack sx={{ overflowY: "auto", height: 630, p: 2 }}>
      <FormIndex
        formStructures={structure(options)}
        formik={formik}
        refsubmit={refsubmit}
      >
        <Button
          // sx={{ visibility: "none" }}
          ref={refsubmit}
          type="submit"
        ></Button>
      </FormIndex>
    </Stack>
  );
}
