import { icons, images } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Details",
    icon: icons?.details,
    details: [
      {
        label: "Card Name(EN)",
        name: data?.localized_data?.en?.name,
      },
      {
        label: "Card Name(AR)",
        name: data?.localized_data?.ar?.name,
      },
      {
        label: "Presentation",
        name: data?.presentation,
      },
    ],
  },
  {
    head: "Prices",
    icon: icons?.fees,
    details: [
      {
        label: "Price",
        name: data?.price,
      },
      {
        label: "Price after discount",
        name: data?.price_after_discount,
      },
    ],
  },
  {
    head: "Dates",
    icon: icons?.dates,
    details: [
      {
        label: "Created At",
        name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Updated At",
        name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a"),
      },
    ],
  },
];
