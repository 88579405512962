import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function OrderNotes({ note }) {
  const { grayTwo, darkGray } = useThemePalette();
  return (
    <>
      <Stack
        sx={{ backgroundColor: "white", borderRadius: "4px" }}
        p={3}
        gap="6px"
      >
        <Typography color={grayTwo}>Order Note:</Typography>
        <Typography color={darkGray} fontWeight={"500"} fontSize={"18px"}>
          {note}
        </Typography>
      </Stack>
    </>
  );
}
