import Types from "./Types";

export const GetCustomerComplaintReasonsSuccess = (payload) => ({
    type: Types.GET_CUSTOMER_COMPLAINT_REASONS_SUCCESS,
    payload,
});

export const AddCustomerComplaintReasonSuccess = (payload) => ({
    type: Types.ADD_CUSTOMER_COMPLAINT_REASON_SUCCESS,
    payload,
});

export const GetCustomerComplaintReasonDetailsSuccess = (payload) => ({
    type: Types.GET_CUSTOMER_COMPLAINT_REASON_DETAILS_SUCCESS,
    payload,
});
export const CustomerComplaintReasonsLoading = (payload) => ({
    type: Types.CUSTOMER_COMPLAINT_REASONS_LOADING,
    payload,
});