import { PriceChange } from "@mui/icons-material";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import sessionStorage from "redux-persist/es/storage/session";

export const initialValues = (data) => {
    return {
        ...data,
        option_type_id: data?.option_type?.id,
        enOptionValue: data?.localized_data?.en.name,
        arOptionValue: data?.localized_data?.ar.name,
        presentation: (data?.option_type?.name === 'color' || data?.option_type?.name === 'Color') ? data?.presentation : "",
        ...data?.localized_data,
    }
};

export const structure = (optionsTypes, optionType, optionTypesOptions, details) => [

    {
        head: "",
        width: "100%",
        list: [
            {
                name: `option_type_id`,
                kind: "select",
                type: "text",
                label: "Option Type",
                width: "47%",
                placeholder: "Choose Option type",
                // options:optionsTypes
                ...optionTypesOptions?.optionTypes
            },
            {
                name: `en.name`,
                kind: "input",
                type: "text",
                label: "Option Value(EN)",
                width: "47%",
                placeholder: "Write Option Value(EN)"
            },
            {
                name: `ar.name`,
                kind: "input",
                type: "text",
                label: "Option Value(AR)",
                width: "47%",
                placeholder: "Write Option Value(AR)",
                display: 'none',
            },
            (optionType?.toLowerCase() === 'color') && (
                {
                    name: `presentation`,
                    kind: "colorInut",
                    type: "text",
                    label: "Pick a color",
                    width: "47%",
                    placeholder: "Pick a color ",
                    disabled: !optionType && true
                }
            )

        ],


    },
];


export const validationSchema = (optionType) =>

    Yup.object({
        option_type_id: Yup.string().required('Required'),
        en: Yup.object().shape({
            name: Yup.string().required('Required'),
        }),
        ar: Yup.object().shape({
            name: Yup.string().required('Required'),
        }),
        presentation: ((optionType === 'color') || (optionType === 'Color')) && Yup.string().required('Required')

    },
    )