import * as Yup from "yup";

export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
    // presentation: data?.presentation,
  }
};

export const structure = (wrapsList, colorsList, shapeList) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Ribbon Color Name(EN)",
        width: "47%",
        placeholder: "Write Ribbon Color Name(EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Ribbon Color Name(AR)",
        width: "47%",
        placeholder: "Write Ribbon Color Name(AR)"
      },
      {
        name: `presentation`,
        kind: "colorInut",
        type: "text",
        label: "Pick a color",
        width: "47%",
        placeholder: "Pick a color ",
      },
      {
        name: `price`,
        kind: "input",
        type: "number",
        label: "Price",
        width: "47%",
        placeholder: "Write price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        )
      },
      {
        name: `price_after_discount`,
        kind: "input",
        type: "number",
        label: "Price after discount",
        width: "47%",
        placeholder: "Write price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        )
      },

    ],
  },
];


export const validationSchema = (wrapId) =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    price: Yup.number().required("required"),
    price_after_discount: Yup.number().required("required"),
    // shape_id: Yup.number().required("required"),
    // wrap_id: Yup.number().required("required"),
    presentation: Yup.string().required('Required'),
  },
  );