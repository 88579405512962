const CattegoriesTypes = {
  //LOADING
  CATEGORIES_LOADING: "CATEGORIES_LOADING",
  //LISTING
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_SCROLL_CATEGORIES_SUCCESS: "GET_SCROLL_CATEGORIES_SUCCESS",
  //CREATION
  ADD_CATEGORIES_SUCCESS: "ADD_CATEGORIES_SUCCESS",
  //DETAILS
  //UPDATE
  UPDATE_CATEGORIES_SUCCESS: "UPDATE_CATEGORIES_SUCCESS",
  //DELETE
  DELETE_CATEGORIES_SUCCESS: "DELETE_CATEGORIES_SUCCESS",
  GET_CATEGORIES_DETAILS_SUCCESS: "GET_CATEGORIES_DETAILS_SUCCESS",
};
export default CattegoriesTypes;
