import {AuthLoading, AuthSuccess, GetUserProfileSuccess, logoutSuccess} from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const loginRequest = ({ ...variables }) => {
    const requestData = {
        loadingType: 'login',
        isLoading: AuthLoading,
        successFunction: AuthSuccess,
        url: `/admin/auth/admin_user/login`,
        action: variables?.action,
        method: "post",
        data: variables?.body
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const OTPRequest = ({ ...variables }) => {
    const requestData = {
        loadingType: 'OTP request',
        isLoading: AuthLoading,
        successFunction: AuthSuccess,
        url: `/admin/auth/passwords/${variables?.user_type}/send_reset_password_info`,
        action:variables?.action,
        method: "post",
        data: variables?.body
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const OTPVerify = ({ ...variables }) => {
    const requestData = {
        loadingType: 'OTP verify',
        isLoading: AuthLoading,
        successFunction: AuthSuccess,
        url: `/admin/auth/passwords/${variables.user_type}/verify_otp`,
        action:variables?.action,
        method: "post",
        data: variables?.body
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const resetPasswordRequest = ({ ...variables }) => {
    const requestData = {
        loadingType: 'reset password',
        isLoading: AuthLoading,
        successFunction: AuthSuccess,
        url: `/admin/auth/passwords/${variables?.user_type}/reset_password`,
        action:variables?.action,
        method: "post",
        data: variables?.body,
        headers: variables?.headers,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};


export const logoutRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "logout",
    isLoading: AuthLoading,
    successFunction: logoutSuccess,
    url: `/auth/logout`,
    action: variables?.action,
    method: "DELETE",
    data: variables?.body,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getUserProfileRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "get user",
    isLoading: AuthLoading,
    successFunction: GetUserProfileSuccess,
    url: `/users/profile`,
    action: variables?.action,
    method: "get",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
