import { Modal, Stack, Typography, Box, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const ModelBox = ({
  title,
  children,
  closeFunc,
  openVar,
  titleStyle,
  modelBorderRadius,
  justifyContent,
  px,
  className,
  padding = true,
  isHeaderVisiable = true,
  maxWidth,
  maxHeight,
  ...rest
}) => {
  return (
    <Dialog
      open={openVar}
      onClose={closeFunc}
      className={className}
      sx={{ zIndex: 900 }}
      PaperProps={{
        sx: {
          borderRadius: modelBorderRadius ? modelBorderRadius : "12px",
          height: "auto",
          maxWidth: {
            lg: maxWidth || "1072px",
            md: "800px",
            sm: "500px",
            xs: "380px",
          },
          px: px,
          width: "50%", 
          maxHeight: maxHeight,
          ...rest
          // overflow: "visible",
        },
      }}
    >
      <Stack>
        <Stack
          sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)" }}
          direction="row"
          justifyContent="space-between"
          py="18px"
          display={!isHeaderVisiable && "none"}
        >
          <Stack direction="row" mx={1} width="100%">
            <Typography sx={titleStyle} component="h2">
              {title}
            </Typography>
          </Stack>
          <Box
            onClick={closeFunc}
            sx={{
              padding: "4px",
              width: { lg: "54px", xs: "40px" },
              height: { lg: "54px", xs: "40px" },
              display: "flex",
              alignItems: "center !important",
              justifyContent: "center !important",
              cursor: "pointer",
            }}
          >
            <CloseIcon sx={{ fontSize: "30px" }} />
          </Box>
        </Stack>
        <Stack sx={{ padding: padding ? "36px" : "0" }}>{children}</Stack>
      </Stack>
    </Dialog>
  );
};

export default ModelBox;
