import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import { detailsData } from "templates/users/drivers/details";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function DriverDetails({ details,displayDriverDataWithImg }) {
  const [addressesToShow, setAddressesToShow] = useState({ length: 1, state: false });
  const cities = useSelector((state) => state.cities.cities);
  const countries = useSelector((state) => state.countries.countries);
  const city = cities?.filter((city) => city?.id == details?.city_id)
  const country = countries?.filter((country) => country?.id == details?.country_id)

  return <>
    <DetailsUse
      data={{
        deleteBtn: false,
        editBtn: false,
        data: {
          list: detailsData({ data: details, addressesToShow, setAddressesToShow, city, country ,displayDriverDataWithImg}),
        },
      }}
    />
  </>
}
