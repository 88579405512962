import AddIcon from "@mui/icons-material/Add";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CCrud from "components/Molecules/Crud";
import ProductDetailsPage from "pages/products/productsDetails";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { initialValues, structure, validationSchema } from "./structure";

const ProductsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  options,
  can_create,
  userType,
  load,
  isStore,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");
  let columnData = [
    { accessor: "id", Header: "#" },
    {
      accessor: "nameEN",
      Header: "Name(EN)",
      sort: true,
    },
    {
      accessor: "nameAR",
      Header: "Name(AR)",
      sort: true,
    },
    { accessor: "vendor", Header: "Vendor", sort: true },
    { accessor: "occasion", Header: "Occasion", sort: true },
    { accessor: "category", Header: "Category", sort: true },
    { accessor: "price", Header: "Price", sort: true, width: 80 },
    {
      accessor: "price_after_discount",
      Header: "Price after discount",
      sort: true,
    },
    { accessor: "total_on_hand", Header: "Quantity", sort: true },
    {
      accessor: "rate",
      Header: "Avg rate/Reviews count",
      sort: true,
    },
    { accessor: "status", Header: "Status" },
    { accessor: "approval_status", Header: "Approval Status" },
    { accessor: "Actions", Header: "Actions" },
  ];
  const storeData = [
    { accessor: "id", Header: "#" },
    {
      accessor: "nameEN",
      Header: "Name(EN)",
      sort: true,
    },
    {
      accessor: "nameAR",
      Header: "Name(AR)",
      sort: true,
    },
    { accessor: "occasion", Header: "Occasion", sort: true },
    { accessor: "category", Header: "Category", sort: true },
    { accessor: "price", Header: "Price", sort: true, width: 80 },
    {
      accessor: "price_after_discount",
      Header: "Price after discount",
      sort: true,
    },
    { accessor: "total_on_hand", Header: "Quantity", sort: true },
    {
      accessor: "rate",
      Header: "Avg rate/Reviews count",
      sort: true,
    },
    { accessor: "status", Header: "Status" },
    { accessor: "approval_status", Header: "Approval Status" },
    { accessor: "Actions", Header: "Actions" },
  ];


  if (isStore) {
    columnData?.splice(3, 1);
  }
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "Products",
            createBtnTitle: can_create && "Add Product",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: columnData,
            isSearch: true,
            searchInputPlaceholder: "Search by product name or id",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(options),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit Product" : "Add Product",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          DetailsComponent={<ProductDetailsPage details={details} setOpenDeleteModal={setOpenDeleteModal} />}
          //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ProductsTemplate;
