import Header from "components/Atoms/SharedComponents/Details/header";
import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useState } from "react";
import { useRef } from "react";
import CActions from "components/Molecules/Crud/components/actions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ProductDetailsHeader({ data, setOpenDeleteModal }) {
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector((state) => state.authenticate.currentUserProfile.permissions.products);

  const isSuperAdmin = currentUser?.privilege == "super_admin";
  const status = data?.approval_status;
  const navigate = useNavigate();
  const { green, red } = useThemePalette();

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionsList = () => {
    return [
      {
        condition: () => {
          return status == "available" && (isSuperAdmin || permissions?.can_update);
        },
        click: () => {
          navigate(`/products/${data?.id}?type=edit`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const headerChildren = (
    <Stack direction="row" gap={2}>
      <Stack direction="row">
        <Stack direction="row" gap={1}>
          <Typography sx={{ color: "#9C9C9C" }}>Status:</Typography>
          {data?.is_visible ? (
            <Typography sx={{ color: green, fontSize: "18px" }}>Active</Typography>
          ) : (
            <Typography sx={{ color: red, fontSize: "18px", textTransform: "capitalize" }}>Disabled</Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row">
        <Stack direction="row" gap={1}>
          <Typography sx={{ color: "#9C9C9C" }}>Approval status:</Typography>
          {status == "available" ? (
            <Typography sx={{ color: green, fontSize: "18px" }}>Approved</Typography>
          ) : (
            <Typography sx={{ color: red, fontSize: "18px", textTransform: "capitalize" }}>{status}</Typography>
          )}
        </Stack>
      </Stack>
      {status !== "deleted" && actionsList().length !== 0 && (
        <Stack>
          <CActions
            id={data?.id}
            index={1}
            data={data}
            handleClick={handleClick}
            handleClose={handleClose}
            anchorRef={anchorRef}
            open={open}
            anchorEl={anchorEl}
            actionsList={actionsList}
          />
        </Stack>
      )}
    </Stack>
  );
  return (
    <Header
      title="Product details"
      children={headerChildren}
      deleteBtn={false}
      editBtn={false}
      // setOpenDeleteModal={setOpenDeleteModal}
    />
  );
}
