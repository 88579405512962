import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, Stack, StepContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import TripOriginIcon from "@mui/icons-material/TripOrigin";
import dayjs from "dayjs";
import { useThemePalette } from "common/hooks/theme_palette";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "none",
  width: "684px",
};

const stepperStyle = {
  p: 4,
  width: "500px",
  ".MuiStepConnector-line": {
    display: "none",
  },
  ".MuiStepLabel-label": {
    fontWeight: "500",
    fontSize: "24px",
  },
};

export default function OrderStatusTimelineDetails({
  open,
  setOpen,
  data,
  completed,
  cancelledStep,
}) {
  const { red, green, yellow } = useThemePalette();
  const nextStep = completed + 1 || true; // true -> if it hasn't started yet then set processing to pending

  const handleClose = () => setOpen(false);
  const isCompleted = (index) => index + 1 <= completed;
  const isCancelled = cancelledStep?.step;

  const steps = () => {
    if (!cancelledStep) {
      return data;
    }
    return data
      ?.slice(0, cancelledStep?.step)
      .concat({ date: cancelledStep?.date, status: "Canceled", next: null });
  };

  const handelStepsColor = (defaultColor, index) => {
    if (isCompleted(index)) return green;
    else if (index + 1 == nextStep) return yellow;
    else if (isCancelled) return red;
    else return defaultColor;
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {/* ------------- MODAL HEADER ------------- */}
        <Stack direction="row" justifyContent="space-between" p={4}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Order status timeline details
          </Typography>
          <CloseIcon onClick={handleClose} cursor="pointer" />
        </Stack>
        <Divider />
        {/* ------------- STEPPER ------------- */}
        <Box sx={{ maxWidth: 400 }}>
          <Stepper orientation="vertical" sx={stepperStyle}>
            {steps()?.map((step, index) => (
              <Step key={index} active={true}>
                <StepLabel
                  sx={{
                    ".MuiStepLabel-label": {
                      color: handelStepsColor("#000000", index),
                    },
                  }}
                  icon={
                    <TripOriginIcon
                      sx={{
                        color: handelStepsColor("#D8D8D8", index),
                      }}
                    />
                  }
                >
                  {step.status}
                </StepLabel>
                <StepContent
                  sx={{
                    borderLeft: `2px solid ${handelStepsColor(
                      "#D8D8D8",
                      index
                    )}`,
                    height: index !== steps.length - 1 ? "40px" : "0px",
                  }}
                >
                  {isCompleted(index) &&
                    step?.date &&
                    dayjs(step?.date).format("DD-MM-YYYY,hh:mm a")}

                  {isCancelled && steps()?.length - 1 == index && (
                    <>
                      {dayjs(cancelledStep?.date).format("DD-MM-YYYY,hh:mm a")}
                    </>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </Modal>
  );
}
