import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import React from "react";
import { Stack } from "@mui/material";
import CFormLabels from "./CFormLabels";
import CFormErrors from "./CFormErrors";

const CPhoneInput = ({
  changeAction,
  label,
  formik,
  name,
  defaultValue,
  ...rest
}) => {
  const errorsMsg = formik?.errors[name];
  const value = formik ? formik?.values[name] : defaultValue;
  return (
    <Stack width="100%" gap={3} >
      <CFormLabels label={label}  />
      <PhoneInput
        country={"us"}
        value={value}
        onChange={(value, country, e, formattedValue) =>
          changeAction(value, country, e, formattedValue)
        }
        {...rest}
      />
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CPhoneInput;
