import { makeFilterString } from "../../utils/Helper";
import {
  CitiesLoading,
  GetCitiesSuccess,
  GetCityDetailsSuccess,
  GetScrollCitiesSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getCitiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "list",
    successFunction: GetCitiesSuccess,
    url: `/admin/lookups/google_cities${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollCitiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "filter-list-cities",
    successFunction: GetScrollCitiesSuccess,
    url: `/admin/lookups/google_cities${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const createCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "create",
    action: variables?.action,
    url: `/admin/lookups/google_cities`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getCityDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "get",
    successFunction: GetCityDetailsSuccess,
    action: variables?.action,
    url: `/admin/lookups/google_cities/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "update",
    action: variables?.action,
    url: `/admin/lookups/google_cities/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/lookups/google_cities/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
