import { makeFilterString } from "../../utils/Helper";
import { reduxRequest } from "common/utils/reduxRequest";
import { AddRibbonColorSuccess, AddWrapVariantSuccess, DeleteRibbonColorSuccess, DeleteWrapVariantSuccess, GetRibbonColorDetailsSuccess, GetRibbonColorsLoading, GetRibbonColorsSuccess, GetScrollRibbonColorSuccess, GetWrapVariantDetailsSuccess, GetWrapVariantsLoading, GetWrapVariantsSuccess, UpdateRibbonColorSuccess, UpdateWrapVariantSuccess } from "./Action";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetRibbonColorsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetRibbonColorsLoading,
    loadingType: "list",
    successFunction: GetRibbonColorsSuccess,
    url: `/admin/wrappers/ribbon_colors${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollRibbonColorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetRibbonColorsLoading,
    loadingType: "ribbon-color-scroll-list",
    successFunction: GetScrollRibbonColorSuccess,
    url: `/admin/wrappers/ribbon_colors${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const deleteRibbonColorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetRibbonColorsLoading,
    successFunction: DeleteRibbonColorSuccess,
    loadingType: "delete wrap",
    action: variables?.action,
    url: `/admin/wrappers/ribbon_colors/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const addRibbonColorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetRibbonColorsLoading,
    loadingType: "add",
    successFunction: AddRibbonColorSuccess,
    action: variables?.action,
    url: `/admin/wrappers/ribbon_colors`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




export const updateRibbonColortRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetRibbonColorsLoading,
    loadingType: "update shape",
    successFunction: UpdateRibbonColorSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/wrappers/ribbon_colors/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const getRibbonColorDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetRibbonColorsLoading,
    loadingType: "details",
    successFunction: GetRibbonColorDetailsSuccess,
    url: `/admin/wrappers/ribbon_colors/${variables?.ribbon_color_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




