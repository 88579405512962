import { combineReducers } from "redux";

import filesUploadReducer from "./modules/uploadFiles/Reducer";
import modalReducer from "./modules/modal/Reducer";

import authenticateReducer from "./modules/authenticate/Reducer";
import users from "./modules/users/Reducer";
import giftCards from "./modules/giftCards/Reducer";
import productsReducer from "./modules/products/Reducer";

import { tagsReducer } from "./modules/lookups/tags/Reducer";
import { bannersReducer } from "./modules/lookups/banners/Reducer";
import { systemConfigReducer } from "./modules/systemConfig/Reducer";
import { citiesReducer } from "./modules/cities/cities/Reducer";
import { zonesReducer } from "./modules/cities/zones/Reducer";
import { feesBetweenZonesReudcer } from "./modules/cities/feesBetweenZones/Reducer";
import { wrappersReducer } from "./modules/Wrappers/Wraps/Reducer";
import { shapesReducer } from "./modules/Wrappers/shapes/Reducer";
import { colorsReducer } from "./modules/Wrappers/colors/Reducer";
import { wrapVariantsReducer } from "./modules/Wrappers/wrapVariants/Reducer";
import { ribbonColorsReducer } from "./modules/Wrappers/ribbonColors/Reducer";
import { wrapExtrasReducer } from "./modules/Wrappers/wrapExtras/Reducer";
import { occasionsTypesReducer } from "./modules/occasions/occasionsTypes/Reducer";
import { userOccasionsReducer } from "./modules/occasions/usersOccasions/Reducer";
import { orderReducer } from "./modules/orders/Reducer";
import { productOptionTypesReducer } from "./modules/lookups/productOptionTypes/Reducer";
import { vendorsReducer } from "./modules/vendors/Reducer";
import { suggestedWrappersReducer } from "./modules/Wrappers/suggestedWraps/Reducer";

import { productOptionValuesReducer } from "./modules/lookups/productOptionValues/Reducer";
import { customerComplaintsReducer } from "./modules/complaints/customerComplaints/Reducer";
import { driverComplaintsReducer } from "./modules/complaints/driverComplaints/Reducer";
import { deliveryTimesReducer } from "./modules/lookups/deliveryTimes/Reducer";
import { categoriesReducer } from "./modules/lookups/categories/Reducer";
import customersReducer from "./modules/users/customers/Reducer";
import { orderDeclinationReducer } from "./modules/lookups/orderDeclination/Reducer";
import { driverComplaintsReasonsReducer } from "./modules/lookups/complaints/driverComplaintReasons/Reducer";
import { customerComplaintsReasonsReducer } from "./modules/lookups/complaints/customerComplaintReasons/Reducer";
import { pushNotificationsReducer } from "./modules/notifications/push-notifications/Reducer";
import { notificationsReducer } from "./modules/notifications/notifications/Reducer";

import driverReducer from "./modules/users/driver/Reducer";
import countriesReducer from "./modules/lookups/countries/Reducer";
import { chatRoomReducer } from "./modules/chat/Reducer";
import { storeBranchesReducer } from "./modules/vendors/storeBranches/Reducer";
import { returnOrderReducer } from "./modules/orders/return-order/Reducer";

const rootReducer = combineReducers({
  files: filesUploadReducer,
  modal: modalReducer,
  tags: tagsReducer,
  banners: bannersReducer,
  systemConfig: systemConfigReducer,
  wrappers: wrappersReducer,
  shapes: shapesReducer,
  colors: colorsReducer,
  orderDeclinationReason: orderDeclinationReducer,
  authenticate: authenticateReducer,
  wrapVariant: wrapVariantsReducer,
  ribbonColors: ribbonColorsReducer,
  wrapsExtras: wrapExtrasReducer,
  suggestedWraps: suggestedWrappersReducer,
  userOccasions: userOccasionsReducer,
  occasionsTypes: occasionsTypesReducer,
  users: users,
  cities: citiesReducer,
  zones: zonesReducer,
  feesBetweenZones: feesBetweenZonesReudcer,
  giftCards: giftCards,
  productsOptionValues: productOptionValuesReducer,
  orders: orderReducer,
  returnOrders: returnOrderReducer,
  products: productsReducer,
  productsOptionTypes: productOptionTypesReducer,
  deliveryTimes: deliveryTimesReducer,
  categories: categoriesReducer,
  customers: customersReducer,
  driverComplaintReasons: driverComplaintsReasonsReducer,
  customerComplaintReasons: customerComplaintsReasonsReducer,
  customerComplaints: customerComplaintsReducer,
  driverComplaints: driverComplaintsReducer,
  vendors: vendorsReducer,
  pushNotifications: pushNotificationsReducer,
  notifications: notificationsReducer,
  drivers: driverReducer,
  countries: countriesReducer,
  storeBranches: storeBranchesReducer,
  chatRooms: chatRoomReducer,
});

export default rootReducer;
