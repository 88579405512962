import Types from "./Types";

export const GetCustomersSuccess = (payload) => ({
  type: Types.GET_CUSTOMERS_SUCCESS,
  payload,
});

export const CustomersLoading = (payload) => ({
  type: Types.CUSTOMERS_LOADING,
  payload,
});


export const UpdateCustomerSuccess = (payload) => ({
  type: Types.UPDATE_CUSTOMER_SUCCESS,
  payload,
});

export const GetCustomerDetailsSuccess = (payload) => ({
  type: Types.GET_CUSTOMER_DETAILS_SUCCESS,
  payload,
});


export const DeleteCustomerSuccess = (payload) => ({
  type: Types.DELETE_CUSTOMER_SUCCESS,
  payload,
});

export const GetFollowersSuccess = (payload) => ({
  type: Types.GET_CUSTOMER_FOLLOWERS_LIST,
  payload,
});

export const GetFollowingSuccess = (payload) => ({
  type: Types.GET_CUSTOMER_FOLLOWING_LIST,
  payload,
});

export const clearFollowingLits = () => ({
  type: Types.CLEAR_FOLLOWING_LIST
})

export const clearFollowersLits = () => ({
  type: Types.CLEAR_FOLLOWERS_LIST
})
