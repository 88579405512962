import { icons, images } from 'assets/AssetHelper'
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Customer name ',
                name: data?.customer?.name,
            },
            {
                label: 'Order number ',
                name: '#'+data?.order_id,
            },
            {
                label: 'Email ',
                name: data?.complaint_email,
            },
            {
                label: 'Complain reason ',
                name: data?.order_complaint_reasons?.map(reason=>reason?.name).join(', '),
            },
            {
                label: 'Date and time  ',
                name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            },
            {
                label: 'Status',
                name: data?.status==='open'?'Not Resolved':'Resolved',
                color: data?.status === 'open' ? '#D20000' : '#29B95F',
            },
            {
                label: 'Feedback',
                name: data?.feedback,
            },
        ]
    },

];


