import {reduxRequest} from "../../../../../common/utils/reduxRequest";
import {makeFilterString} from "../../../utils/Helper";
import {
    AddDriverComplaintReasonSuccess,
    DriverComplaintReasonsLoading, GetDriverComplaintReasonDetailsSuccess,
    GetDriverComplaintReasonsSuccess
} from "./Actions";

export const addDriverComplaintReasonRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintReasonsLoading,
        loadingType: "add",
        successFunction: AddDriverComplaintReasonSuccess,
        action: variables?.action,
        url: `/admin/complaints/route_complaint_reasons`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const getDriverComplaintReasonsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintReasonsLoading,
        loadingType: "list",
        successFunction: GetDriverComplaintReasonsSuccess,
        url: `/admin/complaints/route_complaint_reasons?${makeFilterString(
            variables?.params
        )}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const deleteDriverComplaintReasonRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintReasonsLoading,
        loadingType: "delete order",
        action: variables?.action,
        url: `/admin/complaints/route_complaint_reasons/${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const getDriverComplaintReasonDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintReasonsLoading,
        loadingType: "details",
        successFunction: GetDriverComplaintReasonDetailsSuccess,
        url: `/admin/complaints/route_complaint_reasons/${variables?.id}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const updateDriverComplaintReasonRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintReasonsLoading,
        loadingType: "update",
        url: `/admin/complaints/route_complaint_reasons/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};