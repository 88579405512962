const Types = {
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_UNREAD_COUNT_SUCCESS: "GET_UNREAD_COUNT_SUCCESS",
  SET_FCM_TOKEN_SUCCESS: "SET_FCM_TOKEN_SUCCESS",
  NOTIFICATIONS_LOADING: "NOTIFICATIONS_LOADING",
  UNREAD_COUNT_LOADING: "UNREAD_COUNT_LOADING",

  // MARK_AS_READ_SUCCESS: "MARK_AS_READ_SUCCESS",
  // MARK_ALL_AS_READ_SUCCESS: "MARK_ALL_AS_READ_SUCCESS",
};

export default Types;
