import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import CategoriesTemplate from "templates/lookups/categories";
import {
  createCategoriesRequest,
  deleteCategoryRequest,
  getCategoriesRequest,
  getCategoryDetailsRequest,
  updateCategoryRequest,
} from "services/modules/lookups/categories";

function CategoriesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.categories.categories);
  const count = useSelector((state) => state.categories.count);
  const details = useSelector((state) => state.categories.category);
  const load = useSelector((state) => state.categories.load);

  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.categories
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getCategoryDetailsRequest({ category_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getCategoriesRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      created_at: <>{dayjs(data?.created_at).format("DD-MM-YYYY hh:mm")}</>,
      updated_at: <>{dayjs(data?.updated_at).format("DD-MM-YYYY hh:mm")}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/categories",
      title: "Predefined Lookups/Categories",
    },
    type && {
      active: false,
      title: type === "details" ? "Wrap details" : id ? "Edit category" : "New category",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/categories");
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(`Category has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: { category: { ...formik?.values } },
      action,
    };

    if (id) {
      //update call
      dispatch(updateCategoryRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createCategoriesRequest(requestData));
    }
  };

  const filterList = [];

  const handleDelete = (id) => {
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/tags");
      toast.success("Category deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    // dispatch(deleteTagRequest(requestData));
    dispatch(deleteCategoryRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single gategory
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <CategoriesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        can_create={isSuperAdmin || permissions?.can_create}
      />
    </>
  );
}

export default CategoriesPage;
