import React from "react";
import ConfirmationModal from "../Models/ConfirmationModal";
import { CircularProgress } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";

export default function UnAssignDriver({
  load,
  openUnAssignModal,
  setOpenUnAssignModal,
  handleUnAssignDriver,
  data,
  routeType,
}) {
  const {oliveGreen} = useThemePalette();
  return (
    <>
      <ConfirmationModal
        open={openUnAssignModal}
        setOpen={setOpenUnAssignModal}
        handleConfirmation={(_) => {
          handleUnAssignDriver({
            driverId: data?.driver?.id,
            routeType: routeType,
          });
        }}
        text={[
          "Unassign Driver",
          "Are you sure you want to unassign this driver?",
        ]}
        textStyle={{ color: "#000", fontSize: "32px" }}
        confirmBtnTitle={
          load?.loading && load?.type == "unassignDriver" ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "33px !important",
                height: "33px !important",
              }}
            />
          ) : (
            "Unassign"
          )
        }
        btnStyle={{
          backgroundColor:oliveGreen,
          "&:hover": {
            backgroundColor:oliveGreen,
          },
          color: "white",
        }}
      />
    </>
  );
}
