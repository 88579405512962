/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  chatRooms: [],
  chatRoom: {},
  searchChatRooms: [],
  sendMessage: {},
  count: 0,
  searchCount: 0,
  load: null,
  error: null,
  searchChatRoomsLoading: null
};

export function chatRoomReducer(state = INIT_STATE, action) {
  const { type, payload, data } = action;
  switch (type) {
    case Types.GET_CHAT_ROOMS_SUCCESS: {
      return {
        ...state,
        chatRooms: data?.params?.page_number == 1 ? payload?.data?.chat_room : [...state.chatRooms, ...payload?.data?.chat_room],
        count: payload?.extra?.total_count,
      };
    }
    case Types.SHOW_CHAT_ROOM_SUCCESS: {
      return {
        ...state,
        chatRoom: payload?.data?.chat_room,
      };
    }
    case Types.SEARCH_CHAT_ROOMS_SUCCESS: {
      return {
        ...state,
        searchChatRooms: data?.params?.page_number == 1 ? payload?.data?.chats : [...state.searchChatRooms, ...payload?.data?.chats],
        searchCount: payload?.extra?.total_count,
      };
    }
    case Types.CHAT_ROOMS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.SEARCH_CHAT_ROOMS_LOADING: {
      return {
        ...state,
        searchChatRoomsLoading: payload,
      };
    }
    case Types.SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        sendMessage: payload?.data?.notification,
      };
    }
    case Types.VISIT_CHAT_ROOM: {
      return {
        ...state,
        chatRooms: state?.chatRooms?.map(chatRoom => {
          if (chatRoom.id === payload?.data?.chat_room?.id) {
            return payload?.data?.chat_room;
          } else {
            return chatRoom
          }
        }),
      };
    }
    case Types.CREATE_CHAT_ROOM_SUCCESS: {
      const newChatRooms = [...state.chatRooms, payload?.data?.chat_room];
      const isStore = localStorage?.getItem('user_type') == 'Store'
      return {
        ...state,
        chatRooms: isStore ? [payload?.data?.chat_room] : newChatRooms,
      };
    }
    default: {
      return state;
    }
  }
}
