import { Box, Stack, Typography, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { forgetPasswordSchema } from "common/utils/schemas";
import { OTPRequest } from "services/modules/authenticate";
import { AppForm, AppFormField, SubmitButton } from "components/Molecules/Form";
import { useNavigate } from "react-router-dom";

export default function ForgetPasswordForm({hideIcon,setResetPasswordStep,customAction=false,userType='admin_user'}) {
  const { gray } = useThemePalette();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { load } = useSelector((state) => state?.authenticate);

  const handleFormSubmit = (values, { setSubmitting }) => {
    const action = (data) => {
      localStorage.setItem("forgetPasswordEmail", values.email);
      {customAction?setResetPasswordStep({action:true,step:2}):navigate("/verify-otp")}
    };
    setSubmitting(false);
    const requestBody = {
      body: {
        user: {
          email: values.email,
        },
      },
      action,
    };
    dispatch(OTPRequest({...requestBody,user_type:userType}));
  };

  return (
      <>
        <Loader open={load?.loading} />
        <Stack sx={{ gap: 5 }}>
          {!hideIcon&&
              <>
                <Box
                    component="img"
                    src={icons.lockIcon}
                    sx={{ width: "60px", height: "80px" }}
                />
                <Stack sx={{ gap: 2 }}>
                  <Typography fontFamily="jost" fontSize="32px" fontWeight="400">
                    Forgot password?
                  </Typography>
                  <Typography fontFamily="jost" fontSize="18px" color={gray}>
                    No worries ,We will send you reset instruction
                  </Typography>
                  <Divider sx={{ border: "1px solid #F5F5F5" }} />
                </Stack>
              </>}
          {/* --------------------- FORM --------------------- */}
          <AppForm
              initialValues={{ email: "" }}
              validationSchema={forgetPasswordSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, { setSubmitting });
              }}
          >
            <Stack sx={{ gap: 5 }}>
              <AppFormField
                  label="Email"
                  name="email"
                  type="text"
                  labelStyle={{ fontSize: "18px" }}
                  placeholder="Enter your email"
              />

              <Stack sx={{ gap: 4 }}>
                <SubmitButton>Reset Password</SubmitButton>
              </Stack>
            </Stack>
          </AppForm>
        </Stack>
      </>
  );
}
