const Types = {
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMER_DETAILS_SUCCESS: "GET_CUSTOMER_DETAILS_SUCCESS",
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  CUSTOMERS_LOADING: "CUSTOMERS_LOADING",
  APPROVE_CUSTOMER_SUCCESS: "APPROVE_CUSTOMER_SUCCESS",
  REJECT_CUSTOMER_SUCCESS: "REJECT_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FOLLOWERS_LIST: 'GET_CUSTOMER_FOLLOWERS_LIST',
  GET_CUSTOMER_FOLLOWING_LIST: 'GET_CUSTOMER_FOLLOWING_LIST',
  CLEAR_FOLLOWERS_LIST: 'CLEAR_FOLLOWERS_LIST',
  CLEAR_FOLLOWING_LIST: 'CLEAR_FOLLOWING_LIST',
};

export default Types;
