import Types from "./Types";

export const GetZonesSuccess = (payload) => ({
  type: Types.GET_ZONES_SUCCESS,
  payload,
});

export const GetZoneDetailsSuccess = (payload) => ({
  type: Types.GET_ZONE_DETAILS_SUCCESS,
  payload,
});

export const ZonesLoading = (payload) => ({
  type: Types.ZONES_LOADING,
  payload,
});

export const GetScrollZonesSuccess = (payload) => ({
  type: Types.GET_SCROLL_ZONES_SUCCESS,
  payload,
});