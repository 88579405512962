import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordRequest } from "services/modules/users";
import ChangePasswordTemplate from "templates/change-password/ChangePassword";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router";
import { getDriverDetailsRequest } from "services/modules/users/driver";

export default function ChangeUserPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const driver_type = searchParams.get("driver_type");
  console.log(driver_type);
  const details = useSelector((state) => state.drivers.driver);
  // const driver_type = use
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: `/${driver_type}-drivers`,
      title: `Users/${driver_type} drivers`,
    },
    {
      active: false,
      path: "",
      title: "Change Password",
    },
  ];

  const handleSubmit = (values) => {
    const action = () => {
      toast.success("Password changed successfully");
      navigate("/employee-drivers");
    };
    const requestData = {
      body: {
        user: {
          password: values?.password,
          password_confirmation: values?.confirmPassword,
        },
      },
      params: {
        user_email: details?.email,
        user_type: "driver",
      },
      action,
    };

    dispatch(changePasswordRequest(requestData));
  };

  const modalComponents = [];

  useEffect(() => {
    if (id) dispatch(getDriverDetailsRequest({ id }));
  }, [id]);

  return (
    <>
      <ChangePasswordTemplate
        breadcrumbs={breadcrumbs}
        handleSubmit={handleSubmit}
        user={details}
        modalComponents={modalComponents}
      />
    </>
  );
}
