import { db } from "services/utils/firebase";
import { sendMessageRequest, visitChatRoomRequest } from ".";
import { ref, query, orderByChild, onValue, push, set, child, update } from 'firebase/database';
import { getPresignedUrlRequest } from "../uploadFiles";
import setAuthToken from "../utils/handel_api";

// Update the GetMessages function to unsubscribe from the previous node
let unsubscribe = null;

export const GetMessages = async (MessageNode, setMessagesList, messagesList) => {
    // Unsubscribe from the previous node listener
    if (unsubscribe) {
        unsubscribe();
    }
    const messagesQuery = query(ref(db, MessageNode), orderByChild("created_at"));
    unsubscribe = onValue(messagesQuery, (snapshot) => {
        const arr = Object.values(snapshot.val())
        setMessagesList(arr);
    });
};

export async function visitChatRoom({ chat_id, action, dispatch }) {
    const requestData = {
        chatId: chat_id,
        action,
    };
    dispatch(visitChatRoomRequest({ ...requestData }))

};

export const SendMessage = async ({ message, file = {}, sender_id, action, dispatch, type, chat_id }) => {
    const node = localStorage?.getItem('Messages_node')?.split('/')?.[1]
    let FirebaseDataObj = {};
    if (file?.url != null) {
        FirebaseDataObj = {
            created_at: new Date()?.toISOString(),
            sender_id: sender_id,
            text: message,
            sender_node_id: localStorage.getItem("Messages_node")
        };
        FirebaseDataObj = {
            ...FirebaseDataObj,
            file_name: file?.name,
            file_size: file?.size,
            file_type: file?.type,
            file_url: file?.url,
        };
    } else {
        FirebaseDataObj = {
            text: message,
            created_at: new Date().toISOString(),
            sender_id: sender_id,
            sender_node_id: localStorage.getItem("Messages_node")
        };
    }


    const newMessageRef = push(ref(db, localStorage.getItem('Messages_node')));
    await set(newMessageRef, FirebaseDataObj);

    let data
    const extraOptionsRef = ref(db, `extra_options/${node}`);

    await onValue(extraOptionsRef, async (snapshot) => {
        data = await snapshot.val();
    })

    const nodeRef = child(ref(db), `extra_options/${node}`); // id = custom ID you want to specify 

    await update(nodeRef, { ...data, last_message: FirebaseDataObj?.text ? FirebaseDataObj?.text : FirebaseDataObj?.file_url })


    const messageObj = {
        body: message || file?.url,
        chat_message_type: !type ? "text" : type
    }
    const requestData = {
        chatId: chat_id,
        body: messageObj,
        action,
    };
    dispatch(sendMessageRequest({ ...requestData }));
};

export const handleFiles = async (files, dispatch, updateData) => {
    const requestData = {
        body: {
            files: [
                {
                    extension: files?.type.slice(files?.type.indexOf("/") + 1),
                },
            ],
        },
        file: files,
        updateData: (data) => {
            const updatedFile = {
                name: files?.name,
                size: files?.size,
                type: files?.type?.split('/')?.[0],
                //files[0]?.type?.split("/")[1]
                url: data,
            };

            updateData(updatedFile);
            const token = localStorage.getItem("token");
            setAuthToken(token);
        },
    };
    dispatch(getPresignedUrlRequest({ ...requestData }))
};

export const handleFileInputs = (fileList, file) => {
    return [file[0]]
};

// export function sendMessageAPI(chat_id, messageObj, action, dispatch) {
//     const requestData = {
//         chatId: chat_id,
//         body: messageObj,
//         action,
//     };
//     dispatch(sendMessageRequest({ ...requestData }));
// };