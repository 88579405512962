import { Stack } from "@mui/material";
import FormUse from "components/Atoms/SharedComponents/CRUD/form-use";
import FormActions from "components/Molecules/Products/Variants/formActions";

export default function VariantDetailsForm({ formik, refsubmit, handleClose, structure }) {
  return (
    <Stack sx={{ overflowY: "auto", maxHeight: 700, }}>
      <FormUse formStructure={structure} formik={formik}>
        <FormActions refsubmit={refsubmit} handleClose={handleClose} />
      </FormUse>
    </Stack>
  );
}
