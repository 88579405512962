import { Button, Divider, Input, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import ModelBox from "components/Atoms/Model/Model";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import FormHeader from "components/Molecules/Products/Variants/formHeader";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function ApproveItemModel({
  openModel,
  setOpenModel,
  prepareList,
  updateRequestItem,
  itemDetails,
  type,
  load,
}) {
  const dispatch = useDispatch();
  const data = itemDetails?.returnable?.variant;
  const handleSubmit = (values, formik) => {
    const action = () => {
      dispatch(setOpenModel());
      formik?.resetForm();
    };

    const requestData = {
      id: itemDetails?.id,
      body: {
        return_request_item: {
          status: "accepted",
          retunable_price: values?.retunable_price,
        },
      },
      action,
    };

    updateRequestItem({ requestData });
  };
  const formik = useFormik({
    initialValues: {
      retunable_price: type == "edit" ? itemDetails?.returnable_id : "",
    },
    onSubmit: async (values) => {
      handleSubmit(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });
  return (
    <>
      <ModelBox
        openVar={openModel}
        closeFunc={() => {
          dispatch(setOpenModel(null));
          formik?.resetForm();
        }}
        padding={false}
        isHeaderVisiable={false}
        maxWidth={"650px"}
      >
        <FormHeader
          handleClose={(_) => {
            dispatch(setOpenModel(null));
            formik?.resetForm();
          }}
          title={"Approve Product"}
          padding={2}
        />
        <Divider />
        <form onSubmit={formik?.handleSubmit}>
          <Stack p={2} gap={4}>
            <Stack direction="row" gap="10px">
              <img
                alt="product img"
                src={data?.image}
                width="89px"
                height={"78px"}
                style={{ borderRadius: "2px" }}
              />
              <Stack>
                <Typography fontSize={"24px"}>{data?.name}</Typography>
                <Stack direction="row" alignItems="center" gap="5px">
                  <Typography fontSize={"24px"} fontWeight="700">
                    {data?.price}
                  </Typography>
                  <Typography fontSize="20px">
                    {data?.currency?.name}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            my={4}
            gap={2}
          >
            <Button
              sx={{
                color: "black",
                border: "1px solid #000000",
              }}
              width={"35%"}
              onClick={(_) => {
                dispatch(setOpenModel(null));
                formik?.resetForm();
              }}
            >
              Cancel
            </Button>
            <ContainedButton
              type="submit"
              title="Approve"
              load={load}
              width={"35%"}
            />
          </Stack>
        </form>
      </ModelBox>
    </>
  );
}
