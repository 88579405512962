/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  storeBranches: [],
  storeBranch: {},
  count: 0,
  load: null,
  error: null,
};

export function storeBranchesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_STORE_BRANCHES_SUCCESS: {
      return {
        ...state,
        storeBranches: payload?.data?.cities_stores,
        count: payload?.extra?.total_count,
      };
    }
    case Types.STORE_BRANCHES_LOADING: {
      console.log(payload);
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_STORE_BRANCHES_SUCCESS: {
      const branchToDelete = payload?.data?.current_city_store;
      const storeBranches = state.storeBranches.filter((branch) => branch.id !== branchToDelete?.id);
      return {
        ...state,
        storeBranches,
      };
    }
    case Types.ADD_STORE_BRANCHES_SUCCESS: {
      const branchToAdd = payload?.data?.city_store;
      const storeBranches = [...state.storeBranches, branchToAdd];
      return {
        ...state,
        storeBranches,
      };
    }

    case Types.UPDATE_STORE_BRANCHES_SUCCESS: {
      const branchToUpdate = payload?.data?.city_store;
      const storeBranches = state.storeBranches.map((branch) => {
        if (branch.id === branchToUpdate?.id) {
          return branchToUpdate;
        } else {
          return branch;
        }
      });

      return {
        ...state,
        storeBranches,
      };
    }
    case Types.GET_STORE_BRANCHES_DETAILS_SUCCESS: {
      return {
        ...state,
        storeBranch: payload?.data?.cities_store,
      };
    }

    default: {
      return state;
    }
  }
}
