import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import CActions from "components/Molecules/Crud/components/actions";
import ApproveItemModel from "components/Molecules/Order/returnRequest/model/approveItemModel";
import RejectionReasonDetails from "components/Molecules/Order/returnRequest/model/rejectionReasonDetails";
import RejectItemModel from "components/Molecules/Order/returnRequest/model/rejectItemModel";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isOpenApproveItemModel,
  isOpenRejectItemModel,
  isOpenRejectionDetailsModel,
} from "services/modules/modal/Actions";
import { StoreUser } from "services/modules/utils/constant";

export default function RetunableProducts({
  title,
  details,
  getReturnRequestItem,
  products,
  wrapper,
  giftCard,
  updateRequestItem,
  load,
}) {
  const { orderStatus } = useSelector((state) => state?.returnOrders);
  console.log(orderStatus, "orderStatus");
  const columns = [
    { accessor: "id", Header: "#" },
    { accessor: "product", Header: "Product Name" },
    { accessor: "sku", Header: "Sku", sort: true },
    { accessor: "price", Header: "Price", sort: true },
    { accessor: "status", Header: "Return Status", sort: true },
    orderStatus == "route_one_in_progress" && {
      accessor: "actions",
      Header: "Actions",
      sort: true,
    },
  ].filter((item) => item);
  const {
    yellow,
    backgroundYellow,
    red,
    backgroundRed,
    lightGreen,
    backgroundGreen,
  } = useThemePalette();
  const userType = localStorage.getItem("user_type");
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const {
    openApproveItemModel,
    openRejectItemModel,
    openRejectionDetailsModel,
  } = useSelector((state) => state.modal);
  const { itemDetails } = useSelector((state) => state.modal);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const prepareList = () => {
    let list = [];
    products && list?.push(...products);
    // wrapper and giftcard are not a part of the cart items
    // and have different structure
    // !so they have to be manually structured & added
    wrapper &&
      list?.returnable?.push({
        variant: {
          name: wrapper?.title,
          image: wrapper?.wrapper_image,
          price_after_discount: wrapper?.wrap_variant?.price_after_discount,
        },
        total_amount: wrapper?.cost_after_discount,
      });
    giftCard &&
      list.push({
        variant: {
          name: giftCard?.card?.name,
          image: giftCard?.card?.image,
          price_after_discount: giftCard?.card?.price_after_discount,
        },
        total_amount: giftCard?.card?.price_after_discount,
      });
    return list;
  };

  const actionsList = (id, data) => {
    const isAccepted = data?.status == "accepted";
    const isRejected = data?.status == "rejected";
    const isPending = data?.status == "pending";
    const isAdmin = userType != StoreUser;
    return [
      {
        condition: () => {
          return isPending && isAdmin && orderStatus == "route_one_in_progress";
        },
        click: () => {
          dispatch(isOpenApproveItemModel({ data }));
          return true;
        },
        text: "Approve",
      },
      {
        condition: () => {
          return isPending && isAdmin && orderStatus == "route_one_in_progress";
        },
        click: () => {
          dispatch(isOpenRejectItemModel(data));
        },
        text: "reject",
      },
      {
        condition: () => {
          return isRejected && isAdmin;
        },
        click: () => {
          dispatch(isOpenRejectionDetailsModel({ data, type: "view" }));
        },
        text: "View reason",
      },
      {
        condition: () => {
          return isRejected && isAdmin;
        },
        click: () => {
          dispatch(isOpenRejectionDetailsModel({ data, type: "edit" }));
        },
        text: "Edit reason",
      },
      {
        condition: () => {
          return isAccepted && isAdmin;
        },
        click: () => {
          dispatch(isOpenApproveItemModel({ data, type: "edit" }));
        },
        text: "Edit",
      },
    ];
  };

  const Actions = (id, elmIndex, data) => {
    return (
      <CActions
        id={id}
        index={elmIndex}
        data={data}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        anchorEl={anchorEl}
        actionsList={(_) => actionsList(id, data)}
      />
    );
  };
  const mappedData = prepareList()?.map((data, index) => {
    const isWrapper = data?.wrapper;
    const height = "70px";
    return {
      ...data,
      id: (
        <Stack gap={2}>
          {index + 1}
          {isWrapper && <Stack sx={{ height }} />}
        </Stack>
      ),
      product: (
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Box
              component="img"
              src={data?.returnable?.variant?.image}
              width="50px"
            />
            <Typography fontWeight="500">
              {data?.returnable?.variant?.name}
            </Typography>
          </Stack>
          {isWrapper && (
            <Stack>
              <Typography sx={{ color: "#9F9F9F" }}>Wrapping</Typography>
              <Stack direction="row" alignItems="center">
                <Box
                  component="img"
                  src={data?.returnable?.wrapper?.wrapper_image}
                  width="50px"
                />
                <Typography fontWeight="500">
                  {data?.returnable?.wrapper?.title}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      ),
      sku: (
        <Stack gap={2}>
          <Typography fontWeight="500">
            {data?.returnable?.variant?.sku || "-"}
          </Typography>
          {isWrapper && <Stack sx={{ height }}></Stack>}
        </Stack>
      ),
      price: (
        <Stack gap={2}>
          {data?.returnable?.variant?.price_after_discount}{" "}
          {data?.returnable?.currency?.name}
          {isWrapper && (
            <Stack sx={{ height }}>
              <Typography sx={{ color: "#9F9F9F" }}>Price</Typography>
              <Typography fontWeight="400">
                {data?.returnable?.wrapper?.cost_after_discount}{" "}
                {data?.returnable?.currency?.name}
              </Typography>
            </Stack>
          )}
        </Stack>
      ),
      returned_price: (
        <Stack gap={2}>
          {data?.returnable?.returned_price || 0}{" "}
          {data?.returnable?.currency?.name}
        </Stack>
      ),
      status: (
        <Stack
          gap={2}
          alignItems="center"
          sx={{
            bgcolor:
              data?.status == "pending"
                ? backgroundYellow
                : data?.status == "accepted"
                ? backgroundGreen
                : backgroundRed,
            color:
              data?.status == "pending"
                ? yellow
                : data?.status == "accepted"
                ? lightGreen
                : red,
            maxWidth: "130px",
            paddingX: 1,
            paddingY: 0.5,
            fontWeight: "500",
          }}
        >
          {data?.status}
        </Stack>
      ),
      actions: Actions(data?.id, index, data),
    };
  });

  return (
    <Stack sx={{ backgroundColor: "white" }}>
      <Stack width="50%" px={3} pt={3} mb={-3}>
        <Typography fontSize="32px" fontWeight="500" fontFamily="Jost">
          {title}
        </Typography>
      </Stack>

      <TableUse
        list={mappedData}
        columnsData={columns}
        setTableData={() => void 0}
        setFilterObj={() => void 0}
      />
      {openApproveItemModel && (
        <ApproveItemModel
          setOpenModel={isOpenApproveItemModel}
          openModel={openApproveItemModel}
          prepareList={prepareList}
          updateRequestItem={updateRequestItem}
          itemDetails={itemDetails?.data}
          type={itemDetails?.type}
          load={load?.loading && load?.type == "updateReturnRequest"}
        />
      )}
      {openRejectItemModel && (
        <RejectItemModel
          setOpenModel={isOpenRejectItemModel}
          openModel={openRejectItemModel}
          prepareList={prepareList}
          updateRequestItem={updateRequestItem}
          itemDetails={itemDetails}
          load={load?.loading && load?.type == "updateReturnRequest"}
        />
      )}
      {openRejectionDetailsModel && (
        <RejectionReasonDetails
          setOpenModel={isOpenRejectionDetailsModel}
          openModel={openRejectionDetailsModel}
          prepareList={prepareList}
          updateRequestItem={updateRequestItem}
          itemDetails={itemDetails?.data}
          type={itemDetails?.type}
          load={load?.loading && load?.type == "updateReturnRequest"}
        />
      )}
    </Stack>
  );
}
