import { useNavigate } from "react-router-dom";
import { useState } from "react";

function utils({
  approvalStatusOptions,
  cities,
  setFilterObj,
  tags,
  handleResetPassword,
  setStatus,
  setOpenConfirmationModal,
  setApproval,
  setOpenEditRejectModal,
  setRejectionReason,
  navigate,
  getCities,
  filterCitites,
  cititesCount,
  getTags,
  filterTags,
  tagsCount,
  isSuperAdmin,
  permissions,
  isStore,
  id,
  currentUser,
}) {
  const filterList = [
    {
      type: "filter",
      placeholder: "Cities",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, city_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterCitites?.length == 0 && getCities({});
      },
      options: filterCitites && filterCitites,
      getList: getCities,
      count: cititesCount,
      applySearchFromRequest: true,
    },
    {
      type: "filter",
      placeholder: "Tags",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, tag_ids: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterTags?.length == 0 && getTags({});
      },
      options: filterTags && filterTags,
      getList: getTags,
      count: tagsCount,
      applySearchFromRequest: true,
    },
    {
      type: "filter",
      placeholder: "Approval status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, profile_status: value?.value, page_number: 1 };
        });
      },
      options: approvalStatusOptions,
    },
    {
      type: "filter",
      placeholder: "Profile status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value, page_number: 1 };
        });
      },
      options: [
        { id: "active", name: "Active" },
        { id: "suspended", name: "Suspend" },
        { id: "deactivated", name: "Delete" },
      ],
    },
  ];
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return (isSuperAdmin || permissions?.can_read) && !isStore;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View Profile",
      },
      {
        condition: () => {
          return (
            data?.details?.profile_status === "approved" &&
            data?.details?.status === "active" &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          navigate(`/vendors/${data?.id}?type=edit`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return (
            data?.details?.profile_status === "approved" &&
            data?.details?.status === "active" &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          isSuperAdmin ? navigate(`change-password/${data?.id}`) : handleResetPassword();
        },
        text: "Change Password",
      },
      {
        condition: () => {
          return (
            data?.details?.profile_status === "approved" &&
            data?.details?.status === "active" &&
            (isSuperAdmin || (permissions?.can_update && !isStore))
          );
        },
        click: () => {
          setOpenConfirmationModal(true);
          setStatus({ display: "Suspend", api: "suspended" });
        },
        text: "Suspend",
      },
      {
        condition: () => {
          return (
            data?.details?.profile_status === "approved" &&
            (data?.details?.status === "active" || data?.details?.status === "suspended") &&
            (isSuperAdmin || permissions?.can_delete || (isStore && currentUser?.id == id))
          );
        },
        click: () => {
          setStatus({ display: "Delete", api: "deleted" });

          setOpenConfirmationModal(true);
        },
        text: "Deleted",
      },
      {
        condition: () => {
          return (
            (data?.details?.profile_status === "pending_approval" || data?.details?.profile_status === "rejected") &&
            (isSuperAdmin || permissions?.can_update) &&
            !isStore
          );
        },
        click: () => {
          setStatus({ display: "Approve", api: "approved" });
          setOpenConfirmationModal(true);
        },
        text: "Approve",
      },
      {
        condition: () => {
          return data?.details?.profile_status === "pending_approval" && (isSuperAdmin || permissions?.can_update) && !isStore;
        },
        click: () => {
          setApproval("reject");
          setOpenEditRejectModal({ action: true, reason: "", type: "reject" });
        },
        text: "Reject",
      },
      {
        condition: () => {
          return (
            data?.details?.profile_status === "approved" &&
            data?.details?.status === "suspended" &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          setOpenConfirmationModal(true);
          setStatus({ display: "Active", api: "active" });
        },
        text: "Active",
      },
      {
        condition: () => {
          return data?.details?.profile_status === "rejected" && (isSuperAdmin || permissions?.can_update);
        },
        click: () => {
          setRejectionReason(data?.details?.rejection_reason);
          setOpenEditRejectModal({
            action: true,
            reason: data?.details?.rejection_reason,
            type: "edit",
          });
        },
        text: "Edit Reason",
      },
    ];
  };
  return { filterList, actionsList };
}
export default utils;
