import Types from "./Types";

export const GetNotificationsSuccess = (payload) => ({
  type: Types.GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const NotificationsLoading = (payload) => ({
  type: Types.NOTIFICATIONS_LOADING,
  payload,
});

export const GetUnreadCountSuccess = (payload) => ({
  type: Types.GET_UNREAD_COUNT_SUCCESS,
  payload,
});

export const GetUnreadCountLoading = (payload) => ({
  type: Types.UNREAD_COUNT_LOADING,
  payload,
});

export const SetFCMToken = (payload) => ({
  type: Types.SET_FCM_TOKEN_SUCCESS,
  payload,
});

// export const MarkAsRead = (payload) => ({
//   type: Types.MARK_AS_READ_SUCCESS,
//   payload,
// });

// export const MarkAllAsRead = (payload) => ({
//   type: Types.MARK_ALL_AS_READ_SUCCESS,
//   payload,
// });
