import { Stack } from "@mui/material";
import Header from "components/Atoms/SharedComponents/Details/header";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";

export default function PageHeader({ breadcrumbs, title }) {
  return (
    <>
      <Stack
        direction="row"
        alignSelf="start"
        justifyContent="start"
        gap={2}
        width="100%"
        mb={1}
      >
        <Breadcrumb data={breadcrumbs} />
      </Stack>
      <Header title={title} deleteBtn={false} editBtn={false} />
    </>
  );
}
