import ModelBox from "components/Atoms/Model/Model";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isOpenViewDestinationModel } from "services/modules/modal/Actions";
import MapDirectionsRenderer from "../Destination";

export default function ViewDestinationModel({ places, index }) {
  const { openViewDestinationModel, routeIndex } = useSelector(
    (state) => state.modal
  );
  const dispatch = useDispatch();
  const isVisible = openViewDestinationModel && routeIndex === index;

  return (
    <>
      <ModelBox
        openVar={openViewDestinationModel && isVisible}
        closeFunc={() => dispatch(isOpenViewDestinationModel())}
        padding={false}
        title={"Destinations"}
        titleStyle={{
          fontSize: { lg: "32px", xs: "18px" },
          fontWeight: "500",
          px: { lg: "16px", xs: "15px" },
        }}
      >
        <MapDirectionsRenderer places={places} />
      </ModelBox>
    </>
  );
}
