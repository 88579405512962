import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { detailsData } from "./details";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";

const ZonesTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  formChildren,
  fulfillmentCenters,
  can_create,
  options
}) => {
  const navigate = useNavigate();
  const { graySex, textGray } = useThemePalette();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Zone details",
            data: {
              images: details?.image && [{ id: 0, img: details?.image }],
              list: detailsData({ data: details }),
              checkBox: [
                {
                  checked: details?.is_fulfillment_center,
                  label: "Fulfillment Center",
                },
              ],
            },
          }}
          table={{
            list: data,
            listTitle: "Zones",
            createBtnTitle: can_create && "New Zone",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "localized_data.en.name",
                Header: "Zone Name(EN)",
                sort: true,
              },
              {
                accessor: "localized_data.ar.name",
                Header: "Zone Name(AR)",
                sort: true,
              },
              {
                accessor: "is_fulfillment_center",
                Header: "Is Fulfillment Center zone",
              },
              {
                accessor: "google_city.name",
                Header: "City",
              },
              { accessor: "radius", Header: "Radius" },
              { accessor: "delivery_fees", Header: "Delivery fees" },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search by zone name",
            isChecked: false,
            total_count: count,
            isSort: true,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,

            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id
              ? { is_fulfillment_center: false }
              : initialValues(details, supportedLang),
            structure: () => structure(options, fulfillmentCenters),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit Zone" : "Add Zone",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
            formChildren,
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ZonesTemplate;
