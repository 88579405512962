/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  adminUsers: [],
  filterRoles: [],
  adminUser: {},
  roles: [],
  role: {},
  resources: [],
  count: 0,
  listCount: 0,
  load: null,
  error: null,
};

export default function adminUsersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    //! ADMINS
    case Types.GET_ADMIN_USERS_SUCCESS: {
      return {
        ...state,
        adminUsers: payload?.data?.user,
        count: payload?.extra?.total_count,
      };
    }
    case Types.CREATE_ADMIN_USER_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_ADMIN_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        adminUser: payload?.data?.user,
      };
    }
    case Types.UPDATE_ADMIN_USER_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.DELETE_ADMIN_USER_SUCCESS: {
      return {
        ...state,
      };
    }
    //! ROLES
    case Types.GET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: payload?.data?.roles,
        count: payload?.extra?.total_count,
      };
    }
    case Types.ROLES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_ROLE_DETAILS_SUCCESS: {
      return {
        ...state,
        role: payload?.data?.role,
      };
    }
    case Types.CREATE_ROLE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.DELETE_ROLE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
      };
    }
    //! RESOURCES
    case Types.GET_RESOURCES_SUCCESS: {
      return {
        ...state,
        resources: payload?.data?.resources,
      };
    }
    case Types.GET_SCROLL_ROLES_SUCCESS: {
      return {
        ...state,
        filterRoles: payload?.extra?.page_number == 1
          ? payload?.data?.roles
          : [...state.filterRoles, ...payload?.data?.roles],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
