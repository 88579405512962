import { Button } from '@mui/material'
import { icons } from 'assets/AssetHelper'
import React from 'react'

export default function DocumentMessage({files}) {
    return <>
        <Button
            target="_blank"
            alt='files'
            href={files}
            sx={{
                minWidth: '40px',
                height: '40px',
                p: '0 !important',
                // color: 'white',
                '& .MuiButton-startIcon': {
                    m: '0!important',
                }
            }}
            startIcon={<img src={icons.drive_pdf} style={{ marginRight: '8px' }} alt='pdf' />}
        >
            document
        </Button>
    </>
}
