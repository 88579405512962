import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import {
  FirebaseApiKey,
  FirebaseAppId,
  FirebaseAuthDomain,
  FirebaseDatabaseUrl,
  FirebaseMeasurmentId,
  FirebaseProjestId,
  FirebaseSenderID,
  FirebaseStorageBucket,
  FirebaseVAPIDKey,
} from "../../services/modules/utils/constant";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// import firebase from "firebase/compat/app";
// import firebase from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import "firebase/compat/database";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: FirebaseApiKey,
  authDomain: FirebaseAuthDomain,
  databaseURL: FirebaseDatabaseUrl,
  projectId: FirebaseProjestId,
  storageBucket: FirebaseStorageBucket,
  messagingSenderId: FirebaseSenderID,
  appId: FirebaseAppId,
  measurementId: FirebaseMeasurmentId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);

const messaging = getMessaging();

export const requestForFCMToken = async (setNewFCMToken) => {
  return await getToken(messaging, {
    vapidKey: FirebaseVAPIDKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        setNewFCMToken(currentToken);
      } else {
        setNewFCMToken(null);
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
// const RealTimeDatabase = firebase.database();
// export { RealTimeDatabase };
