/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  cities: [],
  filterCitites: [],
  city: {},
  count: 0,
  listCount: 0,
  load: null,
};

export function citiesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.CITIES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: payload?.data?.cities,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_CITY_DETAILS_SUCCESS: {
      return {
        ...state,
        city: payload?.data?.city,
      };
    }
    case Types.GET_SCROLL_CITIES_SUCCESS: {
      return {
        ...state,
        filterCitites: payload?.extra?.page_number == 1
          ? payload?.data?.cities
          : [...state.filterCitites, ...payload?.data?.cities],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
