import { Box, Stack, Typography } from '@mui/material'
import { useThemePalette } from 'common/hooks/theme_palette'
import React from 'react'

export default function ColorRow({ data }) {
    const { textGray } = useThemePalette()
    return <Stack direction='row' columnGap='8px' >
        <Box width='24px' height='24px' bgcolor={data?.presentation} borderRadius='50%' >
        </Box>
        <Typography color={textGray} fontFamily='Jost'>{data?.presentation}</Typography>
    </Stack>
}
