import Types from "./Types";


// ----------------- SHAPES START-----------------
export const GetColorsSuccess = (payload) => ({
  type: Types.GET_COLORS_SUCCESS,
  payload,
});

export const GetColorsLoading = (payload) => ({
  type: Types.COLORS_LOADING,
  payload,
});


export const AddColorSuccess = (payload) => ({
  type: Types.ADD_COLOR_SUCCESS,
  payload,
});


export const DeleteColorSuccess = (payload) => ({
  type: Types.DELETE_COLOR_SUCCESS,
  payload,
});


export const UpdateColorSuccess = (payload) => ({
  type: Types.UPDATE_COLOR_SUCCESS,
  payload,
});

export const GetColorDetailsSuccess = (payload) => ({
  type: Types.GET_COLOR_DETAILS_SUCCESS,
  payload,
});

export const GetScrollColorsSuccess = (payload) => ({
  type: Types.GET_SCROLL_COLORS_SUCCESS,
  payload,
});

// ----------------- SHAPES END-----------------
