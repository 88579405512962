import Types from "./Types";


// ----------------- SHAPES START-----------------
export const GetWrapVariantsSuccess = (payload) => ({
  type: Types.GET_WRAP_VARIANTS_SUCCESS,
  payload,
});

export const GetWrapVariantsLoading = (payload) => ({
  type: Types.WRAP_VARIANTS_LOADING,
  payload,
});

export const AddWrapVariantSuccess = (payload) => ({
  type: Types.ADD_WRAP_VARIANT_SUCCESS,
  payload,
});

export const AddWrapFailed = (payload) => ({
  type: Types.ADD_WRAP_VARIANT_FAILED,
  payload,
});


export const DeleteWrapVariantSuccess = (payload) => ({
  type: Types.DELETE_WRAP_VARIANT_SUCCESS,
  payload,
});

export const DeleteWrapVariantFailed = (payload) => ({
  type: Types.DELETE_WRAP_VARIANT_FAILED,
  payload,
});

export const UpdateWrapVariantSuccess = (payload) => ({
  type: Types.UPDATE_WRAP_VARIANT_SUCCESS,
  payload,
});

export const GetWrapVariantDetailsSuccess = (payload) => ({
  type: Types.GET_WRAP_VARIANT_DETAILS_SUCCESS,
  payload,
});

export const GetScrollWrapVariantSuccess = (payload) => ({
  type: Types.GET_SCROLL_WRAP_VARIANT_SUCCESS,
  payload,
});
// ----------------- SHAPES END-----------------
