import { makeFilterString } from "../utils/Helper";
import { reduxRequest } from "common/utils/reduxRequest";
import {
  AssignToEmployeeSuccess,
  AssignToFreelancerSuccess,
  GetDriverComplainsSuccess,
  GetEmployeesSuccess,
  GetFreelancerSuccess,
  GetOrderDetailsLoading,
  GetOrderDetailsSuccess,
  SetOrederReadyForDeliverySuccess,
  UnAssignDriverSuccess,
  CancelOrderSuccess,
  GetOrdersSuccess,
  UpdateDriverComplainsSuccess,
  GetOrderStatusSuccess
} from "./Action";



export const getOrderDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "details",
    successFunction: GetOrderDetailsSuccess,
    url: `/admin/orders/${variables?.order_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const getEmployeesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "listEmployees",
    successFunction: GetEmployeesSuccess,
    url: `/admin/orders/${variables?.id}/${variables?.route_type}/employees${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const getOrdersRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "list",
    successFunction: GetOrdersSuccess,
    url: `/admin/orders?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getFreelancerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "listFreelancer",
    successFunction: GetFreelancerSuccess,
    url: `/admin/order_requests/${variables?.body?.route_id}`,
    method: "get"
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const cancelOrderRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "cancel",
    successFunction: CancelOrderSuccess,
    url: `/admin/orders/${variables?.id}/cancel`,
    action: variables?.action,
    method: "patch",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const assignEmployeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "assignDriver",
    successFunction: AssignToEmployeeSuccess,
    // url: `/admin/orders/${variables?.order_id}/assign_routes?route_type=${variables?.route_type}&driver_type=${variables?.driver_type}&driver_id=${variables?.driver_id}&system_configuration_id=${variables?.system_configuration_id}`,
    // url: variables?.body?.system_configuration_id ? `/admin/orders/${variables?.body?.order_id}/assign_routes?route_type=${variables?.body?.route_type}&driver_type=${variables?.body?.driver_type}&driver_id=${variables?.body?.driver_id}&system_configuration_id=${variables?.body?.system_configuration_id}` : `/admin/orders/${variables?.body?.order_id}/assign_routes?route_type=${variables?.body?.route_type}&driver_type=${variables?.body?.driver_type}&driver_id=${variables?.body?.driver_id}`,
    url: `/admin/orders/${variables?.body?.order_id}/assign_routes?route_type=${variables?.body?.route_type}&driver_type=${variables?.body?.driver_type}&driver_id=${variables?.body?.driver_id}`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const assignFreelancerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "assignFreelancerDriver",
    successFunction: AssignToFreelancerSuccess,
    url: `/admin/orders/${variables?.body?.order_id}/accept_freelancer?route_type=${variables?.body?.route_type}&driver_id=${variables?.body?.driver_id}`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const sendRagnesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: `range ${variables?.body?.system_configuration_id}`,
    successFunction: AssignToFreelancerSuccess,
    url: `/admin/orders/${variables?.body?.order_id}/assign_routes?route_type=${variables?.body?.route_type}&driver_type=${variables?.body?.driver_type}&system_configuration_id=${variables?.body?.system_configuration_id}`,
    // url: `/admin/orders/${variables?.body?.order_id}/assign_routes?route_type=${variables?.body?.route_type}&driver_type=${variables?.body?.driver_type}&driver_id=${variables?.body?.driver_id}`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const unAssignDriverRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "unassignDriver",
    successFunction: UnAssignDriverSuccess,
    url: `/admin/orders/${variables?.body?.order_id}/unassign_routes?route_type=${variables?.body?.route_type}&driver_id=${variables?.body?.driver_id}`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const setOrederReadyForDeliveryRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "readyForDelivery",
    successFunction: SetOrederReadyForDeliverySuccess,
    url: `/admin/orders/${variables?.body?.order_id}/mark_ready_for_delivery`,
    method: "put",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const getDriverComplainDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "driver complain",
    successFunction: GetDriverComplainsSuccess,
    url: `/admin/complaints/route_complaints/${variables?.id}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateDriverComplainRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "driver",
    successFunction: UpdateDriverComplainsSuccess,
    url: `/admin/complaints/route_complaints/${variables?.route_complaint_id}`,
    data: variables?.body,
    action: variables?.action,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const getOrderStatusRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOrderDetailsLoading,
    loadingType: "orderStatus",
    successFunction: GetOrderStatusSuccess,
    url: `/admin/orders/${variables?.id}/basic_view`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};