import * as Yup from "yup";

export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
    ...data?.dimensions,
    wrap_id: data?.wrap?.id,
  }
};

export const structure = ( wrapId, options) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `image`,
        kind: "upload",
        type: 'image',
        label: "Wrap Photo",
        placeholder: 'test',
        text: 'JPG and PNG  file size no more than',
        width: "47%",
      },
      {
        width: "47%",
      },
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Shape Name(EN)",
        width: "47%",
        placeholder: "Write Shape Name(EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Shape Name(AR)",
        width: "47%",
        placeholder: "Write Shape Name(AR)"
      },
      {
        name: `wrap_id`,
        kind: "select",
        type: "text",
        label: "Choose wrap",
        width: "47%",
        placeholder: "Choose wrap",
        // options: wrapsOptionsListArray
        ...options?.wraps
      },
      {
        name: `price`,
        kind: "input",
        type: "number",
        label: "Price",
        width: "23%",
        placeholder: "Enter price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }}>SAR</p>
        )
      },
      {
        name: `price_after_discount`,
        kind: "input",
        type: "number",
        label: "Price after discount",
        width: "23%",
        placeholder: "Enter price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        )
      },
      {
        name: `width`,
        kind: "input",
        type: "number",
        label: "Width",
        width: "47%",
        placeholder: "Write Width"
      },
      wrapId == "three" && {
        name: `height`,
        kind: "input",
        type: "number",
        label: "Height",
        width: "47%",
        placeholder: "Write Weight",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >cm</p>
        ),
      },

      {
        name: `weight`,
        kind: "input",
        type: "number",
        label: "Weight",
        width: "47%",
        placeholder: "Write Weight",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >gm</p>
        )
      },
      wrapId == "three" && {
        name: `length`,
        kind: "input",
        type: "number",
        label: "Depth",
        width: "47%",
        placeholder: "Write depth",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >cm</p>
        )
      },
    ],
  },
];


export const validationSchema = (wrapId) =>
  Yup.object({
    image: Yup.string().required('Required'),
    en: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    price: Yup.number().required("required").min(0),
    price_after_discount: Yup.number().required("required"),
    height: wrapId == "three" && Yup.number().required("required"),
    length: wrapId == "three" && Yup.number().required("required"),
    weight: Yup.number().required("required"),
    width: Yup.number().required("required"),
    wrap_id: Yup.number().required("required"),
  },
  );