import Types from "./Types";


export const GetOccasionTypesSuccess = (payload) => ({
  type: Types.GET_OCCASIONS_TYPES_SUCCESS,
  payload,
});

export const GetOccasionTypesLoading = (payload) => ({
  type: Types.OCCASIONS_TYPES_LOADING,
  payload,
});

export const AddOccasionsTypesSuccess = (payload) => ({
  type: Types.ADD_OCCASIONS_TYPES_SUCCESS,
  payload,
});

export const DeleteOccasionsTypesSuccess = (payload) => ({
  type: Types.DELETE_OCCASIONS_TYPES_SUCCESS,
  payload,
});

export const UpdateOccasionsTypesSuccess = (payload) => ({
  type: Types.UPDATE_OCCASIONS_TYPES_SUCCESS,
  payload,
});

export const GetOccasionsTypesDetailsSuccess = (payload) => ({
  type: Types.GET_OCCASIONS_TYPES_DETAILS_SUCCESS,
  payload,
});

export const GetScrollOccasionsTypesSuccess = (payload) => ({
  type: Types.GET_SCROLL_OCCASIONS_TYPES_SUCCESS,
  payload,
});