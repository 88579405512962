import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function UsersInfo({ data }) {
  const { textGray } = useThemePalette();
  return (
    <>
      <Stack gap="14px">
        <Typography fontSize="32px" color={textGray}>
          {data?.name}
        </Typography>
        <Typography fontSize="24px">{data?.email}</Typography>
      </Stack>
    </>
  );
}
