import { makeFilterString } from "../../utils/Helper";
import { reduxRequest } from "common/utils/reduxRequest";
import { AddWrapVariantSuccess, DeleteWrapVariantSuccess, GetScrollWrapVariantSuccess, GetWrapVariantDetailsSuccess, GetWrapVariantsLoading, GetWrapVariantsSuccess, UpdateWrapVariantSuccess } from "./Action";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetWrapVarintsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapVariantsLoading,
    loadingType: "list",
    successFunction: GetWrapVariantsSuccess,
    url: `/admin/wrappers/wrap_variants${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollWrapVarintRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapVariantsLoading,
    loadingType: "wrap-variant-scroll-list",
    successFunction: GetScrollWrapVariantSuccess,
    url: `/admin/wrappers/wrap_variants${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteWrapVariantRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapVariantsLoading,
    successFunction: DeleteWrapVariantSuccess,
    loadingType: "delete wrap",
    action: variables?.action,
    url: `/admin/wrappers/wrap_variants/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const addWrapVariantRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapVariantsLoading,
    loadingType: "add",
    successFunction: AddWrapVariantSuccess,
    action: variables?.action,
    url: `/admin/wrappers/wrap_variants`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




export const updateWrapVariantRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapVariantsLoading,
    loadingType: "update shape",
    successFunction: UpdateWrapVariantSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/wrappers/wrap_variants/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const getWrapVariantDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapVariantsLoading,
    loadingType: "details",
    successFunction: GetWrapVariantDetailsSuccess,
    url: `/admin/wrappers/wrap_variants/${variables?.wrap_variant_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




