import {
  Stack,
  Popper,
  Paper,
  Backdrop,
  ClickAwayListener,
  Button,
  Box,
  Typography,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useThemePalette } from "common/hooks/theme_palette";

export default function CustomerReviewCard({
  index,
  data,
  open,
  anchorEl,
  anchorRef,
  handleClick,
  handleClose,
}) {
  const { orange } = useThemePalette();
  return (
    <Box
      style={{
        position: "relative",
        width: "fit-content",
      }}
    >
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e, index, data)}
        sx={{
          width: "15px !important",
          display: "flex",
          p: 0,
          justifyContent: "start",
        }}
      >
        <RemoveRedEyeOutlinedIcon sx={{ color: orange }} />
      </Button>

      <Popper
        open={anchorEl == index ? true : false}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          minWidth: "100px",
          "&": {
            position: "absolute !important",
            zIndex: "10",
            top: "25px !important",
            left: "-50px !important",
          },
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.10)",
          }}
          open={anchorEl == index ? true : false}
          onClick={handleClose}
        ></Backdrop>
        <Paper
          sx={{
            position: "relative",
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Stack sx={{ width: 400, p: 2 }}>
              <Typography fontSize="24px" fontWeight="500">
                {data?.reviewer_name}
              </Typography>
              <Typography fontSize="18px" fontWeight="400" color={"#757575"}>
                {data?.comment}
              </Typography>
            </Stack>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
}
