import { makeFilterString } from "../utils/Helper";
import {
  GetChatRoomsLoading,
  GetChatRoomsSuccess,
  createChatRoomSuccess,
  getChatRoomSuccess,
  searchChatRommsLoading,
  searchChatRoomSuccess,
  sendMessageSuccess,
  visitChatRoomSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetChatRoomsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetChatRoomsLoading,
    loadingType: "list",
    successFunction: GetChatRoomsSuccess,
    url: `/admin/chats/chat_rooms${makeFilterString(variables?.params)}`,
    // url: `/admin/chats/chat_rooms`,
    method: "get",
    action: variables?.action,
    variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetChatRoomRequest = ({ ...variables }) => {
  console.log(variables?.chatId);
  const requestData = {
    isLoading: GetChatRoomsLoading,
    loadingType: "details",
    successFunction: getChatRoomSuccess,
    url: `/admin/chats/chat_rooms/${variables?.chatId}/show`,
    method: "get",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const searchChatRoomsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: searchChatRommsLoading,
    loadingType: "search",
    successFunction: searchChatRoomSuccess,
    url: `/admin/chats/chat_rooms/search${makeFilterString(variables?.params)}`,
    method: "get",
    action: variables?.action,
    variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const sendMessageRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetChatRoomsLoading,
    loadingType: "send messgage",
    successFunction: sendMessageSuccess,
    action: variables?.action,
    url: `/admin/chats/chat_rooms/${variables?.chatId}/send_message`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  }
};




export const visitChatRoomRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetChatRoomsLoading,
    loadingType: "visit chatroom",
    successFunction: visitChatRoomSuccess,
    action: variables?.action,
    url: `/admin/chats/chat_rooms/${variables?.chatId}/visit`,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  }
};


export const createChatRoomRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetChatRoomsLoading,
    loadingType: "create chatroom",
    successFunction: createChatRoomSuccess,
    action: variables?.action,
    url: `/admin/chats/chat_rooms`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  }
};




