import Types from "./Types";

export const GetSystemConfigLoading = (payload) => ({
  type: Types.GET_SYSTEM_CONFIG_LOADING,
  payload,
});

export const GetSystemConfigSuccess = (payload) => ({
  type: Types.GET_SYSTEM_CONFIGURATION_SUCCESS,
  payload,
});

export const GetSystemConfigDetailsSuccess = (payload) => ({
  type: Types.GET_SYSTEM_CONFIGURATION_DETAILS_SUCCESS,
  payload,
});
