import { Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import React from "react";

export default function AvailableFreelancers({
  data,
  setSelectedEmployee,
  selectedEmployee,
  handleAssignFreelancer,
  isRouteOne,
  load,
}) {
  const { graySix } = useThemePalette();

  //      action = () => {
  //     toast.success(`Employee assigned successfully`);
  // };
  // const requestData = {
  //   body: {
  //     order_id: id,
  //     route_type: isRouteOne(orderStatus) ? 'start_route' : 'finish_route',
  //     driver_type: selectedEmployee?.driver_type,
  //     driver_id: selectedEmployee?.id,
  //   },
  //   action,
  // };
  // dispatch(assignFreelancerRequest({ ...requestData }))
  // dispatch(isOpenAssignToFreelancer());
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        p={"10px 17px "}
        border={`1px solid ${graySix}`}
        sx={{ cursor: "pointer" }}
      >
        <Stack direction="row" gap="8px">
          <img
            alt="driver img"
            src={data?.avatar}
            width={"92px"}
            height={"92px"}
            style={{ borderRadius: "4px" }}
          />
          <Stack gap="12px" justifyContent="center">
            <Typography color="black">{data?.name}</Typography>
            <Stack direction="row" gap="5px">
              <img
                alt="driver img"
                src={icons?.phoneIcon}
                width="24px"
                height="24px"
              />
              <Typography color="black">{data?.phone_number}</Typography>
            </Stack>
            <Stack direction="row" gap="5px">
              <img
                alt="driver img"
                src={icons?.locationOutlined}
                width="24px"
                height="24px"
              />
              <Typography color="black">
                {Math.floor(data?.distance_to)} M{" "}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack justifyContent="space-between" alignItems="end">
          <Typography fontWeight={500} fontSize="20px">
            {data?.fees} SAR{" "}
          </Typography>
          <ContainedButton
            title="Assign"
            onClick={(_) => {
              setSelectedEmployee(data);
              handleAssignFreelancer(data);
            }}
            load={load?.loading && load?.type == "assignFreelancerDriver"}
            width="fit-content"
          />
        </Stack>
      </Stack>
    </>
  );
}
