import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import GetDirection from "components/Atoms/SharedComponents/Orders/getDirection";
import { openGoogleMaps } from "services/modules/utils/Helper";

export default function LocationCard({ data, step }) {
  const isFulfillment = data?.name?.toLowerCase()?.includes("fulfillment");
  const isStore =
    data?.name?.toLowerCase()?.includes("location") &&
    !data?.name?.toLowerCase()?.includes("customer");

  const locationInfo = {
    [`${!isStore ? "Customer" : "Store"} Name`]: !isStore
      ? data?.customerInfo?.name
      : data?.destination?.store?.name,
    "Phone number": !isStore
      ? data?.customerInfo?.mobile
      : `+(${data?.destination?.store?.details?.country_code}) ${data?.destination?.store?.details?.phone_number}`,
    Address: !isStore
      ? data?.customerInfo?.address
      : data?.destination?.detailed_address || "No address available",
  };
  return (
    <Stack sx={{ width: "47%" }} gap={3}>
      <Stack direction="row" gap={3}>
        <Stack direction={"row"} alignItems="center">
          <Box
            component="img"
            src={icons.locationOutlined}
            width="24px"
            height="24px"
            pr="4px"
          />
          <Typography>{data?.name}</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            background:
              data?.status === "pending"
                ? "rgba(255, 204, 0, 0.10)"
                : "#E1F5E8",
            width: "111px",
            height: "40px",
          }}
        >
          <Typography
            sx={{
              color: data?.status === "pending" ? "#EAB308" : "#29B95F",
              fontWeight: "500",
              fontSize: "18px",
              textTransform: "capitalize",
            }}
          >
            {data?.status === "completed"
              ? data?.isLastLocation
                ? "delivered"
                : "collected"
              : "pending"}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        justifyContent="space-between"
        sx={{
          border: "1px solid black",
          borderRadius: "6px",
          p: 2,
          backgroundColor: "#F8F8F8",
          height: "100%",
          minHeight: "140px",
        }}
      >
        {isFulfillment ? (
          <Stack
            direction="row"
            alignItems="center"
            gap={3}
            sx={{ height: "100%" }}
          >
            <Box component="img" src={icons.boxes} width="96px" />
            <Typography sx={{ fontSize: "28px", fontWeight: "400" }}>
              {data?.destination?.name} cadeau fulfilment center
            </Typography>
          </Stack>
        ) : (
          Object.keys(locationInfo).map((key, i) => (
            <Stack key={i} direction="row" justifyContent="space-between">
              <Typography
                sx={{ width: "20%", color: "#969696", fontWeight: "400" }}
              >
                {key}
              </Typography>
              <Stack
                direction="row"
                sx={{ width: "77%" }}
                gap="5px"
                alignItems={"center"}
              >
                <Typography sx={{ fontWeight: "400", fontSize: "12px" }}>
                  {key === "Address" ? (
                    <>
                      {data?.destination?.lat && data?.destination?.long && (
                        <GetDirection
                          address={locationInfo?.[key]}
                          lat={data?.destination?.lat}
                          long={data?.destination?.long}
                        />
                      )}
                    </>
                  ) : (
                    <Typography fontSize="15px">{locationInfo[key]}</Typography>
                  )}
                </Typography>
              </Stack>
            </Stack>
          ))
        )}
      </Stack>
    </Stack>
  );
}
