import { Stack, Typography, Box } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import dayjs from "dayjs";
import React from "react";

export default function CancelledStatus({createdAt}) {
  const { red } = useThemePalette();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mt={3}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Box component="img" src={icons.cancelled} width="40px" />
        <Typography fontWeight="700" fontSize="24px" sx={{ color: red }}>
          Canceled
        </Typography>
      </Stack>
      <Typography fontWeight="500" fontSize="14px">
        {dayjs(createdAt).format("DD-MM-YYYY, hh:mm a")}
      </Typography>
    </Stack>
  );
}
