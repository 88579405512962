import Types from "./Types";

export const CategoriesLoading = (payload) => ({
  type: Types.CATEGORIES_LOADING,
  payload,
});
export const GetCategoriesSuccess = (payload) => ({
  type: Types.GET_CATEGORIES_SUCCESS,
  payload,
});

export const AddCategoriesSuccess = (payload) => ({
  type: Types.ADD_CATEGORIES_SUCCESS,
  payload,
});

export const GetCategoriesDetailsSuccess = (payload) => ({
  type: Types.GET_CATEGORIES_DETAILS_SUCCESS,
  payload,
});

export const UpdateCategoriesSuccess = (payload) => ({
  type: Types.UPDATE_CATEGORIES_SUCCESS,
  payload,
});

export const DeleteCategoriesSuccess = (payload) => ({
  type: Types.DELETE_CATEGORIES_SUCCESS,
  payload,
});

export const GetScrollCategoriesTypesSuccess = (payload) => ({
  type: Types.GET_SCROLL_CATEGORIES_SUCCESS,
  payload,
});
