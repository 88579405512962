import { useFormik } from "formik";
import { structure, validationSchema } from "./structure";
import FormUse from "components/Atoms/SharedComponents/CRUD/form-use";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";

export default function ChangePasswordTemplate({
  breadcrumbs,
  handleSubmit,
  user,
  modalComponents
}) {
  const navigate = useNavigate();

  const form = {
    initialValues: { password: "", confirmPassword: "" },
    structure: () => structure(user?.name),
    validationSchema: validationSchema,
    title: "Change Password",
    submitTitle: "Change Password",
    onSubmit: handleSubmit,
    className: { form: "main-form", actionButton: "main-form-buttons" },
  };

  const formik = useFormik({
    initialValues: form?.initialValues,
    validationSchema: form?.validationSchema,
    onSubmit: async (values) => {
      form?.onSubmit(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleModelClose = () => {
    formik.resetForm();
    const currentURL = window.location.href;
    if(currentURL.includes('vendors')){
      navigate("/vendors")
    }
    else {
      navigate("/admin-users");
    }

    //updating the pagination page,
    //otherwise it will fetch data for page 1, when another page is selected at the footer
    // handlePageChange && handlePageChange(1);
  };

  return (
    <>
      <Breadcrumb data={breadcrumbs} />
      <FormUse
        handleLanguage={form?.handleLanguage}
        formStructure={form?.structure}
        createBtnFun={() => {
          formik?.resetForm();
        }}
        className={form?.className?.form}
        formHead={form?.formHead}
        formik={formik}
        form={form}
        handleModelClose={handleModelClose}
        breadcrumbs={form?.breadcrumbs}
      >
        {form?.formChildren}
      </FormUse>
      {modalComponents &&
        modalComponents?.map((modal) => {
          return <>{modal()}</>;
        })}
    </>
  );
}
