import { Typography, Stack } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isOpenDriverDetails,
  isOpenViewComplain,
} from "services/modules/modal/Actions";
import AvailableDriversCard from "./availableDriversCard";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import DriversDetails from "./Models/driversDetails";
import ViewComplainModal from "./Models/viewComplainModal";
import ComplainsUpdateModel from "components/Molecules/Orders/complains/complainsUpdateModel";
import AssignDriver from "components/Molecules/Orders/assignDriver";
import ViewComplain from "./ViewComplain";
import UnAssignDriver from "components/Molecules/Orders/unAssignDriver";
import Loading from "components/Atoms/SharedComponents/Loading/Loading";
import { getDriverComplainDetailsRequest } from "services/modules/orders";
import { StoreUser } from "services/modules/utils/constant";

export default function RoutesCard({
  handleUnAssignDriver,
  data,
  handleUpdateComplains,
  updateComplaintData,
  openResplveModal,
  setOpenResplveModal,
  setUpdateComplaintData,
  load,
  userType,
  displayFreeLancerBtn,
  routeTitle,
  routeNumber,
  routeType,
  assignedStatus = "assigned",
  driversData,
  setOpenUnAssignModal,
  openUnAssignModal,
}) {
  const dispatch = useDispatch();
  const { grayThree, redTwo, oliveGreen } = useThemePalette();
  const { routeComplain } = useSelector((state) => state?.orders);
  const { viewComplain } = useSelector((state) => state?.modal);

  return (
    <Stack width="50%" p="20px" gap="22px" bgcolor="#fff">
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Stack direction="row" gap="12px">
          <Stack bgcolor={grayThree} p="8px" borderRadius="6px">
            <img
              alt="driver icon"
              src={icons?.dirverIcon}
              width="24px"
              height="24px"
            />
          </Stack>
          <Typography fontWeight="500" fontSize={"24px"}>
            Driver info {userType != StoreUser && routeTitle}
          </Typography>
        </Stack>

        {userType != StoreUser && routeNumber != 1 && (
          <Stack
            direction="row"
            onClick={(_) => dispatch(isOpenDriverDetails())}
          >
            <Typography color={redTwo} sx={{ cursor: "pointer" }}>
              View {routeNumber == 3 ? "Drivers" : "Driver"} details of route{" "}
              {routeNumber == 3 ? "one and two" : routeNumber - 1}
            </Typography>
            <img alt="arrow" src={icons?.redRightArrow} />
          </Stack>
        )}
      </Stack>

      <Loading isLoading={load?.loading && load?.type == "details"}>
        <>
          {(data?.status == "generated" ||
            data?.status == "processing_drivers") && (
            <AssignDriver
              route={data}
              userType={userType}
              displayFreeLancerBtn={displayFreeLancerBtn}
            />
          )}
          {data?.driver && (
            <Stack>
              <AvailableDriversCard
                data={data?.driver}
                displayDistance={false}
              />
              <Stack
                mt="16px"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {data?.status == assignedStatus && userType != StoreUser && (
                  <ContainedButton
                    onClick={(_) => setOpenUnAssignModal(true)}
                    title="Unassign Driver"
                  />
                )}
                {data?.route_complaint && (
                  <ViewComplain click={() => dispatch(isOpenViewComplain())} />
                )}
              </Stack>
            </Stack>
          )}
          {userType != StoreUser && (
            <>
              <UnAssignDriver
                openUnAssignModal={openUnAssignModal}
                setOpenUnAssignModal={setOpenUnAssignModal}
                data={data}
                handleUnAssignDriver={handleUnAssignDriver}
                routeType={routeType}
                load={load}
              />
              <DriversDetails
                // driver={routeOne?.driver}
                title={`Driver details of ${routeTitle?.toLowerCase()}`}
                driversData={driversData}
              />
            </>
          )}
          {viewComplain && data?.route_complaint?.id && (
            <ViewComplainModal
              complaintId={data?.route_complaint?.id}
              setOpenResplveModal={setOpenResplveModal}
              openResplveModal={openResplveModal}
              handleUpdateComplains={handleUpdateComplains}
              setUpdateComplaintData={setUpdateComplaintData}
              complaint={routeComplain}
              getComplaintDetails={getDriverComplainDetailsRequest}
            />
          )}
          <ComplainsUpdateModel
            updateComplaintData={updateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
          />
        </>
      </Loading>
    </Stack>
  );
}
