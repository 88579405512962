import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import { Stack } from "@mui/material";
import DeleteModal from "components/Molecules/Models/DeleteModal";
import VariantForm from "components/Organisms/products/productVariants/variantForm";
import VariantDetails from "components/Organisms/products/productVariants/variantDetails";

export default function VendorProductsTemplate({
                                                   isOpenForm,
                                                   isOpenDetails,
                                                   setIsOpenDetails,
                                                   variantId,
                                                   fulfillmentCenters,
                                                   details,
                                                   setOpenDeleteModal,
                                                   openDeleteModal,
                                                   handleDelete,
                                                   handleSubmit,
                                                   locations,
                                                   setLocations,
                                                   locationError,
                                                   tableData,
                                                   list,
                                                   columns,
                                                   setTableData,
                                                   setFilterObj,
                                                   filterList,
                                                   headerChildren,
                                                   actionsList,
                                                   rowData,
                                                   handlePageChange,
                                                   page,
                                                   count,
                                                   optionTypesFilter,
                                                   handleVariantFormClose
                                               }) {
    return (
        <Stack sx={{ backgroundColor: "white" }}>
            <VariantForm
                open={isOpenForm}
                handleSubmit={handleSubmit}
                locations={locations}
                setLocations={setLocations}
                id={variantId && variantId}
                fulfillmentCenters={fulfillmentCenters}
                details={details}
                optionTypesFilter={optionTypesFilter}
                locationError={locationError}
                handleVariantFormClose={handleVariantFormClose}
            />
            <VariantDetails open={isOpenDetails} setOpen={setIsOpenDetails} details={details} />
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                handelDeleteRequest={() => {
                    handleDelete(tableData?.rowData?.id && tableData?.rowData?.id);
                }}
                handleCancelDelete={() => setOpenDeleteModal(false)}
                customClass="organizations-delete-modal"
                text={[`Delete variant`, `Are you sure that you want to delete this variant?`]}
            />
            <TableUse
                list={list}
                columnsData={columns}
                setTableData={setTableData}
                setFilterObj={setFilterObj}
                filterList={filterList}
                headerChildren={headerChildren}
                actionsList={actionsList}
                rowData={rowData}
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                handlePageChange={handlePageChange}
                paginationPage={page}
                total_count={count}
            />
        </Stack>
    );
}
