import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import {
  GetWrapExtrasRequest,
  addWrapExtraRequest,
  deleteWrapExtraRequest,
  getWrapExtraDetailsRequest,
  updateWrapExtraRequest,
} from "services/modules/Wrappers/wrapExtras";
import { toast } from "react-toastify";
import WrapsExtrasTemplate from "templates/wrappers/wrapsExtras";

function WrapsExtras() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.wrapsExtras.wrapExtras);
  const wrapsList = useSelector((state) => state.wrappers.wraps);
  const shapesList = useSelector((state) => state.shapes.shapes);
  const colorsList = useSelector((state) => state.colors.colors);
  const count = useSelector((state) => state.wrapsExtras.count);
  const details = useSelector((state) => state.wrapsExtras.wrapExtra);
  const load = useSelector((state) => state.wrapsExtras.load);

  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.wrap_extras
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getWrapExtraDetailsRequest({ wrap_extra_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetWrapExtrasRequest(requestData));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      image: <img src={data?.image} alt={data?.name} width="108px" height="108px" />,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/wraps-extras",
      title: "wrappers/Wrap extras",
    },
    type && {
      active: false,
      title: type === "details" ? "Wrap Extra details" : id ? "Edit Wrap Extra" : "New Wrap Extra",
    },
  ];

  const handelSubmit = (data, formik) => {
    console.log(formik?.values);
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/wraps-extras");
      !id && formik?.resetForm();
      toast.success(`Product has been ${id ? "updated" : "created"} successfully`);
    };
    console.log(formik?.values);
    const requestData = {
      body: { wrap_extra: { ...formik?.values } },
      action,
    };
    if (id) {
      dispatch(updateWrapExtraRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(addWrapExtraRequest(requestData));
    }
  };

  const filterList = [];
  // console.log(wrapsList?.map(wrap => { name: wrap?.name, id: wrap?.id }));

  const handleDelete = (id) => {
    // const id = selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
   if (type == "details") {
     navigate(-1);
   }
      toast.success("Wrap extra has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteWrapExtraRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <WrapsExtrasTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        wrapsOptionsListArray={wrapsList}
        colorsList={colorsList}
        shapesList={shapesList}
        selectedRow={selectedRow}
        can_create={isSuperAdmin || permissions?.can_create}
      />
    </>
  );
}

export default WrapsExtras;
