import { useThemePalette } from 'common/hooks/theme_palette';
import ConfirmationModal from 'components/Molecules/Models/ConfirmationModal'
import React from 'react'

export default function SuspendModel({ openModel, setOpenModel, handleUpdate, userType }) {
    const { red } = useThemePalette()

    return <>
        <ConfirmationModal
            open={openModel?.state}
            setOpen={setOpenModel}
            handleConfirmation={_ => {
                handleUpdate()
                setOpenModel({ state: false, customerId: false, action: false })
            }}
            text={[
                `Suspend ${userType} Account`,
                `Are you sure you want to suspend this ${userType} account?`
            ]}
            textStyle={{ color: red, fontSize: '32px', fontWeight: 500 }}
            confirmBtnTitle="Suspend"
            btnStyle={{
                backgroundColor: red,
                "&:hover": {
                    backgroundColor: red,
                },
                color: "white",
            }}
        />
    </>
}
