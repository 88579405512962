import { Box, Grid, TextField, Typography } from '@mui/material'
import "./DatePicker.css"
import dayjs from 'dayjs';
import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


export default function BasicDatePicker({
  inputFormat = "DD-MM-YYYY",
  formik,
  value, onChange, placeholder, dateMsgSelect, filter, className, name, id, redBorder, errorText, createLis, sx, label }) {
  const [cleared, setCleared] = React.useState(false);
  const errorsMsg = formik?.errors[name];

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => { };
  }, [cleared]);
  return (
    <Grid className={className}>
      {label && (
        <Typography as="span" sx={{ color: "#797979", fontSize: "18px", fontFamily: "Jost", mb: 3 }}>
          {label}
        </Typography>
      )}
      <Box classname='date-picker'
      >

        <DesktopDatePicker
          name={name}
          id={id}
          value={(typeof (formik?.values[name]) === 'undefined') ? null : (formik?.values[name])}
          // onChange={(newValue) => onChange(dayjs(new Date(newValue).toLocaleDateString()).format("DD-MM-YYYY"))}
          // onChange={(newValue) => onChange(dayjs(new Date(newValue).toLocaleDateString()) )}
          onChange={(newValue) => onChange(filter ? dayjs(new Date(newValue).toLocaleDateString()).format("DD-MM-YYYY") : dayjs(new Date(newValue).toLocaleDateString()))}
          renderInput={(params) => <TextField {...params} />}
          // label={(typeof (formik?.values[name] || value) === 'undefined' && createLis === true) ? dateMsgSelect : placeholder}
          label={(typeof (formik?.values[name] || value) === 'undefined' && createLis === true) ? dateMsgSelect : placeholder}
          format={inputFormat}
          className={`${(typeof value === 'undefined' && createLis === true) && redBorder}`}
          slotProps={{
            field: { clearable: true, onClear: () => setCleared(true) },
          }}
          InputProps={{
            disableUnderline: true
          }}
          sx={{ ...sx  }}

        />
      </Box>
      {errorsMsg && <Typography color="red" mt="8px" fontSize="14px">
        {errorsMsg}
      </Typography>}
      {/* {redBorder === 'red-border' && (
        <span
          style={{
            fontSize: "13px",
            color: "#d32f2f",
            textAlign: "left",
            width: "97%",
            margin: "auto"
          }}
        >
          {errorText}
        </span>
      )} */}
    </Grid>
  )
}