import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function Images({ data }) {
  const { textGray } = useThemePalette();
  return (
    <>
      <Box width="48%">
        <Typography color={textGray} mb="16px" fontSize="18px" textTransform="capitalize">
          {data?.title}
        </Typography>
        <Stack
          justifyContent="center"
          alignItems="center"
          height="218px"
          sx={{
            border: data?.type == "icon" && "1px dashed rgba(0, 0, 0, 0.25)",
            
          }}
        >
          <Box
            component="img"
            src={data?.img}
            width={data?.type == "icon" ? "95px" : "100%"}
            height={data?.type == "icon" ? "95px" : "100%"}
            borderRadius={data?.type != "icon" && "10px"}
            alt=""
          />
        </Stack>
      </Box>
    </>
  );
}
