import { Typography, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { icons } from 'assets/AssetHelper'
import { useThemePalette } from 'common/hooks/theme_palette'
import React from 'react'
import * as dayjs from "dayjs";

export default function ReturnDetailsHead({ orderPlaceIn, deliveryIn }) {
    const { grayTwo } = useThemePalette()
    return <>
        <Stack bgcolor={'#fff'} justifyContent='space-between' direction='row' p={'24px'} >
            <Stack width='50%' direction='row' gap='10px' >
                <Box>
                    <img
                        alt='date icon'
                        src={icons?.dateIcon}
                        width='100%'
                    />
                </Box>
                <Typography color={grayTwo}>
                    Return Request :
                </Typography>
                <Typography>
                    {/* {orderPlaceIn} */}
                    {dayjs(orderPlaceIn).format("DD-MM-YYYY, hh:mm a")}
                </Typography>
            </Stack>
            <Stack width='50%' direction='row' gap='10px' >
                <Box>
                    <img
                        alt='date icon'
                        src={icons?.timeIcon}
                        width='100%'
                    />
                </Box>
                <Typography color={grayTwo}>
                    Estimated Delivery in:
                </Typography>
                <Typography>
                    {/* {orderPlaceIn} */}
                    {dayjs(deliveryIn).format("DD-MM-YYYY, hh:mm a")}
                </Typography>
            </Stack>
        </Stack>
    </>
}
