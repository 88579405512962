import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { detailsData } from "./details";
import { useEffect } from "react";
import { GetWrapsRequest } from "services/modules/Wrappers/Wraps";
import { useDispatch, useSelector } from "react-redux";
import { GetShapeRequest } from "services/modules/Wrappers/shapes";

const WrapsExtrasTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  wrapsOptionsListArray,
  selectedRow,
  colorsList,
  can_create,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");
  const [wrapId, setWrapId] = useState("en");
  const dispatch = useDispatch();
  useEffect(() => {
    // const filteredData = wrapsOptionsListArray?.find((ele) => ele?.id == selectedRow?.formikData?.values?.wrap_id)
    setWrapId(selectedRow?.formikData?.values?.wrap_id);
  }, [selectedRow?.formikData?.values?.wrap_id]);

  useEffect(() => {
    const requestData = {
      params: {
        page_size: 20,
        page_number: 1,
        keyword: "",
        wrap_id: wrapId,
      },
    };
    dispatch(GetShapeRequest(requestData));
  }, [wrapId]);

  return (
    <>
      <Box>
        <Stack
          direction='row'
          alignSelf='start'
          justifyContent='start'
          gap={2}
          width='100%'
          mb={1}
        >
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Wrap Extra details",
            data: {
              // images: [{ id: 0, img: details?.image }],
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data, // data
            listTitle: "Wrap extras", //title
            createBtnTitle: can_create && "New Wrap Extra", // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "image",
                Header: "Image",
                sort: true,
              },
              {
                accessor: "localized_data.en.name",
                Header: "Wrap Extra Name(EN)",
                sort: true,
              },
              {
                accessor: "localized_data.ar.name",
                Header: "Wrap Extra Name(AR)",
                sort: true,
              },
              { accessor: "count_on_hand", Header: "Quantity", sort: true },
              { accessor: "price", Header: "Price", sort: true },
              {
                accessor: "price_after_discount",
                Header: "Price after discount",
                sort: true,
              },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search Wrap name",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(),
            validationSchema: validationSchema(wrapId),
            deleteFun: deleteFun,
            title: id ? "Edit Wrap Extra" : "New Wrap Extra",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default WrapsExtrasTemplate;
