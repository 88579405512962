import * as Yup from "yup";

export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
  }
};

export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `icon`,
        kind: "upload",
        type: 'icon',
        label: "Upload icon",
        text: 'JPG and PNG,',
        width: "47%",
        id: 0
      },
      {
        name: `banner`,
        kind: "upload",
        type: 'image',
        label: "Upload banner",
        text: 'JPG and PNG,',
        width: "47%",
        id: 1
      },
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Occasion Name(EN) ",
        width: "47%",
        placeholder: "Write Occassion name(EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Occasion Name(AR)",
        width: "47%",
        placeholder: "Write Occassion name(AR)"
      },
      {
        name: `en.description`,
        kind: "textArea",
        type: "text",
        label: "Occasion Description(EN)",
        width: "47%",
        placeholder: "Write Occasion description(EN)",
        colSpan: 6
      },
      {
        name: `ar.description`,
        kind: "textArea",
        type: "text",
        label: "Occasion Description(AR)",
        width: "47%",
        placeholder: "Write Occasion description(AR)",
        colSpan: 6
      },
    ],
  },
];


export const validationSchema = () =>
  Yup.object({
    icon: Yup.string().required('Required'),
    banner: Yup.string().required('Required'),
    en: Yup.object().shape({
      name: Yup.string().required('Required'),
      description: Yup.string(),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required('Required'),
      description: Yup.string(),
    }),
  },
  );