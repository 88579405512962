import * as Yup from "yup";
export const initialValues = (data) => {
    console.log(data)
    return {
        ...data,
        ...data?.localized_data,
        image:data?.avatar
    }
};
export const structure = () => [
    {
        head: "",
        width: "100%",
        list: [
            {
                name: `image`,
                kind: "upload",
                type: 'image',
                label: "Profile image",
                placeholder: 'test',
                text: 'JPG and PNG  file size no more than',
                width: "47%",
            },
            {
                width: "47%",
            },

        ],

    },
    {
        head:"Personal details",
        width:'100%',
        list:[{
            name: `name`,
            kind: "input",
            type: "text",
            label: "Name",
            width: "47%",
            placeholder: "Write name"
        },
            {
                name: `phone_number`,
                kind: "input",
                type: "text",
                label: "Phone Number",
                width: "47%",
                placeholder: "write phone number"
            },
            {
                name: `email`,
                kind: "input",
                type: "text",
                label: "Email",
                width: "47%",
                placeholder: "write Email"
            },]
    }
];


export const validationSchema = () =>

    Yup.object({
        name:Yup.string().required('Required'),
        email:Yup.string().required('Required'),
        phone_number:Yup.string().required('Required'),
    });