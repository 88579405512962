import { Typography } from '@mui/material';
import { icons } from 'assets/AssetHelper'
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Wrap Variant Name(EN)',
                name: data?.localized_data?.en?.name,
            },
            {
                label: 'Wrap Variant Name(AR)',
                name: data?.localized_data?.ar?.name,
            },
            {
                label: 'Shape',
                name: (
                    <div style={{ borderRadius: '4px', backgroundColor: '#F5F5F5', textAlign: 'center', padding: '3px 15px' }}>
                        <Typography color='#6A6A6A' fontSize='18px' fontFamily='Jost' >{data?.shape?.name}</Typography>
                    </div>
                ),
                width: '15%'
            },
            {
                label: 'Color',
                name: (
                    <div style={{ borderRadius: '4px', backgroundColor: '#F5F5F5', textAlign: 'center', padding: '3px 15px' }}>
                        <Typography color='#6A6A6A' fontSize='18px' fontFamily='Jost' >{data?.color?.name}</Typography>
                    </div>
                ),
                width: '15%'
            },
            {
                label: 'Wrap',
                name: (
                    <div style={{ borderRadius: '4px', backgroundColor: '#F5F5F5', textAlign: 'center', padding: '3px 15px' }}>
                        <Typography color='#6A6A6A' fontSize='18px' fontFamily='Jost' >{data?.wrap?.name}</Typography>
                    </div>
                ),
                width: '15%'
            },
        ],
    },
    {
        head: 'Prices',
        icon: icons?.priceIcon,
        details: [
            {
                label: 'Price',
                name: `${data?.price} ${data?.currency?.name}`,
            },
            {
                label: 'Price after discount',
                name: `${data?.price_after_discount} ${data?.currency?.name}`,
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.datesIcons,
        details: [
            {
                label: 'Created AT',
                name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            },
            {
                label: 'Updated At',
                name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a")
            },
        ]
    },
];