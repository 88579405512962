const VendorsTypes = {
    // Vendors
    // list
    GET_VENDORS_SUCCESS: "GET_VENDORS_SUCCESS",
    VENDORS_LOADING: "VENDORS_LOADING",
    //details
    GET_VENDORS_DETAILS_SUCCESS: "GET_VENDORS_DETAILS_SUCCESS",
    // create
    ADD_VENDORS_SUCCESS: "ADD_VENDORS_SUCCESS",
    // Delete
    DELETE_VENDORS_SUCCESS: "DELETE_VENDORS_SUCCESS",
    // update
    UPDATE_VENDORS_SUCCESS: "UPDATE_VENDORS_SUCCESS",
    GET_SCROLL_VENDORS_SUCCESS: "GET_SCROLL_VENDORS_SUCCESS",

};

export default VendorsTypes;
