import { Stack, Typography } from "@mui/material";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import React from "react";
import { useDispatch } from "react-redux";
import {
  isOpenAssignToEmployee,
  isOpenAssignToFreelancer,
} from "services/modules/modal/Actions";
import { StoreUser } from "services/modules/utils/constant";

export default function AssignDriver({
  route,
  userType,
  displayFreeLancerBtn = true,
}) {
  const dispatch = useDispatch();

  return (
    <>
      <Stack>
        <Stack alignItems="center" mb="18px">
          <Typography fontSize="20px">No driver assign yet</Typography>
        </Stack>
        {userType !== StoreUser && (
          <Stack direction="row" gap="24px">
            <ContainedButton
              title="Assign Employee"
              onClick={(_) => dispatch(isOpenAssignToEmployee(route))}
            />
            {displayFreeLancerBtn && (
              <ContainedButton
                title="Assign freelancer"
                onClick={(_) => dispatch(isOpenAssignToFreelancer(route))}
              />
            )}{" "}
          </Stack>
        )}
      </Stack>
    </>
  );
}
