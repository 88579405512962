import Types from "./Types";

const INIT_STATE = {
  openAddAddress: false,
  assignToEmployee: false,
  assignToFreelancer: false,
  driverDetails: false,
  viewComplain: false,
  followesModel: false,
  followingModel: false,
  previewChatImg: false,
  openApproveItemModel: false,
  openRejectItemModel: false,
  openRejectionDetailsModel: false,
  openViewDestinationModel: false,
  routeIndex: null,
  itemDetails: {},
  chatImgUrl: '',
  route: {},
  isOpen: false,
  message: null,
};

export default function modalReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.IS_OPEN_ADD_ADDRESS: {
      return {
        ...state,
        openAddAddress: !state.openAddAddress,
      };
    }
    case Types.IS_OPEN_ASSIGN_TO_EMPLOYEE: {
      return {
        ...state,
        assignToEmployee: !state.assignToEmployee,
        route: payload,
      };
    }
    case Types.IS_OPEN_ASSIGN_TO_FREELANCER: {
      return {
        ...state,
        assignToFreelancer: !state.assignToFreelancer,
        route: payload,
      };
    }
    case Types.IS_OPEN_DRIVER_DETAILS: {
      return {
        ...state,
        driverDetails: !state.driverDetails,
      };
    }
    case Types.IS_OPEN_VIEW_COMPLAIN: {
      return {
        ...state,
        viewComplain: !state.viewComplain,
      };
    }
    case Types.IS_OPEN_FOLLOWES_MODEL: {
      return {
        ...state,
        followesModel: !state.followesModel,
      };
    }
    case Types.IS_OPEN_FOLLOWING_MODEL: {
      return {
        ...state,
        followingModel: !state.followingModel,
      }
    }
    case Types.IS_OPEN_CHAT_PREVIEW_IMG: {
      return {
        ...state,
        previewChatImg: !state.previewChatImg,
        chatImgUrl: payload,
      }
    }
    case Types.IS_OPEN_APPROVE_ITEM_MODEL: {
      return {
        ...state,
        openApproveItemModel: !state.openApproveItemModel,
        itemDetails: payload,
      }
    }
    case Types.IS_OPEN_REJECT_ITEM_MODEL: {
      return {
        ...state,
        openRejectItemModel: !state.openRejectItemModel,
        itemDetails: payload,
      }
    }
    case Types.IS_OPEN_REJECTION_DETAILS_MODEL: {
      return {
        ...state,
        openRejectionDetailsModel: !state.openRejectionDetailsModel,
        itemDetails: payload,
      }
    }
    case Types.IS_OPEN_VIEW_DESTINATION_MODEL: {
      return {
        ...state,
        openViewDestinationModel: !state.openViewDestinationModel,
        routeIndex: payload
      }
    }
    case Types.CLOSE_SUCCESS_MODAL: {
      return {
        ...state,
        modal: {
          isOpen: false,
          message: null,
        },
      };
    }
    default: {
      return state;
    }
  }
}
