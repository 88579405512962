import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Stack, Typography, MenuItem, Box } from "@mui/material";
import { useNavigate } from "react-router";
import dayjs from "dayjs";

import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

export default function Notification({
  notification,
  notificationTypes,
  handleClose,
}) {
  const navigate = useNavigate();
  const { orange, gray17 } = useThemePalette();
  const type = notificationTypes({ id: notification?.data_id, type: notification?.notification_type })

  const handleClick = () => {
    type && navigate(type.path);
    handleClose();
  };

  return (
    <MenuItem
      sx={{
        whiteSpace: "unset",
        wordBreak: "break-word",
        width: "450px",
        p: 2,
        '&:hover': {
          cursor: type?.path ? 'pointer ' : 'default'
        },
      }}
      onClick={handleClick}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Stack direction="row" gap={2} alignItems="start">
          <Stack
            width='48px'
            height='48px'
            borderRadius='50%'
            bgcolor={gray17}
            justifyContent='center'
            alignItems='center'
          >
            <Box component="img" src={notification?.avatar || type?.icon} width="24px" height='24px' />

          </Stack>
          <Stack>
            <Typography>{notification?.localized_data?.en?.message}</Typography>
            <Typography sx={{ color: "#B4B4B4" }}>
              {dayjs(notification?.sent_at).format("DD-MM-YYYY, HH:MM a")}
            </Typography>
          </Stack>
        </Stack>
        {!notification?.is_seen && (
          <Stack>
            <FiberManualRecordIcon sx={{ color: orange }} fontSize="15px" />
          </Stack>
        )}
      </Stack>
    </MenuItem>
  );
}
