const ProductsOptionTypes= {
    //LOADING
    DRIVER_COMPLAINTS_LOADING:"DRIVER_COMPLAINTS_LOADING",
    //LISTING
    GET_DRIVER_COMPLAINTS_SUCCESS: "GET_DRIVER_COMPLAINTS_SUCCESS",
    //CREATION
    SET_DRIVER_COMPLAINTS_SUCCESS: "SET_DRIVER_COMPLAINTS_SUCCESS",
    //DETAILS
    GET_DRIVER_COMPLAINT_DETAILS_SUCCESS: "GET_DRIVER_COMPLAINT_DETAILS_SUCCESS",
    //UPDATE
    UPDATE_DRIVER_COMPLAINT_SUCCESS: "UPDATE_DRIVER_COMPLAINT_SUCCESS",
    //DELETE
    DELETE_DRIVER_COMPLAINT_SUCCESS: "DELETE_DRIVER_COMPLAINT_SUCCESS",
}
export default ProductsOptionTypes