import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./common/theme";
import { store } from "./services/store";
import { Provider as ReduxProvider} from "react-redux";
import AppRoute from "./common/Route";
import setAuthToken from "services/modules/utils/handel_api";
import AlertProvider from "./components/Atoms/Alerts/ToastifyAlert";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


function App() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  // onValue(ref(db, '/order_requests/'), (snapshot) => {
  //   // const username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
  //   console.log(snapshot.val());
  // });

  // to get phone number i onsubmit without country code
  // const countryCode = values?.country_code?.length;
  // const phone_number = values?.phone_number?.slice(countryCode - 1);

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppRoute />
          </LocalizationProvider>
        </AlertProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
