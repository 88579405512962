import {
  Stack,
  Typography,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { getResourcesDataRequest } from "services/modules/users/index";
import AppSwitch from "components/Atoms/Input/Switch/Switch";

export default function RolePermissionsForm(props) {
  const { id, permissionsAttributes, setPermissionsAttributes } = props;
  const tableHeader = ["#", "role", "view", "add", "edit", "delete"];
  const permissionsNames = [
    "can_read",
    "can_create",
    "can_update",
    "can_delete",
  ];
  const columnStyle = {
    color: "#464646",
    fontSize: "18px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontFamily: "Jost",
  };
  const dispatch = useDispatch();
  const details = useSelector((state) => state.users.role);
  const list = useSelector((state) => state?.users?.resources);

  const prepareRows = () => {
    let rows = [];
    //add role
    if (!id) {
      //manually add default permissions as the list includes none
      const permissions = {
        can_read: false,
        can_create: false,
        can_update: false,
        can_delete: false,
      };
      list?.forEach((item) => rows.push({ ...item, ...permissions }));
    } else {
      //update role
      let { permissions } = details;
      permissions?.forEach((permission) =>
        rows.push({
          permissionId: permission?.id,
          id: permission?.resource?.id,
          name: permission?.resource?.name,
          can_create: permission?.can_create,
          can_read: permission?.can_read,
          can_update: permission?.can_update,
          can_delete: permission?.can_delete,
        })
      );
    }
    return rows;
  };

  //in case of update, get the existing permissions
  const getExisitingPermissions = (details) => {
    let perExistingPermissions = [];
    let { permissions } = details;
    permissions?.forEach((perm) => {
      let { can_create, can_read, can_update, can_delete } = perm;
      let isTrue = can_create || can_read || can_update || can_delete;
      if (isTrue) {
        perExistingPermissions.push({
          id: perm.id,
          resource_id: perm.resource.id,
          can_create,
          can_read,
          can_update,
          can_delete,
        });
      }
    });
    return perExistingPermissions;
  };

  const handleSwitchChange = (row, permissionName, isAllowed) => {
    //check if the permission already exists
    const isExist =
      permissionsAttributes.filter((perm) => perm?.resource_id === row?.id)
        .length !== 0;
    if (!isExist) {
      let permission = {
        id: row?.permissionId, // in case of update this id must be sent
        resource_id: row?.id,
        can_read: false,
        can_create: false,
        can_update: false,
        can_delete: false,
      };
      permission[permissionName] = isAllowed;
      setPermissionsAttributes([...permissionsAttributes, permission]);
    } else {
      setPermissionsAttributes(
        permissionsAttributes.map((perm) =>
          perm?.resource_id !== row?.id
            ? perm
            : { ...perm, [permissionName]: isAllowed }
        )
      );
    }
  };

  useEffect(() => {
    const filterObj = {
      page_size: 50, //to fetch all the resources at once
      page_number: 1,
      keyword: "",
      sort_by: "id",
    };
    const requestData = {
      params: filterObj,
    };
    !id && dispatch(getResourcesDataRequest(requestData));
  }, []);

  useEffect(() => {
    const preExistingPermissions = getExisitingPermissions(details);
    if (preExistingPermissions.length !== 0 && id) {
      setPermissionsAttributes((p) => preExistingPermissions);
    } else {
      setPermissionsAttributes([]);
    }
  }, [details, id]);

  return (
    <Stack>
      <Typography
        sx={{
          color: "#565656",
          fontSize: "28px",
          fontFamily: "Jost",
          fontWeight: "500",
          backgroundColor: "white",
          p: 2,
          mt: 2,
        }}
      >
        Role Permission
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F8F8F8" }}>
              {tableHeader.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    textTransform: "capitalize",
                    color: index === 0 ? "#1A1A1A" : "#858585",
                    fontSize: index === 0 ? "16px" : "18px",
                    width: index === 1 ? "50%" : "10%",
                    fontFamily: "Jost",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {prepareRows()?.map((row, rowIndex) => (
              <TableRow key={row.id} sx={{ border: "1px solid #F8F8F8" }}>
                <TableCell component="th" scope="row" sx={columnStyle}>
                  {rowIndex + 1}
                </TableCell>
                <TableCell sx={columnStyle}>{row.name}</TableCell>
                {permissionsNames.map((perm, i) => (
                  <TableCell key={i}>
                    <AppSwitch
                      checked={row[perm]}
                      onChange={handleSwitchChange}
                      permissionName={perm}
                      row={row}
                      index={rowIndex}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

// const [tableData, setTableData] = useState({});
// const handleSwitchChange = (index, data, attr) => {
//   console.log(index, data.id, data.name, attr);
// };

// const permissions = (data, index) => {
//   const attr = {
//     view: "can_read",
//     add: "can_create",
//     edit: "can_update",
//     delete: "can_delete",
//   };
//   const perm = {};
//   for (const key in attr) {
//     perm[key] = (
//       <AppSwitch
//         checked={id ? data.permission?.index?.attr[key] : !index}
//         // onChange={() =>
//         //   handleSwitchChange(
//         //     index,
//         //     data,
//         //     data.permission?.index?.attr[key] || attr[key]
//         //   )
//         // }
//       />
//     );
//   }
//   return perm;
// };

// const mappedData = compiedList()?.map((data, index) => {
//   return {
//     ...data,
//     num: index + 1,
//     ...permissions(data, index),
//   };
// });
