import Types from "./Types";

export const GetBannersLoading = (payload) => ({
  type: Types.GET_BANNERS_LOADING,
  payload,
});

export const GetBannersSuccess = (payload) => ({
  type: Types.GET_BANNERS_SUCCESS,
  payload,
});

export const GetBannerDetailsSuccess = (payload) => ({
  type: Types.GET_BANNER_DETAILS_SUCCESS,
  payload,
});
