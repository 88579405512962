const Types = {
  GET_DRIVERS_SUCCESS: "GET_DRIVERS_SUCCESS",
  ADD_DRIVER_SUCCESS:'ADD_DRIVER_SUCCESS',
  GET_DRIVER_DETAILS_SUCCESS: "GET_DRIVER_DETAILS_SUCCESS",
  UPDATE_DRIVER_SUCCESS: 'UPDATE_DRIVER_SUCCESS',
  DRIVERS_LOADING: "DRIVERS_LOADING",
  APPROVE_DRIVER_SUCCESS: "APPROVE_DRIVER_SUCCESS",
  REJECT_DRIVER_SUCCESS: "REJECT_DRIVER_SUCCESS",
  DELETE_DRIVER_SUCCESS: "DELETE_DRIVER_SUCCESS",
};

export default Types;
