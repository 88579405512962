import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmployeeDetailsTemplate from "templates/users/drivers/employee/employeeDetails";
import DriverComplainHistory from "components/Organisms/users/drivers/employee/driverComplainHistory";
import DriverDetails from "components/Organisms/users/drivers/details";
import DriverOrderHistory from "components/Organisms/users/drivers/driverOrderHistory";
import { handleTabChange } from "services/modules/utils/Helper";

export default function EmployeeDetailsPage({ details, actionsList }) {
  const tabs = [
    {
      title: "Details",
      children: <DriverDetails details={details} />,
    },
    { title: "Orders History", children: <DriverOrderHistory /> },
    { title: "Complaints History", children: <DriverComplainHistory /> },
  ];

  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));

  const tabId = searchParams.get("tabId");
  const [value, setValue] = useState(+tabId || 0);


  return (
    <EmployeeDetailsTemplate
      details={details}
      selectedTab={value}
      tabs={tabs}
      handleTabChange={(event, newValue) => handleTabChange({ event, newValue, setValue, searchParams, setSearchParams })}
      actionsList={actionsList}
    />
  );
}
