import { reduxRequest } from "common/utils/reduxRequest";
import {
  CountriesLoading,
  GetCountriesSuccess,
  GetScrollCountriesSuccess
} from "./Action";
import { makeFilterString } from "services/modules/utils/Helper";




export const getCountiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CountriesLoading,
    loadingType: "list countries",
    successFunction: GetCountriesSuccess,
    url: `/lookups/countries${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollCountiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CountriesLoading,
    loadingType: "list-filter-countries",
    successFunction: GetScrollCountriesSuccess,
    url: `/lookups/countries${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
