import { makeFilterString } from "../../utils/Helper";
import {
  AddWrapFailed,
  GetShapesSuccess,
  GetShapeLoading,
  DeleteShapeSuccess,
  AddShapeSuccess,
  GetShapeDetailsSuccess,
  UpdateShapesSuccess,
  GetScrollShapesSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetShapeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetShapeLoading,
    loadingType: "list",
    successFunction: GetShapesSuccess,
    url: `/admin/wrappers/shapes${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollShapesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetShapeLoading,
    loadingType: "shapes-scroll-list",
    successFunction: GetScrollShapesSuccess,
    url: `/admin/wrappers/shapes${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const deleteShapeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetShapeLoading,
    successFunction: DeleteShapeSuccess,
    loadingType: "delete wrap",
    action: variables?.action,
    url: `/admin/wrappers/shapes/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const addShapeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetShapeLoading,
    loadingType: "add",
    successFunction: AddShapeSuccess,
    failFunction: AddWrapFailed,
    action: variables?.action,
    url: `/admin/wrappers/shapes`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateShapeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetShapeLoading,
    loadingType: "update shape",
    successFunction: UpdateShapesSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/wrappers/shapes/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getShapeDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetShapeLoading,
    loadingType: "details",
    successFunction: GetShapeDetailsSuccess,
    url: `/admin/wrappers/shapes/${variables?.shape_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




