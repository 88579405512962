import Types from "./Types";
const INIT_STATE = {
  load: null,
  currentUserProfile: {},
  userType: null,
};
export default function authReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.AUTH_SUCCESS: {
      return {
        ...state,
        load: null,
        userType: payload?.data?.user_type,
      };
    }
    case Types.AUTH_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_USER_PROFILE_SUCCESS: {
      let permissions = payload?.data?.user?.role?.permissions;
      let newPermissions = {};
      permissions?.forEach((element) => {
        newPermissions[element.resource_name] = element;
      });
      return {
        currentUserProfile: {
          ...payload?.data?.user,
          permissions: newPermissions,
        },
        load: null,
      };
    }
    case Types.UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        currentUserProfile: payload,
      };
    }
    default: {
      return state;
    }
  }
}
