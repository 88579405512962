import { Box, Stack } from '@mui/material'
import React from 'react'

export default function DriverDetailsImages({src}) {
    return <>
        <Stack
            maxWidth={'542px'}
            height='252px'
            border='1px dashed rgba(0, 0, 0, 0.25)'
            borderRadius='10px'
            justifyContent='center'
            alignItems='center'
            // p='20px'
        >
            <Box
                component="img"
                src={src}
                width="70%"
                height='70%'
                borderRadius='6px'
            />
        </Stack>
    </>
}
