const orderDeclinationTypes = {
  GET_ORDER_DECLINATION_REASONS_SUCCESS:
    "GET_ORDER_DECLINATION_REASONS_SUCCESS",
  GET_ORDER_DECLINATION_REASONS_DETAILS_SUCCESS:
    "GET_ORDER_DECLINATION_REASONS_DETAILS_SUCCESS",
  GET_ORDER_DECLINATION_LOADING: "GET_ORDER_DECLINATION_LOADING",
  ADD_ORDER_DECLINATION_REASON_SUCCESS: "ADD_ORDER_DECLINATION_REASON_SUCCESS",
};

export default orderDeclinationTypes;
