import { makeFilterString } from "../../utils/Helper";
import { ZonesLoading, GetZoneDetailsSuccess, GetZonesSuccess, GetScrollZonesSuccess } from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

//! ZONES
export const getZonesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ZonesLoading,
    loadingType: "list",
    successFunction: GetZonesSuccess,
    url: `/admin/lookups/zones${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getScrollZonesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ZonesLoading,
    loadingType: "filter-zones-list",
    successFunction: GetScrollZonesSuccess,
    url: `/admin/lookups/zones${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createZoneRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ZonesLoading,
    loadingType: "create",
    action: variables?.action,
    url: `/admin/lookups/zones`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getZoneDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ZonesLoading,
    loadingType: "get",
    successFunction: GetZoneDetailsSuccess,
    action: variables?.action,
    url: `/admin/lookups/zones/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateZoneRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ZonesLoading,
    loadingType: "update",
    action: variables?.action,
    url: `/admin/lookups/zones/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteZoneRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ZonesLoading,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/lookups/zones/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
