import Types from "./Types";

const INIT_STATE = {
  driverComplaintReasons:[],
    driverComplaintReason:{},
    count: 0,
    load: null,
    error: null,
};

export function driverComplaintsReasonsReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.DRIVER_COMPLAINT_REASONS_LOADING:{
            return {
                ...state,
                load: payload
            }
        }
        case Types.GET_DRIVER_COMPLAINT_REASONS_SUCCESS: {
            return {
                ...state,
                driverComplaintReasons: payload?.data?.route_complaint_reasons,
                count: payload?.extra?.total_count,
            };
        }

        case Types.GET_DRIVER_COMPLAINT_REASON_DETAILS_SUCCESS: {
            return {
                ...state,
                driverComplaintReason: payload?.data?.route_complaint_reason,
            };
        }
        case Types.ADD_DRIVER_COMPLAINT_REASON_SUCCESS: {
            console.log(payload);
            return {
                ...state,
            };
        }
        case Types.UPDATE_DRIVER_COMPLAINT_REASON_SUCCESS: {
            return {
                ...state,
            };
        }
        case Types.DELETE_DRIVER_COMPLAINT_REASON_SUCCESS: {
            return {
                ...state,
            };
        }
        default:{
            return state
        }
    }
    }