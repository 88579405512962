import { icons } from "assets/AssetHelper";
import CustomerAddresses from "components/Molecules/users/customers/customerAddresses";
import CustomerProfileInfo from "components/Molecules/users/customers/customerProfileInfo";
import dayjs from "dayjs";
import ViewAllBtn from "components/Molecules/users/customers/viewAllBtn";

export const detailsData = ({
  data,
  followers,
  followings,
  setAddressesToShow,
  addressesToShow,
  getFollowingList,
  getFollowersList,
  followingslist,
  followersList,
}) => [
  {
    head: "Customer Image",
    icon: icons?.media,
    details: [
      {
        name: (
          <CustomerProfileInfo
            data={data}
            followers={followers}
            followings={followings}
            getFollowingList={getFollowingList}
            getFollowersList={getFollowersList}
            followingslist={followingslist}
            followersList={followersList}
          />
        ),
        width: "100%",
      },
    ],
  },
  {
    head: "Personal Information",
    icon: icons?.details,
    details: [
      {
        label: "Phone",
        name: data?.phone_number,
      },
      {
        label: "Birthdate",
        name: dayjs(data?.birthdate).format("DD / MM / YYYY "),
      },
    ],
  },
  data?.addresses?.length > 0 && {
    head: (
      <ViewAllBtn
        setAddressesToShow={setAddressesToShow}
        addressesToShow={addressesToShow}
        data={data?.addresses}
      />
    ),
    icon: icons?.locationOutlined,
    details: [
      {
        name: (
          <CustomerAddresses
            data={data?.addresses}
            addressesToShow={addressesToShow}
          />
        ),
        width: "100%",
      },
    ],
  },
  {
    head: "Dates",
    icon: icons?.dates,
    details: [
      {
        label: "Created At",
        name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Updated At",
        name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Last Sign In At",
        name: dayjs(data?.last_sign_in_at).format("DD-MM-YYYY HH:MM a"),
      },
    ],
  },
];
