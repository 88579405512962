import { Typography } from '@mui/material';
import { icons } from 'assets/AssetHelper'
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Wrap Extra Name(EN)',
                name: data?.localized_data?.en?.name,
            },
            {
                label: 'Wrap Extra Name(AR)',
                name: data?.localized_data?.ar?.name,
            },
            {
                label: 'Width',
                name: data?.dimensions?.width || 0,
            },
            {
                label: 'Length',
                name: data?.dimensions?.length || 0,
            },
            {
                label: 'Height',
                name: data?.dimensions?.height || 0,
            },
            {
                label: 'Weight',
                name: data?.weight || 0,
            },
        ]
    },
    {
        head: 'Prices',
        icon: icons?.priceIcon,
        details: [
            {
                label: 'Price',
                name: `${data?.price} ${data?.currency?.name}`,
                width: '30%',
            },
            {
                label: 'Price after discount',
                name: `${data?.price_after_discount} ${data?.currency?.name}`,
                width: '30%',
            },
            {
                label: 'Quantity',
                name: data?.count_on_hand,
                width: '30%',
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.datesIcons,
        details: [
            {
                label: 'Created AT',
                name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            },
            {
                label: 'Updated At',
                name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a")
            },
        ]
    },
];