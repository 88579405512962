import { Divider, FormControlLabel, Stack, Typography, Checkbox } from "@mui/material";
import { loginSchema } from "common/utils/schemas";
import { AppForm, AppFormField, SubmitButton } from "components/Molecules/Form";
import { useThemePalette } from "common/hooks/theme_palette";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "services/modules/authenticate";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";

export default function LoginForm() {
  const FCMToken = useSelector((state) => state.notifications.FCMToken);

  const { load } = useSelector((state) => state?.authenticate);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orange } = useThemePalette();

  const handleFormSubmit = (values, { setSubmitting }) => {
    const action = (data) => {
      localStorage.setItem("token", data?.extra?.access_token);
      localStorage.setItem("user_type", data?.data?.user_type);
      localStorage.setItem("firebase_uid", data?.data?.user?.firebase_uid);
      localStorage.setItem('userInfo',JSON.stringify(data?.data?.user));
      window.dispatchEvent(new Event("storage"));
      //window.dispatchEvent(new Event("userInfo"));

      // window.location.reload();
    };
    const requestData = {
      body: {
        user: values,
        device: {
          fcm_token: FCMToken || "fcm_token",
          device_type: 1,
        },
      },
      action,
    };
    dispatch(loginRequest(requestData));
    setSubmitting(false);
  };

  return (
    <>
      <Loader open={load?.loading} />
      <Stack sx={{ gap: 4 }}>
        <Typography fontFamily="jost" fontSize="38px" fontWeight="500">
          Welcome Back
        </Typography>
        <Typography fontSize="30px" fontWeight="400">
          Login
        </Typography>
        <Divider sx={{ border: "1px solid #F5F5F5" }} />

        <AppForm
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values, { setSubmitting });
          }}
        >
          <Stack sx={{ gap: 5 }}>
            <AppFormField
              label="Email"
              name="email"
              type="text"
              labelStyle={{ fontSize: "18px" }}
              placeholder="Enter your email"
            />
            <AppFormField
              label="Password"
              name="password"
              labelStyle={{ fontSize: "18px" }}
              type="password"
              placeholder="***********"
              sx={{ backgroundColor: "#FBFBFB" }}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography
                sx={{
                  color: orange,
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
                onClick={(_) => navigate("forget-password")}
              >
                Forget Password ?
              </Typography>
            </Stack>
            <Stack>
              <SubmitButton>Login</SubmitButton>
            </Stack>
          </Stack>
        </AppForm>
      </Stack>
    </>
  );
}
