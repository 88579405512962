import { useState } from "react";
import ChatItem from "./chatItem";

const ChatItemList = ({ chatRooms, visitChatRoom, setFirstMessageCount,setMessagesList }) => {
    const [data, setData] = useState('');
    return (
        <>
            {chatRooms?.map((item) => (
                <ChatItem
                    item={item}
                    key={item?.id}
                    visitChatRoom={visitChatRoom}
                    data={data}
                    setData={setData}
                    setFirstMessageCount={setFirstMessageCount}
                    setMessagesList={setMessagesList}
                />
            ))}
        </>
    )
};

export default ChatItemList;