import { Box, Button, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import ModelBox from "components/Atoms/Model/Model";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvailableDriversCard from "../availableDriversCard";
// import { getEmployeesRequest } from "services/modules/orders";
import { useParams } from "react-router";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import { useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "components/Atoms/SharedComponents/Loading/Loading";

const AssignToEmpolyee = ({
  employees,
  setFilterObj,
  routeType,
  filterObj,
  selectedEmployee,
  setSelectedEmployee,
  handleAssignEmployee,
  handleCloseModel,
  isRouteOne,
  orderStatus,
  load,
  getEmployeesRequest,
  id,
}) => {
  const { assignToEmployee } = useSelector((state) => state.modal);
  const { grayfour, grayFive } = useThemePalette();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null);
  useEffect(() => {
    assignToEmployee &&
      dispatch(
        getEmployeesRequest({
          params: {
            // page_size: 10,
            // page_number: 1,
            keyword: "",
            // sort_by: "id",
            route_type:
              isRouteOne && isRouteOne(orderStatus)
                ? routeType || "start_route"
                : routeType || "finish_route",
            ...filterObj,
          },
          id: id,
          route_type:
            isRouteOne && isRouteOne(orderStatus)
              ? routeType || "start_route"
              : routeType || "finish_route",
        })
      );
  }, [assignToEmployee, filterObj]);

  const driverEmplyee = employees?.filter(
    (data) => data?.driver_type == "employee"
  );
  return (
    <>
      <ModelBox
        openVar={assignToEmployee}
        closeFunc={() => {
          handleCloseModel();
          setFilterObj((s) => {
            return {};
          });
        }}
        title={"Assign Employee Driver"}
        titleStyle={{
          fontSize: { lg: "32px", xs: "18px" },
          fontWeight: "500",
          px: { lg: "36px", xs: "15px" },
        }}
        padding={false}
      >
        <Stack p="36px">
          <Stack mb="45px" direction="row" p="0">
            <TextFieldInput
              placeholder={"Search by driver name"}
              StartAdornment={
                <img src={icons.blackSearchIcon} alt="search icon" />
              }
              border={`1px solid ${grayFive}`}
              sx={{ border: `1px solid ${grayFive}` }}
              onChange={(e) => {
                if (searchFilterTimeout) {
                  clearTimeout(searchFilterTimeout);
                }
                const timeout = setTimeout(() => {
                  setFilterObj((s) => {
                    return { ...s, keyword: e?.target?.value };
                  });
                }, 500);
                setSearchFilterTimeout(timeout);
              }}
            />
          </Stack>
          <Typography fontSize="18px" fontWeight="500" mb="19px">
            Available employees
          </Typography>
          <Stack direction="row" alignItems={"center"} gap="6px" mb="36px">
            <Box component="img" src={icons?.info} />
            <Typography fontSize="16px" fontWeight="400" color={grayfour}>
              this distance is between driver and the furthest vendor 's
              location
            </Typography>
          </Stack>
          <Stack gap="16px" mb="24px">
            <Loading isLoading={load?.loading && load?.type == "listEmployees"}>
              {driverEmplyee?.length > 0 ? (
                driverEmplyee?.map((data) => (
                  <AvailableDriversCard
                    data={data}
                    setSelectedEmployee={setSelectedEmployee}
                    selectedEmployee={selectedEmployee}
                  />
                ))
              ) : (
                <Typography color="red">There is no drivers now</Typography>
              )}
            </Loading>
          </Stack>
        </Stack>
        <Stack
          p="36px 0 18px"
          justifyContent="center"
          borderTop={`1px solid ${grayFive}`}
        >
          <Stack direction="row" gap="18px" justifyContent="center">
            <Button
              onClick={(_) => handleCloseModel()}
              variant="outlined"
              sx={{
                py: 1,
                px: 5,
                // textTransform: "none",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
                fontSize: "18px",
                textAlign: "center",
                borderColor: "#000",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#fff",
                  borderColor: "#000",
                },
              }}
            >
              Cancel
            </Button>
            <ContainedButton
              title={"Assign"}
              load={load?.loading && load?.type == "assignDriver"}
              onClick={(_) => handleAssignEmployee()}
              width="fit-content"
            />
          </Stack>
        </Stack>
      </ModelBox>
    </>
  );
};

export default AssignToEmpolyee;
