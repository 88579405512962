const ProductsOptionTypes = {
    //LOADING
    PRODUCT_OPTION_TYPES_LOADING: "PRODUCT_OPTION_TYPES_LOADING",
    //LISTING
    GET_PRODUCTS_OPTION_TYPES_SUCCESS: "GET_PRODUCTS_OPTION_TYPES_SUCCESS",
    //CREATION
    SET_PRODUCT_OPTION_TYPES_SUCCESS: "SET_PRODUCT_OPTION_TYPES_SUCCESS",
    //DETAILS
    GET_PRODUCTS_OPTION_TYPES_DETAILS_SUCCESS: "GET_PRODUCTS_OPTION_TYPES_DETAILS_SUCCESS",
    //UPDATE
    UPDATE_PRODUCTS_OPTION_TYPES_SUCCESS: "UPDATE_PRODUCTS_OPTION_TYPES_SUCCESS",
    //DELETE
    DELETE_PRODUCTS_OPTION_TYPES_SUCCESS: "DELETE_PRODUCTS_OPTION_TYPES_SUCCESS",
    GET_SCROLL_PRODUCTS_OPTION_TYPES_SUCCESS: "GET_SCROLL_PRODUCTS_OPTION_TYPES_SUCCESS",
}
export default ProductsOptionTypes