import React, { useState } from "react";
import Header from "components/Atoms/SharedComponents/Details/header";
import { Button, Stack } from "@mui/material";
import OrderDetailsHead from "components/Organisms/orders/orderDetailsHead";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AssignToEmpolyee from "components/Organisms/orders/Models/assignToEmpolyee";
import AssignToFreelancer from "components/Organisms/orders/Models/assignToFreelancer";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import RouteDriverDetails from "components/Organisms/orders/routesDriverDetails";
// import OrderDetailsHead from "components/Organisms/orders/orderDetails/orderDetailsHead";
// import DriverInfoCard from "components/Organisms/orders/orderDetails/driverInfoCard";
import OrderStatus from "components/Organisms/orders/orderStatus";

// import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import OrderSummary from "components/Organisms/orders/OrderSummary";
import CustomerInfo from "components/Organisms/orders/customerInfo";
import PaymentDetails from "components/Organisms/orders/paymentDetails";
import OrderRoutes from "components/Organisms/orders/orderRoutes";
import { useThemePalette } from "common/hooks/theme_palette";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import { StoreUser } from "services/modules/utils/constant";
import { useNavigate } from "react-router-dom";
import { getEmployeesRequest } from "services/modules/orders";
import RoutesCard from "components/Organisms/orders/routesCard";
import OutlinedButton from "components/Atoms/SharedComponents/Buttons/OutlinedButton";
import OrderNotes from "components/Organisms/orders/OrderNotes";

const OrderDetailsTemplate = ({
  id,
  details,
  breadcrumbs,
  routes,
  employees,
  setFilterObj,
  routeType,
  setSelectedEmployee,
  selectedEmployee,
  handleAssignEmployee,
  handleCloseModel,
  filterObj,
  handleUnAssignDriver,
  setOrderReadyForDelivery,
  orderStatus,
  routeOne,
  routeTwo,
  isRouteOne,
  handleUpdateComplains,
  setUpdateComplaintData,
  updateComplaintData,
  openResplveModal,
  setOpenResplveModal,
  sendRangesRequest,
  load,
  handleAssignFreelancer,
  handleOrderCancel,
  openCancelOrderModal,
  setOpenCancelOrderModal,
  userType,
  routesStatusChanges,
  setOpenUnAssignModal,
  openUnAssignModal,
}) => {
  const { orangeTwo } = useThemePalette();
  const navigate = useNavigate();
  console.log(details?.cart,'details?.cart?.special_instructions');
  const driverData = [
    {
      title: "Driver details of route 1",
      driver: routeOne?.driver,
      route: routeOne,
    },
  ];

  const driversData = [
    ...driverData,
    {
      title: "Driver details of route 2",
      driver: routeTwo?.driver,
      route: routeTwo,
    },
  ];

  const orderSummaryColumns = [
    { accessor: "id", Header: "#" },
    { accessor: "product", Header: "Product Name" },
    { accessor: "sku", Header: "Sku", sort: true },
    { accessor: "price", Header: "Price", sort: true },
    { accessor: "quantity", Header: "Quantity", sort: true },
    { accessor: "total_price", Header: "Total Price", sort: true },
  ];

  // Title definition
  const orderSummaryTitle = "Order Summary";
  const adminPossibleStatus = {
    processing: { status: "Processing", step: 1, next: "Assigned" },
    start_route_assigned: { status: "Assigned", step: 2, next: "Started" },
    started: { status: "Started", step: 3, next: "Assigned" },
    collected: {
      status: "Delivered from supplier(s)to Fulfillment",
      step: 4,
      next: "Assigned",
    },
    ready_for_delivery: {
      status: "Ready for delivery",
      step: 5,
      next: "Assigned",
    },
    finish_route_assigned: {
      status: "Assigned Route 2",
      step: 6,
      next: "Assigned",
    },
    enroute: { status: "Picked up and in its way to deliver", step: 7 },
    delivered: { status: "Delivered", step: 8, next: null },
  };
  return (
    <Stack gap="24px">
      <Header
        title={`Order details  ( #${id} )`}
        deleteBtn={false}
        editBtn={false}
      >
        {orderStatus != "canceled" && userType != StoreUser && (
          <OutlinedButton
            onClick={(_) => setOpenCancelOrderModal(true)}
            title="Cancel Order"
          />
        )}
        {orderStatus == "collected" && userType != StoreUser && (
          <ContainedButton
            onClick={(_) => setOrderReadyForDelivery()}
            title="Ready for delivery"
            width="fit-content"
            load={load?.loading && load?.type == "readyForDelivery"}
          />
        )}
        {
          // orderStatus != "canceled" &&
          // orderStatus == "delivered" &&
          userType != StoreUser && (
            <ContainedButton
              onClick={() => navigate(`/return-request?id=${id}`)}
              title="Return Request"
              width="fit-content"
            />
          )
        }
      </Header>
      <OrderDetailsHead
        orderPlaceIn={details?.created_at}
        deliveryIn={details?.delivery_date}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        gap="24px"
      >
        <Stack width="50%">
          <OrderStatus
            details={details}
            userType={userType}
            routesStatusChanges={routesStatusChanges}
            adminPossibleStatus={adminPossibleStatus}
            title={"Order status"}
            orderStatus={orderStatus}
            height={"100% !important"}
          />
        </Stack>
        {orderStatus == "delivered" ||
        (orderStatus == "canceled" && routeOne?.driver && routeTwo?.driver) ? (
          <RouteDriverDetails
            routeTwo={routeTwo}
            routeOne={routeOne}
            updateComplaintData={updateComplaintData}
            setUpdateComplaintData={setUpdateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            description="View Routes drivers details"
            driver={true}
            driversData={driversData}
          />
        ) : orderStatus == "canceled" &&
          routeOne?.driver &&
          !routeTwo?.driver ? (
          <RouteDriverDetails
            routeOne={routeOne}
            updateComplaintData={updateComplaintData}
            setUpdateComplaintData={setUpdateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            description="View Routes drivers details"
            driver={true}
            driversData={driverData}
          />
        ) : orderStatus == "canceled" &&
          !routeOne?.driver &&
          !routeTwo?.driver ? (
          <RouteDriverDetails
            routeOne={routeOne}
            updateComplaintData={updateComplaintData}
            setUpdateComplaintData={setUpdateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            description="No driver assigned"
            driver={false}
          />
        ) : isRouteOne(orderStatus) ? (
          <RoutesCard
            data={routeOne}
            handleUnAssignDriver={handleUnAssignDriver}
            handleUpdateComplains={handleUpdateComplains}
            setUpdateComplaintData={setUpdateComplaintData}
            updateComplaintData={updateComplaintData}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            userType={userType}
            routeNumber={1}
            routeType={"start_route"}
            openUnAssignModal={openUnAssignModal}
            setOpenUnAssignModal={setOpenUnAssignModal}
          />
        ) : (
          <RoutesCard
            data={routeTwo}
            routeOne={routeOne}
            handleUnAssignDriver={handleUnAssignDriver}
            handleUpdateComplains={handleUpdateComplains}
            setUpdateComplaintData={setUpdateComplaintData}
            updateComplaintData={updateComplaintData}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            userType={userType}
            routeTitle={"Route 2"}
            routeType={"finish_route"}
            routeNumber={2}
            driversData={driverData}
            openUnAssignModal={openUnAssignModal}
            setOpenUnAssignModal={setOpenUnAssignModal}
          />
        )}
      </Stack>
      {userType != StoreUser && (
        <>
          <AssignToEmpolyee
            setFilterObj={setFilterObj}
            employees={employees}
            routeType={routeType}
            routes={routes}
            setSelectedEmployee={setSelectedEmployee}
            selectedEmployee={selectedEmployee}
            handleAssignEmployee={handleAssignEmployee}
            handleCloseModel={handleCloseModel}
            filterObj={filterObj}
            handleUnAssignDriver={handleUnAssignDriver}
            getEmployeesRequest={getEmployeesRequest}
            id={id}
            load={load}
          />
          <AssignToFreelancer
            employees={employees}
            routeType={routeType}
            routes={routes}
            setSelectedEmployee={setSelectedEmployee}
            selectedEmployee={selectedEmployee}
            handleAssignFreelancer={handleAssignFreelancer}
            handleCloseModel={handleCloseModel}
            sendRangesRequest={sendRangesRequest}
            load={load}
            isRouteOne={isRouteOne}
          />
          <ConfirmationModal
            open={openCancelOrderModal}
            setOpen={setOpenCancelOrderModal}
            text={["are you sure you want to cancel order"]}
            confirmBtnTitle="yes"
            handleConfirmation={handleOrderCancel}
            load={load?.loading && load?.type == "cancel"}
          />
        </>
      )}
      <Stack gap={4}>
        {userType != StoreUser && (
          <>
            <Stack>
              <OrderRoutes details={details} />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <CustomerInfo details={details} />
              <PaymentDetails details={details} />
            </Stack>
          </>
        )}
        <Stack>
          <OrderSummary
            details={details}
            title={orderSummaryTitle}
            columns={orderSummaryColumns}
            products={details?.cart?.cart_items}
            wrapper={details?.cart?.wrapper}
            giftCard={details?.cart?.gift_card}
            currency={details?.cart?.currency?.name}
          />
        </Stack>
        {details?.cart?.special_instructions && (
          <Stack>
            <OrderNotes note={details?.cart?.special_instructions} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default OrderDetailsTemplate;
