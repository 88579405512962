/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  orderDeclinationReasons: [],
  orderDeclinationReason: {},
  count: 0,
  load: null,
};

export function orderDeclinationReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ORDER_DECLINATION_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }

    case Types.GET_ORDER_DECLINATION_REASONS_SUCCESS: {
      return {
        ...state,
        orderDeclinationReasons: payload?.data?.order_declination_reasons,
        count: payload?.extra?.total_count,
      };
    }

    case Types.GET_ORDER_DECLINATION_REASONS_DETAILS_SUCCESS: {
      return {
        ...state,
        orderDeclinationReason: payload?.data?.order_declination_reason,
      };
    }

    default: {
      return state;
    }
  }
}
