const suggestedWrappersTypes = {
    // SUGGESTED WRAPPERS
    // list
    GET_SUGGESTED_WRAPS_SUCCESS: "GET_SUGGESTED_WRAPS_SUCCESS",
    SUGGESTED_WRAPS_LOADING: "SUGGESTED_WRAPS_LOADING",
    //details
    GET_SUGGESTED_WRAPS_DETAILS_SUCCESS: "GET_SUGGESTED_WRAPS_DETAILS_SUCCESS",
    // create
    ADD_SUGGESTED_WRAPS_SUCCESS: "ADD_SUGGESTED_WRAPS_SUCCESS",
    // Delete
    DELETE_SUGGESTED_WRAPS_SUCCESS: "DELETE_SUGGESTED_WRAPS_SUCCESS",
    // update
    UPDATE_SUGGESTED_WRAPS_SUCCESS: "UPDATE_SUGGESTED_WRAPS_SUCCESS",
};

export default suggestedWrappersTypes;
