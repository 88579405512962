import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import UsersOccasionsTemplate from "../../templates/occasions/usersOccasions";
import {
  GetOccasionTypesRequest,
  GetScrollOccasionTypesRequest,
} from "../../services/modules/occasions/occasionsTypes";
import {
  getUserOccasionDetailsRequest,
  getUserOccasionsRequest,
} from "../../services/modules/occasions/usersOccasions";
import dayjs from "dayjs";

function UsersOccasionsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.userOccasions.userOccasions);
  const count = useSelector((state) => state.userOccasions.count);
  const details = useSelector((state) => state.userOccasions.userOccasion);
  const occasionsTypes = useSelector(
    (state) => state.occasionsTypes.occasionTypes
  );
  const load = useSelector((state) => state.userOccasions.load);
  const filterOccation = useSelector(
    (state) => state.occasionsTypes.filterOccation
  );
  const occationsCount = useSelector((state) => state.occasionsTypes.listCount);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getUserOccasionDetailsRequest({ occasion_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        occasion_type_id: 0,
        ...filterObj,
      },
    };
    dispatch(getUserOccasionsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      updated_at: <>{dayjs(data?.datetime).format("DD-MM-YYYY hh:mm")}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/occasions",
      title: "Occasions",
    },
    type && {
      active: false,
      title: type === "details" ? "Details" : id ? "Edit Tag" : "New Tag",
    },
  ];
  const handelSubmit = (data, formik) => {};
  const getOccasionsTypes = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        ...filterObj,
      },
    };
    dispatch(GetScrollOccasionTypesRequest(requestData));
  };
  const filterList = [
    {
      type: "search",
      placeholder: "Search user ",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
        setFilterObj,
    },
    {
      type: "filter",
      placeholder: "Occasion Type",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, occasion_type_id: value?.value, page_number: 1 };
        });
      },
      // options: [{ name: "Super admin", id: "super admin" }],
      onMenuOpen: () => {
        filterOccation?.length == 0 && getOccasionsTypes({});
      },
      options: filterOccation && filterOccation,
      getList: getOccasionsTypes,
      applySearchFromRequest: true,
      count: occationsCount,
    },
  ];

  const handleDelete = () => {};

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          // delete fun
          // prototype -> to be modified, as we need to open a modal to confirm that deletion, then show success prompt if deleted successfully
          // handleDelete(data?.id);
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <UsersOccasionsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
      />
    </>
  );
}

export default UsersOccasionsPage;
