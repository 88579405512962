import * as Yup from "yup";

export const initialValues = (data) => {
  return {
    ...data,
  };
};

export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.message`,
        kind: "textArea",
        type: "text",
        label: "Notifications message (EN)",
        width: "47%",
        placeholder: "Write Notification message (EN)",
        colSpan: 6,
      },
      {
        name: `ar.message`,
        kind: "textArea",
        type: "text",
        label: "Notifications message (AR)",
        width: "47%",
        placeholder: "Write Notification message (AR)",
        colSpan: 6,
      },
      // {
      //   name: `user_type`,
      //   kind: "radioBox",
      //   type: "text",
      //   label: "User type",
      //   width: "47%",
      //   placeholder: "Enter the price after discount",
      //   options: [
      //     { value: "1", label: "Customer" },
      //     { value: "2", label: "Driver" },
      //     { value: "3", label: "Any" },
      //   ],
      // },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      message: Yup.string().required("Required"),
    }),
    ar: Yup.object().shape({
      message: Yup.string().required("Required"),
    }),
    // user_type: Yup.string().required("required"),
  });
