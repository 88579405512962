import SearchableSelect from "components/Atoms/Input/Select/SearchableSelect";
import React, { useState, useEffect } from "react";
import { IconButton, InputBase, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Search from "components/Atoms/Input/Search/Search";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
import dayjs from "dayjs";

const DataFiltration = ({ filterList, handlePageChange }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const timeId =
      data &&
      setTimeout(() => {
        data?.list?.onChange(data?.event?.target?.value);
        handlePageChange(1);
      }, 500);
    return () => clearTimeout(timeId);
  }, [data]);

  return (
    <Stack flexDirection="row" flexWrap="wrap" gap={2} alignItems="center">
      {filterList?.map((list, index) => {
        return list?.type == "search" ? (
          <Search
            placeholder={list?.placeholder}
            onChange={(event) => setData({ event, list })}
            width="25%"
            isClearable={list?.isClearable && list?.isClearable}
            setFilterObj={list?.setFilterObj && list?.setFilterObj}
          />
        ) : list?.type == "date" ? (
          <BasicDatePicker onChange={list?.onChange} value={dayjs(list?.value)} filter={true} sx={list?.sx} />
        ) : (
          <SearchableSelect
            options={list?.options}
            onChange={list?.onChange}
            placeholder={list?.placeholder}
            count={list?.count && list?.count}
            getList={list?.getList && list?.getList}
            onMenuOpen={list.onMenuOpen && list.onMenuOpen}
            data={list}
            applySearchFromRequest={list?.applySearchFromRequest}
            isLoading={list?.isLoading && list?.isLoading}
          />
        );
      })}
    </Stack>
  );
};

export default DataFiltration;

// <Stack
//   key={index}
//   flexDirection="row"
//   bgcolor="white"
//   borderRadius="10px"
//   width="20%"
// >

//   <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
//     <SearchIcon />
//   </IconButton>
//   <InputBase
//     sx={{ ml: 1, width: "100%" }}
//     placeholder={list?.placeholder}
//     type={"text"}
//     size="md"
//     onChange={(event) => setData({ event, list })}
//   />
// </Stack>
