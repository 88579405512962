import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import {
  assignEmployeeRequest,
  assignFreelancerRequest,
  cancelOrderRequest,
  getFreelancerRequest,
  getOrderDetailsRequest,
  sendRagnesRequest,
  setOrederReadyForDeliveryRequest,
  unAssignDriverRequest,
  updateDriverComplainRequest,
} from "services/modules/orders";
import {
  isOpenAssignToEmployee,
  isOpenAssignToFreelancer,
} from "services/modules/modal/Actions";
import { toast } from "react-toastify";
import OrderDetailsTemplate from "templates/orders/details";

function OrderDetailsPage({ details }) {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const [filterObj, setFilterObj] = useState(null);
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const employees = useSelector((state) => state.orders.employees);
  const { orderStatus, routeOne, routeTwo, routes, load } = useSelector(
    (state) => state?.orders
  );
  const [openResplveModal, setOpenResplveModal] = useState(false);
  const [routeType, setRouteType] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dirverRouteOne, setDriverRouteOne] = useState(null);
  const [driverRouteTwo, setDriverRouteTwo] = useState(null);
  const [currentDriver, setCurrentDriver] = useState(null);
  const [openUnAssignModal, setOpenUnAssignModal] = useState(false);

  // const [routes, setRoutes] = useState();
  const [key, setKey] = useState(0);
  const [updateComplaintData, setUpdateComplaintData] = useState({});
  const userType = localStorage.getItem("user_type");
  const routesStatusChanges = useSelector(
    (state) => state?.orders?.routesStatusChanges
  );
  useEffect(() => {
    // lw fe driver fe route 1 w mfesh driver fe route 2
    if (routes && routes[0]?.driver?.id && !routes[1]?.driver) {
      setDriverRouteOne(routes[0]?.driver);
      setCurrentDriver(routes[0]?.driver);
    }
    // lw fe driver fe route 1 w feh driver fe route 2 w el order msh delivered
    else if (
      routes &&
      routes[0]?.driver?.id &&
      routes[1]?.driver?.id &&
      details?.status != "delivered"
    ) {
      setDriverRouteOne(routes[0]?.driver);
      setDriverRouteTwo(routes[1]?.driver);
      setCurrentDriver(routes[1]?.driver);
    }
  }, [routes, details]);

  // useEffect(() => {
  //   setRoutes(details?.routes);
  // }, [details?.routes]);

  const isRouteOne = (status) => {
    if (
      status == "processing" ||
      status == "start_route_assigned" ||
      status == "collected" ||
      status == "started"
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (routes) {
      // case first route and there is not deiver assigned
      if (!routes[0]?.driver?.id) {
        setRouteType("start_route");
        // case first route and the driver assigned but still in route 1
      } else if (
        routes[0]?.driver?.id &&
        !routes[1]?.driver?.id &&
        details?.status == "collected"
      ) {
        setRouteType("start_route");
      } else if (routes[0]?.driver?.id && routes[0]?.status == "delivered") {
        setRouteType("finish_route");
      }
    }
  }, [routes]);

  const handleAssignEmployee = () => {
    const action = () => {
      toast.success(`Employee assigned successfully`);
      dispatch(isOpenAssignToEmployee());
      setSelectedEmployee(null);
    };
    !selectedEmployee && toast.error(`Please select driver`);
    const requestData = {
      body: {
        order_id: id,
        route_type: isRouteOne(orderStatus) ? "start_route" : "finish_route",
        driver_type: selectedEmployee?.driver_type,
        driver_id: selectedEmployee?.id,
      },
      action,
    };
    selectedEmployee && dispatch(assignEmployeeRequest({ ...requestData }));
  };

  const handleAssignFreelancer = (data) => {
    const action = () => {
      toast.success(`Driver assigned successfully`);
      dispatch(isOpenAssignToFreelancer());
    };
    const requestData = {
      body: {
        order_id: id,
        route_type: isRouteOne(orderStatus) ? "start_route" : "finish_route",
        driver_type: data?.driver_type,
        driver_id: data?.id,
      },
      action,   
    };
    dispatch(assignFreelancerRequest({ ...requestData }));
  };

  const sendRangesRequest = ({ system_configuration_id, routeId }) => {
    const requestData = {
      body: {
        order_id: id,
        route_type: isRouteOne(orderStatus) ? "start_route" : "finish_route",
        driver_type: "freelancer",
        system_configuration_id: system_configuration_id, //
      },
    };
    dispatch(sendRagnesRequest({ ...requestData }));
  };

  const handleUnAssignDriver = ({ driverId, routeType }) => {
    const action = () => {
      toast.success(`Driver unassigned successfully`);
      // dispatch(getOrderDetailsRequest({ order_id: id }));
      setOpenUnAssignModal(false)
    };
    const requestData = {
      body: {
        order_id: id,
        route_type: isRouteOne(orderStatus) ? "start_route" : "finish_route",
        driver_id: driverId,
      },
      action,
    };
    dispatch(unAssignDriverRequest({ ...requestData }));
  };

  const setOrderReadyForDelivery = () => {
    const action = () => {
      toast.success(`Order Ready for delivery`);
    };
    const requestData = {
      body: {
        order_id: id,
      },
      action,
    };
    dispatch(setOrederReadyForDeliveryRequest({ ...requestData }));
    // setOrederReadyForDeliveryRequest
  };

  const handleCloseModel = () => {
    dispatch(isOpenAssignToEmployee());
    setSelectedEmployee(null);
  };

  const handleUpdateComplains = ({ complaintId, status }) => {
    const action = () => {
      toast.success(
        `Complain ${status == "open" ? "unresolved" : "resolved"} successfully `
      );
    };
    const requestData = {
      route_complaint_id: complaintId,
      body: {
        route_complaint: {
          status,
        },
      },
      action,
    };
    dispatch(updateDriverComplainRequest({ ...requestData }));
  };

  const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);

  const handleOrderCancel = () => {
    const action = () => {
      toast.success(`Order canceled successfully`);
      setOpenCancelOrderModal(false);
    };
    const requestData = {
      id,
      action,
    };
    dispatch(cancelOrderRequest({ ...requestData }));
  };
  
  return (
    <>
      <Loader open={load?.loading && load?.type == "details"} />
      <OrderDetailsTemplate
        id={id}
        details={details}
        routes={routes}
        handleOrderCancel={handleOrderCancel}
        employees={employees}
        setFilterObj={setFilterObj}
        routeType={routeType}
        filterObj={filterObj}
        setSelectedEmployee={setSelectedEmployee}
        selectedEmployee={selectedEmployee}
        handleAssignEmployee={handleAssignEmployee}
        handleCloseModel={handleCloseModel}
        handleUnAssignDriver={handleUnAssignDriver}
        setOrderReadyForDelivery={setOrderReadyForDelivery}
        dirverRouteOne={dirverRouteOne}
        driverRouteTwo={driverRouteTwo}
        currentDriver={currentDriver}
        key={key}
        orderStatus={orderStatus}
        routeOne={routeOne}
        routeTwo={routeTwo}
        isRouteOne={isRouteOne}
        handleUpdateComplains={handleUpdateComplains}
        setUpdateComplaintData={setUpdateComplaintData}
        updateComplaintData={updateComplaintData}
        openResplveModal={openResplveModal}
        setOpenResplveModal={setOpenResplveModal}
        sendRangesRequest={sendRangesRequest}
        load={load}
        handleAssignFreelancer={handleAssignFreelancer}
        setOpenCancelOrderModal={setOpenCancelOrderModal}
        openCancelOrderModal={openCancelOrderModal}
        userType={userType}
        routesStatusChanges={routesStatusChanges}
        openUnAssignModal={openUnAssignModal}
        setOpenUnAssignModal={setOpenUnAssignModal}
      />
    </>
  );
}

export default OrderDetailsPage;
