import { Box, InputBase, Stack } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { useRef, useState } from "react";
export default function Search({
  placeholder,
  orange = false,
  width = "50%",
  onChange,
  isClearable = true,
  setFilterObj,
}) {
  const { grayBorder } = useThemePalette();
  const inputRef = useRef();

  const handleClear = () => {
    if (inputRef?.current) {
      inputRef.current.value = "";
      isClearable &&
        setFilterObj &&
        setFilterObj((s) => {
          return { ...s, keyword: null, page_number: 1 };
        });
    }
  };

  return (
    <Stack
      flexDirection="row"
      bgcolor="white"
      borderRadius="4px"
      width={width}
      sx={{ border: `1px solid ${grayBorder}` }}
    >
      <Box
        src={orange ? icons.orangeSearchIcon : icons.blackSearchIcon}
        component="img"
        width="20px"
        sx={{ p: "15px" }}
        aria-label="search"
      />
      <InputBase
        inputRef={inputRef}
        sx={{ ml: 1, width: "100%" }}
        type={"text"}
        size="md"
        placeholder={placeholder}
        onChange={onChange}
        // onChange={(event) => setData({ event, list })}
      />
      {inputRef?.current?.value && (
        <Box
          width="20px"
          sx={{ p: "15px", cursor: "pointer" }}
          onClick={handleClear}
        >
          x
        </Box>
      )}
    </Stack>
  );
}
