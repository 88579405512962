import React, { useState } from "react";
import Header from "components/Atoms/SharedComponents/Details/header";
import { Button, Stack, Tab, Tabs, Box } from "@mui/material";
import OrderStatus from "components/Organisms/orders/orderStatus";
import { useThemePalette } from "common/hooks/theme_palette";
import { images } from "assets/AssetHelper";
import { useNavigate } from "react-router-dom";

import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import Footer from "components/Atoms/SharedComponents/Table/footer";
import { Delivered } from "services/modules/utils/constant";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";

const ReturnRequestTemplate = ({
  list,
  breadcrumbs,
  id,
  handlePageChange,
  count,
  orderStatus,
  load
}) => {
  const { oliveGreen } = useThemePalette();
  const navigate = useNavigate();
  const adminPossibleStatus = {
    draft: { status: "Processing", step: 1, next: "Assigned" },
    driver_assignment: {
      status: "Assigned",
      step: 2,
      next: "Started",
    },
    route_one_in_progress: {
      status: "Started",
      step: 3,
      next: "Delivered from supplier(s)to Fulfillment",
    },
    items_checked: {
      status: "Delivered from supplier(s)to Fulfillment",
      step: 4,
      next: "Ready for Delivery",
    },
    ready_for_delivery: {
      status: "Ready for Delivery",
      step: 5,
      next: "Assigned Route 2",
    },
    route_two_in_progress: {
      status: "Assigned Route 2",
      step: 6,
      next: "Picked up and in its way to deliver",
    },
    route_three_in_progress: {
      status: "Picked up and in its way to deliver",
      step: 7,
      next: "Delivered",
    },
    completed: { status: "Delivered", step: 8, next: null },
  };
  return (
    <>
      {!list || Object.keys(list).length === 0 ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <img src={images.returnemptyImage} alt="No data" />
          <Button
            onClick={() => navigate(`/create-return-request?id=${id}`)}
            variant="contained"
            sx={{
              py: 1,
              px: 5,
              textTransform: "none",
              textAlign: "center",
              backgroundColor: oliveGreen,
              marginTop: 4,

              "&:hover": {
                backgroundColor: oliveGreen,
              },
            }}
          >
            Create Request
          </Button>
        </Stack>
      ) : (
        <Stack gap="24px">
          <Stack
            direction="row"
            alignSelf="start"
            justifyContent="start"
            gap={2}
            width="100%"
            mb={1}
          >
            <Breadcrumb data={breadcrumbs} />
          </Stack>
          <Header title={`Return Request`} deleteBtn={false} editBtn={false}>
            {orderStatus == Delivered && (
              <ContainedButton
                onClick={() => navigate(`/create-return-request?id=${id}`)}
                title="Create Request"
                width="fit-content"
              />
            )}
          </Header>

          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="24px"
            height={"100% !important"}
          >
            {list?.map((item) => (
              <OrderStatus
                details={item}
                adminPossibleStatus={adminPossibleStatus}
                width={{ md: "48%", xl: "49%" }}
                routesStatusChanges={item?.order_status_changes}
                title={`Request (#${item?.id})`}
                orderStatus={item?.status}
                onClick={(_) =>
                  navigate(
                    `/return-request-details?id=${item?.order_id}&request_id=${item?.id}`
                  )
                }
                isClickable={true}
              />
            ))}
          </Stack>
        </Stack>
      )}
      <Footer
        total_count={count}
        handlePageChange={handlePageChange}
        totalPage={Math.ceil(count / 10)}
      />
    </>
  );
};

export default ReturnRequestTemplate;
