import Types from "./Types";

export const GetProductSuccess = (payload) => ({
  type: Types.GET_PRODUCTS_SUCCESS,
  payload,
});

export const ProductsLoading = (payload) => ({
  type: Types.PRODUCTS_LOADING,
  payload,
});

export const UpdateProductSuccess = (payload) => ({
  type: Types.UPDATE_PRODUCT_SUCCESS,
  payload,
});

export const CreateProductSuccess = (payload) => ({
  type: Types.CREATE_PRODUCT_SUCCESS,
  payload,
});

export const GetProductDetailsSuccess = (payload) => ({
  type: Types.GET_PRODUCT_DETAILS_SUCCESS,
  payload,
});

export const ClearProductDetailsSuccess = () => ({
  type: Types.CLEAR_PRODUCT_DETAILS_SUCCESS,
});

//VARIANTS
export const GetProductVariantsSuccess = (payload) => ({
  type: Types.GET_PRODUCT_VARIANTS_SUCCESS,
  payload,
});

export const ProductVariantsLoading = (payload) => ({
  type: Types.PRODUCT_VARIANTS_LOADING,
  payload,
});

export const GetVariantDetailsSuccess = (payload) => ({
  type: Types.GET_VARIANT_DETAILS_SUCCESS,
  payload,
});

export const CreateVariantSuccess = (payload) => ({
  type: Types.CREATE_VARIANT_SUCCESS,
  payload,
});

export const UpdateVariantSuccess = (payload) => ({
  type: Types.UPDATE_VARIANT_SUCCESS,
  payload,
});

export const DeleteProductVariantSuccess = (payload) => ({
  type: Types.DELETE_PRODUCT_VARIANT_SUCCESS,
  payload,
});

//VARIANT LOCATIONS
export const GetVariantBranchesSuccess = (payload) => ({
  type: Types.GET_VARIANT_BRANCHES_SUCCESS,
  payload,
});

export const CreateVariantBranchSuccess = (payload, data) => ({
  type: Types.CREATE_VARIANT_LOCATION_SUCCESS,
  payload,
  data,
});

export const UpdateVariantBranchSuccess = (payload, data) => ({
  type: Types.UPDATE_VARIANT_LOCATION_SUCCESS,
  payload,
  data,
});

export const DeleteVariantBranchSuccess = (payload, data) => ({
  type: Types.DELETE_VARIANT_LOCATION_SUCCESS,
  payload,
  data,
});

//CUSTOMER REVIEWS
export const GetCustomersReviewsSuccess = (payload) => ({
  type: Types.GET_CUSTOMERS_REVIEWS_SUCCESS,
  payload,
});

export const GetScrollBranchessSuccess = (payload) => ({
  type: Types.GET_SCROLL_BRANCHES_SUCCESS,
  payload,
});

export const GetOptionValuesSuccess = (payload) => ({
  type: Types.GET_OPTION_VALUES_SUCCESS,
  payload,
});

export const GetOptionValuesLoading = (payload) => ({
  type: Types.GET_OPTION_VALUES_LOADING,
  payload,
});
