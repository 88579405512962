import { makeFilterString } from "../../utils/Helper";

import { reduxRequest } from "../../../../common/utils/reduxRequest";
import { AddCategoriesSuccess, CategoriesLoading, GetCategoriesDetailsSuccess, GetCategoriesSuccess, GetScrollCategoriesTypesSuccess } from "./Action";

export const getCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CategoriesLoading,
    loadingType: "list",
    successFunction: GetCategoriesSuccess,
    url: `/admin/categories/categories${makeFilterString(variables?.params)}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetScrollCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CategoriesLoading,
    loadingType: "list",
    successFunction: GetScrollCategoriesTypesSuccess,
    url: `/admin/categories/categories${makeFilterString(variables?.params)}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createCategoriesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CategoriesLoading,
    loadingType: "add",
    successFunction: AddCategoriesSuccess,
    action: variables?.action,
    url: `/admin/categories/categories`,
    method: "post",
    data: variables?.body,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getCategoryDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CategoriesLoading,
    loadingType: "details",
    successFunction: GetCategoriesDetailsSuccess,
    url: `/admin/categories/categories/${variables?.category_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteCategoryRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CategoriesLoading,
    loadingType: "delete category",
    action: variables?.action,
    url: `/admin/categories/categories/${variables?.id}`,
    method: "delete",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateCategoryRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CategoriesLoading,
    loadingType: "update category",
    url: `/admin/categories/categories/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
