import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";

import { icons } from "assets/AssetHelper";
import { AppForm, AppFormField, SubmitButton } from "components/Molecules/Form";
import { resetPasswordSchema } from "common/utils/schemas";
import ResetPasswordSuccessful from "./resetPasswordSuccess";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { resetPasswordRequest } from "services/modules/authenticate";
import setAuthToken from "services/modules/utils/handel_api";
import {toast} from "react-toastify";

export default function ResetPasswordForm({hideIcon,setResetPasswordStep,customAction,userType='admin_user'}) {
  const email = localStorage.getItem("forgetPasswordEmail");
  const token = localStorage.getItem("verification-token");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { load } = useSelector((state) => state.authenticate);

  const handleFormSubmit = (values, { setSubmitting }) => {

    const action = () => {
      const customAction=()=>{
        setResetPasswordStep({action:false,step:null})
        toast.success("Password have been changed successfully")
      }
      {customAction? customAction():  navigate("/reset-password-success")}

      localStorage.removeItem("verification-token");


    };
    setSubmitting(false);
    const requestData = {
      body: {
        user: {
          email,
          password: values?.password,
          password_confirmation: values?.confirmPassword,
        },
        device: {
          device_type: "android",
          fcm_token: "{{fcm_token}}",
        },
      },
      headers: {
        "verification-token": token,
      },
      action,
    };

    dispatch(resetPasswordRequest({...requestData,user_type:userType}));
  };

  return (
      <>
        <Loader open={load?.loading} />
        <Stack sx={{ gap: 5 }}>
          {!hideIcon&&  <Box
              component="img"
              src={icons.lockIcon}
              sx={{ width: "60px", height: "80px" }}
          />}

          <Typography fontFamily="jost" fontSize="32px" fontWeight="400">
            Set new password
          </Typography>
          <AppForm
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validationSchema={resetPasswordSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, { setSubmitting });
              }}
          >
            <Stack sx={{ gap: 5 }}>
              <AppFormField
                  label="New Password"
                  name="password"
                  type="password"
                  labelStyle={{ fontSize: "18px" }}
                  placeholder="**********"
              />
              <AppFormField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  labelStyle={{ fontSize: "18px" }}
                  placeholder="**********"
              />
              <Stack sx={{ gap: 4 }}>
                <SubmitButton>Reset Password</SubmitButton>
              </Stack>
            </Stack>
          </AppForm>
        </Stack>
      </>
  );
}
