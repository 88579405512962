import Types from "./Types";


export const GetWrapExtrasSuccess = (payload) => ({
  type: Types.GET_WRAP_EXTRAS_SUCCESS,
  payload,
});

export const GetWrapExtrasLoading = (payload) => ({
  type: Types.WRAP_EXTRAS_LOADING,
  payload,
});

export const AddWrapExtraSuccess = (payload) => ({
  type: Types.ADD_WRAP_EXTRA_SUCCESS,
  payload,
});

export const AddExtraFailed = (payload) => ({
  type: Types.ADD_WRAP_EXTRA_FAILED,
  payload,
});


export const DeleteWrapExtraSuccess = (payload) => ({
  type: Types.DELETE_WRAP_EXTRA_SUCCESS,
  payload,
});

export const DeleteWrapExtraFailed = (payload) => ({
  type: Types.DELETE_WRAP_EXTRA_FAILED,
  payload,
});

export const UpdateWrapExtraSuccess = (payload) => ({
  type: Types.UPDATE_WRAP_EXTRA_SUCCESS,
  payload,
});

export const GetWrapExtraDetailsSuccess = (payload) => ({
  type: Types.GET_WRAP_EXTRA_DETAILS_SUCCESS,
  payload,
});

export const GetScrollWrapExtraSuccess = (payload) => ({
  type: Types.GET_SCROLL_WRAP_EXTRA_SUCCESS,
  payload,
});