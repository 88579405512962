import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import SuccessModal from "components/Molecules/Models/SuccessModal";

import { useThemePalette } from "common/hooks/theme_palette";
import ColorRow from "components/Atoms/SharedComponents/Wrappers/Colors/ColorRow";
import { toast } from "react-toastify";
import ProductOptionValuesTemplate from "../../templates/products-option-values";

import { GetProductsOptionValues } from "../../services/modules/lookups/productOptionValues/Actions";
import {
  createProductOptionValueRequest,
  deleteProductOptionValueRequest,
  getProductOptionValueDetailsRequest,
  getProductsOptionValuesRequest,
  updateProductValueRequest,
} from "../../services/modules/lookups/productOptionValues";
import {
  getProductsOptionTypesRequest,
  getScrollProductsOptionTypesRequest,
} from "../../services/modules/lookups/productOptionTypes";

function ProductsOptionValuesPage() {
  const { textGray } = useThemePalette();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formikValues, setFormik] = useState();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.option_values
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const list = useSelector(
    (state) => state.productsOptionValues?.productsOptionValues
  );
  const count = useSelector((state) => state.productsOptionValues?.count);
  const details = useSelector(
    (state) => state?.productsOptionValues?.productOptionValue
  );
  const load = useSelector((state) => state.productsOptionValues?.load);

  const options = useSelector(
    (state) => state.productsOptionTypes?.productsOptionTypes
  );
  const filterProductsOptionTypes = useSelector(
    (state) => state.productsOptionTypes?.filterProductsOptionTypes
  );
  const productsOptionTypesCount = useSelector(
    (state) => state.productsOptionTypes?.listCount
  );
  const productsOptionTypesLoading = useSelector(
    (state) => state.productsOptionTypes?.load
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState(null);

  const getProductOptionType = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        in_filter: true,
        ...filterObj,
      },
    };
    dispatch(getScrollProductsOptionTypesRequest(requestData));
  };
  // useEffect(() => {
  //   const requestData = {
  //     params: {
  //       page_size: 10,
  //       page_number: 1,
  //       keyword: "",
  //       sort_by: "id",
  //       ...filterObj,
  //     },
  //   };
  //   type == "form" && dispatch(getProductsOptionTypesRequest(requestData));
  // }, [type]);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    console.log(id);
    dispatch(getProductOptionValueDetailsRequest({ option_value_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        option_type_id: null,
        ...filterObj,
      },
    };
    dispatch(getProductsOptionValuesRequest(requestData));
  };
  const fetchProductOptionType = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollProductsOptionTypesRequest(requestData));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      // button: <Button variant="outline">test</Button>,
      presentation: <ColorRow data={data} />,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/products-option-values",
      title: "Products Option Values",
    },
    type && {
      active: false,
      title:
        type === "details"
          ? "Option values details"
          : id
          ? "Edit Option Value"
          : "New Option Value",
    },
  ];

  const handelSubmit = (data, formik) => {
    const type = options.find((option) => option?.id === data?.optionType);
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/products-option-values");
      !id && formik?.resetForm();

      toast.success(
        `New Option Value  has been ${id ? "updated" : "created"} successfully`
      );
    };
    const requestData = {
      body: {
        option_value: {
          ...formik?.values,
          presentation:
            type?.name === "Color" || type?.name === "color" || !type?.name
              ? data?.presentation
              : data?.en.name,
        },
      },
      action,
    };
    if (id) {
      dispatch(updateProductValueRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createProductOptionValueRequest(requestData));
    }
  };
  
  const filterList = [
    {
      type: "filter",
      placeholder: "Option Type",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, option_type_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterProductsOptionTypes?.length == 0 && getProductOptionType({});
      },
      options: filterProductsOptionTypes && filterProductsOptionTypes,
      getList: getProductOptionType,
      applySearchFromRequest: true,
      count: productsOptionTypesCount,
    },
  ];
  const handleDelete = (id) => {
    // const id = details?.id ? details?.id : selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }

      toast.success("Option Type has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteProductOptionValueRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below

  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const optionTypesOptions = {
    optionTypes: {
      options: [details?.option_type, ...filterProductsOptionTypes],
      onOpen: filterProductsOptionTypes?.length <= 1 && fetchProductOptionType,
      isLoading: productsOptionTypesLoading?.loading,
      getList: fetchProductOptionType,
      count: productsOptionTypesCount,
    },
  };

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <ProductOptionValuesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        selectedRow={selectedRow}
        can_create={isSuperAdmin || permissions?.can_create}
        optionTypesOptions={optionTypesOptions}
        filterProductsOptionTypes={filterProductsOptionTypes}
      />
    </>
  );
}

export default ProductsOptionValuesPage;
