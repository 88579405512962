import React from 'react'
import { useDispatch } from 'react-redux'
import { isOpenPreviewChatImg } from 'services/modules/modal/Actions'

export default function ImageMessage({ img, onclick }) {
    const dispatch = useDispatch()
    return <>
        <img src={img} width='300px' style={{ cursor: 'pointer' }} onClick={onclick} alt='files' />
    </>
}
