import Types from "./Types";

export const CountriesLoading = (payload) => ({
  type: Types.COUNTIRES_LOADING,
  payload,
});

export const GetCountriesSuccess = (payload) => ({
  type: Types.GET_COUNTIRES_SUCCESS,
  payload,
});

export const GetScrollCountriesSuccess = (payload) => ({
  type: Types.GET_SCROLL_COUNTRIES_SUCCESS,
  payload,
});
