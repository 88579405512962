import * as Yup from "yup";

export const editInitialValues = (data) => {
  return {
    ...data,
    role: data?.role?.id,
  };
};
export const editStructure = (_, rolesOptions, getRoles, rolesCount, load) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `name`,
        kind: "input",
        type: "text",
        label: "User Name",
        width: "47%",
        placeholder: "Write User name",
      },
      {
        name: `role`,
        kind: "select",
        type: "text",
        label: "Role",
        width: "47%",
        options: rolesOptions,
        onOpen: rolesOptions?.length <= 1 && getRoles,
        getList: getRoles,
        count: rolesCount,
        isLoading: load?.type == "filter-roles-list",
        height: "200px",
        placeholder: "Choose role",
      },
      {
        name: `email`,
        kind: "input",
        type: "text",
        label: "Email",
        width: "47%",
        placeholder: "Write Email",
      },
      {
        name: `phone_number`,
        kind: "input",
        type: "text",
        label: "Phone Number",
        width: "47%",
        placeholder: "Write Phone number",
      },
    ],
  },
];
const phoneNumberRegex = /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export const editValidationSchema = () =>
  Yup.object({
    name: Yup.string().required("required"),
    role: Yup.string().required("required"),
    email: Yup.string().email().required("required"),
    phone_number: Yup.string().matches(phoneNumberRegex, "invalid phone number").required("required"),
  });

// role: Yup.object({
//   id: Yup.string(),
//   name:Yup.string(),
// }),
