import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { detailsData } from "./details";
import { useEffect } from "react";

const SystemConfigurationTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  selectedRow,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");

  const [isClickable, setIsClickable] = useState(false);

  useEffect(() => {
    setIsClickable(selectedRow?.formikData?.values?.need_action);
  }, [selectedRow?.formikData?.values?.need_action]);

  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "System Configurations",
            createBtnTitle: "",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "key",
                Header: "Key",
                sort: true,
              },
              {
                accessor: "value",
                Header: "Value",
                sort: true,
              },
              { accessor: "description", Header: "Description", sort: true },
              {
                accessor: "created_at",
                Header: "Created At",
              },
              {
                accessor: "updated_at",
                Header: "Updated At",
              },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id
              ? { is_visible: false, need_action: false }
              : initialValues(details, supportedLang),
            structure: () => structure(supportedLang, isClickable),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit Sytem Configuration" : "Add Sytem Configuration",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default SystemConfigurationTemplate;
