import { toast } from "react-toastify";

export default function handleErrors(error) {
  let errorData = error?.response?.data;
  console.log(errorData,'error');
  console.log(error,'error');
  if (errorData) {
    if (Array.isArray(errorData?.errors)) {
      errorData?.errors?.map((data) => {
        toast.error(data?.message);
        // throw data?.message;
      });
    } else {
      toast.error(errorData?.message);
      // throw errorData?.message;
    }
  } else {
    toast.error(error?.message);
    // throw error?.message;
  }
}