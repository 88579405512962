import { Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function GetDirection({
  address,
  lat,
  long,
  displayDirImg = true,
}) {
  const { orange } = useThemePalette();
  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`;
    window.open(url, "_blank");
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="center" // Align items vertically
        gap="4px"
        sx={{ cursor: "pointer", overflowWrap: "break-word !important" }}
        flexWrap={"wrap"}
        onClick={(_) => openGoogleMaps(lat, long)}
      >
        <span
          style={{
            // whiteSpace: "nowrap",
            fontSize: "15px",
            overflowWrap: "break-word !important",
          }}
        >
          {address}
        </span>{" "}
        {displayDirImg && (
          <img
            src={icons?.direction}
            alt="direction"
            width="15.55px"
            height={"15.55px"}
          />
        )}
        <Typography
          color={orange}
        //   sx={{ whiteSpace: "nowrap" }}
          fontSize={"12px"}
        >
          Get Direction
        </Typography>
      </Stack>
    </>
  );
}
