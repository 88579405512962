import Types from "./Types";


//! admins
export const GetAdminUsersSuccess = (payload) => ({
  type: Types.GET_ADMIN_USERS_SUCCESS,
  payload,
});

export const CreateAdminUserSuccess = (payload) => ({
  type: Types.CREATE_ADMIN_USER_SUCCESS,
  payload,
});

export const GetAdminUserDetailsSuccess = (payload) => ({
  type: Types.GET_ADMIN_USER_DETAILS_SUCCESS,
  payload,
});

export const UpdateAdminUserSuccess = (payload) => ({
  type: Types.UPDATE_ADMIN_USER_SUCCESS,
  payload,
});


export const DeleteAdminUserSuccess = (payload) => ({
  type: Types.DELETE_ADMIN_USER_SUCCESS,
  payload,
});
export const ChangePasswordSuccess = (payload) => ({
  type: Types.CHANGE_PASSWORD_SSUCCESS,
  payload,
});



//! roles
export const GetRolesSuccess = (payload) => ({
  type: Types.GET_ROLES_SUCCESS,
  payload,
});

export const RolesLoading = (payload) => ({
  type: Types.ROLES_LOADING,
  payload,
});

export const GetRoleDetailsSuccess = (payload) => ({
  type: Types.GET_ROLE_DETAILS_SUCCESS,
  payload,
});

export const CreateRoleSuccess = (payload) => ({
  type: Types.CREATE_ROLE_SUCCESS,
  payload,
});

export const DeleteRoleSuccess = (payload) => ({
  type: Types.DELETE_ROLE_SUCCESS,
  payload,
});

export const UpdateRoleSuccess = (payload) => ({
  type: Types.UPDATE_ROLE_SUCCESS,
  payload,
});

//! resources
export const GetResourcesSuccess = (payload) => ({
  type: Types.GET_RESOURCES_SUCCESS,
  payload,
});

export const GetScrollRolesSuccess = (payload) => ({
  type: Types.GET_SCROLL_ROLES_SUCCESS,
  payload,
});
