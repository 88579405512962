import { makeFilterString } from "../../utils/Helper";
import { GetNotificationsSuccess, GetUnreadCountLoading, GetUnreadCountSuccess, NotificationsLoading } from "./Action";
import { reduxRequest } from "common/utils/reduxRequest";

export const getNotificationsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: NotificationsLoading,
    successFunction: GetNotificationsSuccess,
    loadingType: "list",
    action: variables?.action,
    url: `/notifications${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getUnreadCountRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetUnreadCountLoading,
    loadingType: "list",
    successFunction: GetUnreadCountSuccess,
    url: `/notifications/count`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

// export const markAllAsReadRequest = ({ ...variables }) => {
//   const requestData = {
//     isLoading: NotificationsLoading,
//     successFunction: MarkAllAsRead,
//     loadingType: "mark",
//     url: `/notifications/read`,
//     method: "put",
//   };

//   return async (dispatch) => {
//     reduxRequest({
//       dispatch,
//       ...requestData,
//     });
//   };
// };

// export const markAsReadRequest = ({ ...variables }) => {
//   const requestData = {
//     isLoading: NotificationsLoading,
//     successFunction: MarkAsRead,
//     loadingType: "mark",
//     url: `/notifications/${variables?.id}`,
//     method: "get",
//   };

//   return async (dispatch) => {
//     reduxRequest({
//       dispatch,
//       ...requestData,
//     });
//   };
// };
