import CustomAccordion from "components/Molecules/Accordion/customAccordion";
import PageHeader from "components/Molecules/Products/AddProduct/header";
import { useState } from "react";

export default function EditProductTemplate({ breadcrumbs, data, type }) {
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  // console.log(expandedAccordion);
  return (
    <>
      <PageHeader title={type === "edit" ? "Edit Product" : "Add Product"} breadcrumbs={breadcrumbs} />
      <CustomAccordion expandedAccordion={expandedAccordion} setExpandedAccordion={setExpandedAccordion} data={data} />
    </>
  );
}
