import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CitiesTemplate from "templates/cities";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import {
  createCityRequest,
  deleteCityRequest,
  getCitiesRequest,
  getCityDetailsRequest,
  getScrollCitiesRequest,
  updateCityRequest,
} from "services/modules/cities/cities";

import GoogleMap from "components/Molecules/Map/GoogleMap";
import { toast } from "react-toastify";

function CitiesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.cities.cities);
  const fulfillmentCenters = list?.filter((city) => city.is_fulfillment_center);
  const count = useSelector((state) => state.cities.count);
  const details = useSelector((state) => state.cities.city);
  const load = useSelector((state) => state.cities.load);

  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.google_cities
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const filterCitites = useSelector((state) => state.cities.filterCitites);
  const cititesCount = useSelector((state) => state.cities.listCount);
  const cititesLoading = useSelector((state) => state.cities.load);
  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getCityDetailsRequest({ id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getCitiesRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      is_fulfillment_center: (
        <span variant="outline">
          {data.is_fulfillment_center ? "yes" : "no"}
        </span>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const getCities = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        is_fulfillment_center: true,
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollCitiesRequest(requestData));
  };
  const breadcrumbs = !type
    ? [
        {
          active: true,
          path: "/",
          title: "Home",
        },
        {
          active: false,
          path: "/cities",
          title: "Cities",
        },
      ]
    : [
        {
          active: true,
          path: "/",
          title: "Home",
        },
        {
          active: true,
          path: "/cities",
          title: "Cities",
        },
        {
          active: false,
          title:
            type === "details" ? "City details" : id ? "Edit City" : "Add City",
        },
      ];

  const handelSubmit = (data, formik) => {
    // const { name, nameAr, isFulfillmentCenter, fulfillmentCenter, fees } = data;
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/cities");
      !id && formik?.resetForm();
      toast.success(
        ` ${id ? "City" : "New City"} has been ${
          id ? "updated" : "created"
        } successfully`
      );
    };
    const requestData = {
      body: {
        city: {
          ...data,
          lat: location?.lat,
          long: location?.lng,
        },
      },
      action,
    };
    if (id) {
      //update call
      dispatch(updateCityRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createCityRequest(requestData));
    }
  };

  const filterList = [];

  const handleDelete = (id) => {
    // const id = details?.id ? details?.id : selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/cities");
      toast.success("City has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteCityRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  // details of a single city
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  //this will work as an initial location
  //and will be updated when a place is searched
  const [location, setLocation] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });

  const getCurrentLocation = (lat, lng) => {
    setLocation({ lat, lng });
  };

  const formChildren = () => {
    return (
      <GoogleMap
        getCurrentLocation={getCurrentLocation}
        location={location}
        type={!id ? "add" : "edit"}
        details={details}
      />
    );
  };
  const options = {
    cities: {
      options: [details?.fulfillment_center, ...filterCitites],
      onOpen: filterCitites?.length <= 1 && getCities,
      isLoading:
        cititesLoading?.loading && cititesLoading?.type == "filter-list-cities",
      getList: getCities,
      count: cititesCount,
    },
  };
  return (
    <>
      <Loader open={load?.loading} />
      <CitiesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        formChildren={formChildren()}
        fulfillmentCenters={fulfillmentCenters}
        can_create={isSuperAdmin || permissions?.can_create}
        options={options}
      />
    </>
  );
}

export default CitiesPage;
