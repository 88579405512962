import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useEffect, useState } from "react";
import { useThemePalette } from "common/hooks/theme_palette";
import EditVendorForm from "../../components/Organisms/vendor/editVendor/editVendorForm";
import Locations from "../../components/Atoms/SharedComponents/Vendor/Locations/Locations";
import { getStoreBranchesRequest } from "../../services/modules/vendors/storeBranches";
import { toast } from "react-toastify";
import { getVendorDetailsRequest, updateVendorRequest } from "../../services/modules/vendors";
import EditVendorTemplate from "../../templates/vendor/editVendor";
import setAuthToken from "../../services/modules/utils/handel_api";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";

export default function EditVendorPage() {
  const { oliveGreen, orange } = useThemePalette();
  const refOpenLocationsForm = useRef();
  const refsubmit = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const isStore = localStorage?.getItem("user_type") == "Store";

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const details = useSelector((state) => state.vendors.vendor);
  const storeBrances = useSelector((state) => state.storeBranches.storeBranches);
  const tags = useSelector((state) => state.tags.tags);

  const storesLoading = useSelector((state) => state.storeBranches.load);
  const vendorLoading = useSelector((state) => state.vendors.load);
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    // because this page will be used as vendor profile (details & edit form)
    {
      active: true,
      path: isStore ? `/vendors?type=details&id=${id}` : "/vendors",
      title: isStore ? "Vendor details" : "Vendors",
    },
    {
      active: false,
      title: type == "form" ? "Add vendor" : "Edit vendor",
    },
  ];

  const handleCancel = (e) => {
    e.stopPropagation();
    if (isStore) {
      navigate(`/vendors?type=details&id=${id}`);
    } else {
      navigate("/vendors");
    }
  };

  const handleSubmit = (values, formik) => {
    if (values) {
      const countryCode = +formik?.values?.country_code;
      const phoneNumberWithoutCountryCode = formik?.values?.phone_number.slice(countryCode?.toString()?.length);

      const tags = formik?.values?.tags.map((tag) => tag.value);
      setAuthToken(token);

      const requestData = {
        body: {
          store: {
            ...formik?.values,
            phone_number: phoneNumberWithoutCountryCode,
            logo: formik?.values?.image,
            tag_ids: tags,
          },
        },
        action: () => {
          toast.success("Vendor updated successfully");
          //   navigate("/vendors");
        },
      };
      dispatch(updateVendorRequest({ ...requestData, id: id }));
    }
  };

  const detailsHeaderChildren = (
    <Stack direction="row" gap={2}>
      <Button onClick={handleCancel} sx={{ color: "red", border: "1px solid" }}>
        Cancel
      </Button>
      <Button
        onClick={(e) => {
          handleSubmit();
          refsubmit?.current?.click();
          e.stopPropagation();
        }}
        sx={{
          color: "white",
          backgroundColor: oliveGreen,
          "&:hover": {
            backgroundColor: oliveGreen,
          },
        }}
      >
        Update
      </Button>
    </Stack>
  );
  const locationsHeaderChildren = (
    <Button
      onClick={(e) => {
        refOpenLocationsForm?.current?.click();
        e.stopPropagation();
      }}
      sx={{ color: orange, fontWeight: "500", fontSize: "20px", px: 0 }}
    >
      + Add new location
    </Button>
  );

  const accordionData = [
    {
      label: "Details",
      details: (
        <EditVendorForm
          id={id}
          details={details}
          tags={tags}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          refsubmit={refsubmit}
          options={tags}
        />
      ),
      headerChildren: detailsHeaderChildren,
    },
    {
      label: "Locations",
      details: (
        <Locations
          storeBranches={storeBrances}
          isHeaderChildren={false}
          id={id}
          refOpenLocationsForm={refOpenLocationsForm}
          edit={true}
        />
      ),
      headerChildren: locationsHeaderChildren,
    },
  ];

  const getDetails = async () => {
    dispatch(getVendorDetailsRequest({ id }));
  };

  useEffect(() => {
    if (id) getDetails();
  }, [id]);
  useEffect(() => {
    const requestedData = {
      params: {
        store_id: id,
      },
    };
    dispatch(getStoreBranchesRequest(requestedData));
  }, []);

  const isPageLoading = () => {
    const loadingTypes = [
      "listStoreBranches",
      "deleteStoreBranch",
      "addStoreBranch",
      "updateStoreBranch",
      "update vendor",
      "details",
    ];
    return loadingTypes.includes(storesLoading?.type) || loadingTypes.includes(vendorLoading?.type);
  };

  return (
    <>
      {isPageLoading() && <Loader open={true} />}
      <EditVendorTemplate breadcrumbs={breadcrumbs} data={accordionData} type={type} />
    </>
  );
}
