import { Typography, Box, Stack, Button } from "@mui/material";
import { icons } from "assets/AssetHelper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import SelectBox from "components/Atoms/Input/Select/Select";
import { useThemePalette } from "common/hooks/theme_palette";

export default function LocationWithQuantity(props) {
  const { oliveGreen } = useThemePalette();
  const {
    i,
    locationOptions,
    location,
    locations,
    handleDeleteVariantBranch,
    handleLocationDataChange,
    handleSaveVariantBranch,
  } = props;

  let cities = {};
  locationOptions?.forEach((location) => (cities[location?.id] = location?.name));
  
  const selectedLocations = locations.map((loc) => loc.city?.id);
  const availableOpts = locationOptions
    .filter((loc) => {
      return !selectedLocations.includes(loc.id);
    }) //to include the selected option
    .concat({
      id: location?.city?.id,
      name: cities[location?.city?.id],
    });

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    handleLocationDataChange(i, key, value);
  };

  const handleSave = () => {
    handleSaveVariantBranch(location);
  };

  const handleDelete = () => {
    handleDeleteVariantBranch(i, location);
  };

  return (
    <Stack direction="row" gap={4} alignItems="center">
      <SelectBox
        icon={KeyboardArrowDownIcon}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={location?.city?.id ?? ""}
        name="city"
        onchange={handleChange}
        items={availableOpts}
        title="Location"
        style={{ width: "40%" }}
        placeholder="Location"
      />
      <Stack>
        <Typography sx={{ color: "#565656" }}>Quantity</Typography>
        <TextFieldInput
          name="quantity"
          type="number"
          placeholder="Quanity"
          inputProps={{ min: 0 }}
          value={location?.count_on_hand || ""}
          onChange={handleChange}
        />
      </Stack>
      <Stack direction="row" gap={1}>
        <Button
          onClick={handleSave}
          sx={{
            width: "150px",
            p: 0,
            color: "white",
            fontWeight: "300",
            backgroundColor: oliveGreen,
            "&:hover": {
              backgroundColor: oliveGreen,
            },
          }}
        >
          Save Location
        </Button>
        <Stack
          onClick={handleDelete}
          sx={{ border: "1px solid red", p: 1.5, borderRadius: "4px", cursor: "pointer" }}
          alignItems="center"
          direction="row"
        >
          <Box component="img" src={icons.templateDeleteIcon} sx={{ m: 0 }} />
        </Stack>
      </Stack>
    </Stack>
  );
}
