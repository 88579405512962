import { Box, Stack, Button } from "@mui/material";
import React from "react";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import ProductListing from "../../../Molecules/Order/returnRequest/productListing";

export default function QuantityAndReason({
  details,
  load,
  formik,
  handleChange,
}) {
  return (
    <>
      <Loader open={load?.loading && load?.type == "details"} />
      <form onSubmit={formik?.handleSubmit} style={{width:'100%'}} >
        <Stack
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            flexDirection: "row",
            rowGap:'20px',
            justifyContent: "space-between"
          }}
        >
          {details && details.length > 0 ? (
            details?.map((detail, index) => (
              <Box
                key={index}
                p={3}
                backgroundColor={"white"}
                style={{ width: "46%" }}
              >
                <Stack gap={4}>
                  <ProductListing
                    imageStyle={{ width: 64, height: 64 }}
                    details={detail}
                    load={load}
                  />
                  <TextFieldInput
                    label={"Enter Quantity"}
                    labelStyle={{ color: "#565656", fontSize: 18 }}
                    name={`quantity_${index}`}
                    placeholder="Write quantity"
                    type="number"
                    value={formik.values[`quantity_${index}`] || ""}
                    onChange={(event) =>
                      handleChange(index, "quantity", event?.target?.value)
                    }
                  />
                  <TextFieldInput
                    label={"Reason"}
                    labelStyle={{ color: "#565656", fontSize: 18 }}
                    name={`reason_${index}`}
                    multiline={true}
                    placeholder="Write Reason"
                    rows={5}
                    value={formik.values[`reason_${index}`] || ""}
                    onChange={(event) =>
                      handleChange(index, "reason", event?.target?.value)
                    }
                  />
                </Stack>
              </Box>
            ))
          ) : (
            <Box p={3} backgroundColor={"white"}>
              <p>No details available.</p>
            </Box>
          )}
          <Button type="submit" style={{ display: "none" }}>
            Hidden Submit Button
          </Button>
        </Stack>
      </form>
    </>
  );
}
