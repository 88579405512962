import { Stack,Box } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    details: [
      {
        label: "User Name",
        name: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box component="img" src={icons.User} width="24px" />
            {data?.name}
          </Stack>
        ),
      },
      {
        label: "Role",
        name: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box component="img" src={icons.role} width="24px" />{" "}
            {data?.role?.name}
          </Stack>
        ),
      },
      {
        label: "Email",
        name: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box component="img" src={icons.email} width="24px" /> {data?.email}
          </Stack>
        ),
      },
      {
        label: "Phone Number",
        name: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box component="img" src={icons.phone} width="24px" />{" "}
            {data?.phone_number }
          </Stack>
        ),
      },
      {
        label: "Created At",
        name: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box component="img" src={icons.dates} width="24px" />
            {dayjs(data?.created_at).format("DD-MM-YYYY HH-MM a")}
          </Stack>
        ),
      },
      {
        label: "Updated At",
        name: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Box component="img" src={icons.dates} width="24px" />
            {dayjs(data?.updated_at).format("DD-MM-YYYY HH-MM a")}
          </Stack>
        ),
      },
    ],
  },
];
