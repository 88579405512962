import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { useThemePalette } from "../../../common/hooks/theme_palette";
import RejectionModel from "../../../components/Atoms/SharedComponents/Vendor/Model/rejectionModel";
import ConfirmationModal from "../../../components/Molecules/Models/ConfirmationModal";
import VendorDetailsPage from "../../../pages/vendor/vendorDetails";

const VendorTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  formChildren,
  openEditReason,
  setEditReason,
  rejectionReason,
  setRejectionReason,
  handleRejectionSubmit,
  approval,
  setApproval,
  setOpenConfirmation,
  openConfirmation,
  handleProfileStatus,
  approvalStatusOptions,
  status,
  formikValues,
  options,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [location, setLocation] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });
  const tags = useSelector((state) => state.tags?.tags);
  const { oliveGreen, confirmationGreen } = useThemePalette();

  const getCurrentLocation = (lat, lng) => {
    setLocation({ lat, lng });
  };
  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          DetailsComponent={
            <VendorDetailsPage
              details={details}
              actionsList={actionsList}
              setOpenDeleteModal={setOpenDeleteModal}
              filterList={filterList}
              approvalStatusOptions={approvalStatusOptions}
            />
          }
          table={{
            list: data, // data
            listTitle: "Vendors", //title
            createBtnTitle: "New Vendor", // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              { accessor: "logo", Header: "Logo", sort: true },
              {
                accessor: "localized_data.en.name",
                Header: "Vendor Name(EN)",
                sort: true,
              },
              {
                accessor: "localized_data.ar.name",
                Header: "Vendor Name(AR)",
                sort: true,
              },
              // { accessor: "Created at", Header: "Wrap Name(AR)", sort: true },
              { accessor: "details.email", Header: "Email", sort: true },
              { accessor: "tags", Header: "Tags", sort: true },
              { accessor: "city", Header: "City", sort: true },

              { accessor: "status", Header: "Approval Status", sort: true },
              {
                accessor: "profile_status",
                Header: "Profile Status",
                sort: true,
              },

              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search by vendor name",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className:
              "organization-table system-configurations-table main-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id
              ? {}
              : initialValues(details, tags, supportedLang, status),
            structure: () => structure(options),

            validationSchema: (_) => validationSchema(formikValues, id),
            deleteFun: deleteFun,
            title: id ? "Edit Vendor" : "Add Vendor",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
            formChildren,
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>

      <ConfirmationModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        handleConfirmation={handleProfileStatus}
        confirmBtnTitle={status.display}
        text={[
          `${status.display} vendor `,
          `Are you sure that you want to ${status.display.toLowerCase()} this account ?`,
        ]}
        color={
          status.display === "Suspend" || status.display === "Delete"
            ? "red"
            : confirmationGreen
        }
        btnColor={
          status.display === "Approve" || status.display === "Active"
            ? confirmationGreen
            : "red"
        }
      ></ConfirmationModal>
      <RejectionModel
        handleRejectionSubmit={handleRejectionSubmit}
        openRejectionReason={openEditReason}
        setOpenRejectionReason={setEditReason}
        setRejectionReason={setRejectionReason}
      ></RejectionModel>
    </>
  );
};

export default VendorTemplate;
