/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  products: [],
  product: {},
  variants: [],
  variant: {},
  branches: [],
  filterBranches: [],
  listCount: 0,
  reviews: [],
  count: 0,
  load: null,
  variants_count: 0,
  option_values: {},
  option_values_loading: null,
  variants_loading: null,
};

export default function productsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.PRODUCTS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: payload?.data?.products,
        count: payload?.extra?.total_count,
      };
    }
    case Types.UPDATE_PRODUCT_SUCCESS: {
      let products = [];
      let updatedProduct = payload?.data?.product;

      if (updatedProduct?.status === "deleted") {
        products = state?.products.filter((product) => product.id !== updatedProduct?.id);
      } else {
        products = state?.products.map((product) => {
          if (product.id === updatedProduct?.id) {
            return { ...product, approval_status: updatedProduct?.status };
          }
          return product;
        });
      }
      console.log(products, "products");
      return {
        ...state,
        products,
        product: updatedProduct,
      };
    }
    case Types.GET_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        product: payload?.data?.product,
      };
    }
    case Types.CLEAR_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        product: {},
      };
    }
    case Types.GET_PRODUCT_VARIANTS_SUCCESS: {
      const variants = payload?.data?.variants.filter((variant) => !variant?.is_master);
      return {
        ...state,
        variants,
        variants_count: payload?.extra?.total_count,
      };
    }
    case Types.PRODUCT_VARIANTS_LOADING: {
      return {
        ...state,
        variants_loading: payload,
      };
    }
    case Types.GET_VARIANT_DETAILS_SUCCESS: {
      return {
        ...state,
        variant: payload?.data?.variant,
      };
    }
    case Types.GET_VARIANT_BRANCHES_SUCCESS: {
      return {
        ...state,
        branches: payload?.data?.cities_stores?.map((branch) => branch?.google_city),
      };
    }
    case Types.CREATE_VARIANT_SUCCESS: {
      //UPDATE THE EXISTING VARIANTS WITH THE NEW RESPONSE
      return {
        ...state,
        variants: [...state.variants, payload?.data?.variant],
      };
    }
    case Types.UPDATE_VARIANT_SUCCESS: {
      //UPDATE THE EXISTING VARIANTS WITH THE NEW RESPONSE
      return {
        ...state,
        variants: state?.variants?.map((variant) => {
          if (variant?.id === payload?.data?.variant?.id) {
            return payload?.data?.variant;
          }
          return variant;
        }),
      };
    }
    case Types.UPDATE_VARIANT_LOCATION_SUCCESS: {
      const variantId = action?.data?.variant_id;
      const updatedLocation = payload?.data?.city_variant;
      const updatedVariants = state?.variants.map((variant) => {
        if (variant.id == variantId) {
          let updatedLocations = variant?.city_variant?.map((city) => {
            if (updatedLocation?.id == city?.id) {
              return updatedLocation;
            } else {
              return city;
            }
          });
          return { ...variant, city_variant: updatedLocations };
        }
        return variant;
      });
      return {
        ...state,
        variants: updatedVariants,
      };
    }
    case Types.CREATE_VARIANT_LOCATION_SUCCESS: {
      const variantId = action?.data?.body?.google_cities_variant?.variant_id;
      const newLocation = payload?.data?.city_variant;
      const updatedVariants = state?.variants.map((variant) => {
        if (variant.id == variantId) {
          return { ...variant, city_variant: [...variant?.city_variant, newLocation] };
        }
        return variant;
      });
      return {
        ...state,
        variants: updatedVariants,
      };
    }
    case Types.DELETE_VARIANT_LOCATION_SUCCESS: {
      const variantId = action?.data?.variant_id;
      const locationToDelete = payload?.data?.current_city_store?.id;

      const updatedVariants = state?.variants.map((variant) => {
        if (variant.id == variantId) {
          const newLocations = variant?.city_variant.filter((city) => city.id !== locationToDelete);
          return { ...variant, city_variant: newLocations };
        }
        return variant;
      });

      return {
        ...state,
        variants: updatedVariants,
      };
    }
    case Types.CREATE_VARIANT_LOCATION_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.DELETE_PRODUCT_VARIANT_SUCCESS: {
      return {
        ...state,
        variants: state.variants.filter((v) => v.id !== payload?.data?.variant.id),
        count: state.count - 1,
      };
    }
    case Types.GET_CUSTOMERS_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: payload?.data?.reviews,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_SCROLL_BRANCHES_SUCCESS: {
      return {
        ...state,
        filterBranches:
          payload?.extra?.page_number == 1
            ? payload?.data?.cities_stores?.map((branch) => branch?.google_city)
            : [...state.filterBranches, ...payload?.data?.cities_stores?.map((branch) => branch?.google_city)],
        listCount: payload?.extra?.total_count,
      };
    }
    case Types.GET_OPTION_VALUES_SUCCESS: {
      const optionTypeId = payload?.data?.option_values[0]?.option_type?.id;
      let option_values = {};

      if (optionTypeId) {
        if (payload?.extra?.page_number == 1) {
          option_values = { [optionTypeId]: { list: payload?.data?.option_values, count: payload?.extra?.total_count } };
        } else {
          // keep old data on scroll
          option_values = {
            ...state.option_values,
            [optionTypeId]: {
              list: [...state.option_values?.[optionTypeId]?.list, ...payload?.data?.option_values],
            },
          };
        }
      }

      return {
        ...state,
        option_values: { ...state.option_values, ...option_values },
      };
    }
    case Types.GET_OPTION_VALUES_LOADING: {
      return {
        ...state,
        option_values_loading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
