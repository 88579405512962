const shapesTypes = {
  // WRAPPERS
  // list
  GET_SHAPES_SUCCESS: "GET_SHAPES_SUCCESS",
  SHAPES_LOADING: "GET_SHAPES_LOADING",
  //details
  GET_SHAPES_DETAILS_SUCCESS: "GET_SHAPES_DETAILS_SUCCESS",
  // create
  ADD_SHAPES_SUCCESS: "ADD_SHAPES_SUCCESS",
  ADD_SHAPES_FAILED: "ADD_SHAPES_FAILED",
  // Delete
  DELETE_SHAPES_SUCCESS: "DELETE_SHAPES_SUCCESS",
  DELETE_SHAPES_FAILED: "DELETE_SHAPES_FAILED",
  // update
  UPDATE_SHAPES_SUCCESS: "UPDATE_SHAPES_SUCCESS",
  UPDATE_SHAPES_FAILED: "UPDATE_SHAPES_FAILED",
  GET_SCROLL_SHAPES_SUCCESS: "GET_SCROLL_SHAPES_SUCCESS",

};

export default shapesTypes;
