import { makeFilterString } from "../../utils/Helper";


import { reduxRequest } from "common/utils/reduxRequest";
import {
    AddSuggestedWrapSuccess,
    DeleteSuggestedWrapsSuccess, GetSuggestedWrapDetailsSuccess,
    GetSuggestedWrapsLoading,
    GetSuggestedWrapsSuccess, UpdateSuggestedWrapsSuccess
} from "./Action";
import {GetShapeLoading, GetShapesSuccess} from "../shapes/Action";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const getSuggestedWrapsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetSuggestedWrapsLoading,
        loadingType: "list",
        successFunction: GetSuggestedWrapsSuccess,
        url: `/admin/wrappers/suggested_wrappers${makeFilterString(variables?.params)}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };

};


export const deleteSuggestedWrapRequest = ({ ...variables }) => {
    console.log(variables,'variables')
    const requestData = {
        isLoading: GetSuggestedWrapsLoading,
        successFunction: DeleteSuggestedWrapsSuccess,
        loadingType: "delete wrap",
        action: variables?.action,
        url: `/admin/wrappers/suggested_wrappers/${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};



export const addSuggestedWrapRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetSuggestedWrapsLoading,
        loadingType: "add",
        successFunction: AddSuggestedWrapSuccess,
        action: variables?.action,
        url: `/admin/wrappers/suggested_wrappers`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const updateSuggestedWrapRequest = ({ ...variables }) => {
    console.log(variables, 'variables');
    const requestData = {
        isLoading: GetSuggestedWrapsLoading,
        loadingType: "update wrap",
        successFunction: UpdateSuggestedWrapsSuccess,
        url: `/admin/wrappers/suggested_wrappers/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const getSuggestedWrapDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetSuggestedWrapsLoading,
        loadingType: "details",
        successFunction: GetSuggestedWrapDetailsSuccess,
        url: `/admin/wrappers/suggested_wrappers/${variables?.id}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};




