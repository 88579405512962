const colorsTypes = {
  // WRAPPERS
  // list
  GET_COLORS_SUCCESS: "GET_COLORS_SUCCESS",
  COLORS_LOADING: "COLORS_LOADING",
  //details
  GET_COLOR_DETAILS_SUCCESS: "GET_COLOR_DETAILS_SUCCESS",
  // create
  ADD_COLOR_SUCCESS: "ADD_COLOR_SUCCESS",
  // Delete
  DELETE_COLOR_SUCCESS: "DELETE_COLOR_SUCCESS",
  DELETE_COLOR_FAILED: "DELETE_COLOR_FAILED",
  // update
  UPDATE_COLOR_SUCCESS: "UPDATE_COLOR_SUCCESS",
  UPDATE_COLOR_FAILED: "UPDATE_COLOR_FAILED",
  GET_SCROLL_COLORS_SUCCESS: "GET_SCROLL_COLORS_SUCCESS",
};

export default colorsTypes;
