import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Footer from "./footer";
import Header from "./header";
import DataFiltration from "./dataFiltration";

import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EmptyList from "./EmptyList";
import {
  StickyClass,
  StyledTableRow,
  TableCellStyle,
  TableContainerStyle,
} from "./style";
const CTable = ({
  Data,
  Columns,
  Title,
  emptyListMsg,
  searchAttribute,
  isSearch,
  setFilterObj,
  selectedData,
  currentpage,
  totalPage,
  headerChildren,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  filterList,
  extraFilters,
  handleApplyFilter,
  className,
  total_count,
  paginationPage,
  searchInputPlaceholder,
  handlePageChange,
  isDetails=true
}) => {
  const navigate = useNavigate();
  const data = useMemo(
    () =>
      Data &&
      Data?.map((value, i) => {
        return {
          ...value,
        };
      }),
    [Data]
  );

  const columns = useMemo(
    () =>
      Columns?.map((column) => {
        return {
          Header: column.Header,
          accessor: column.accessor,
          sort: column?.sort,
          clickable: column?.clickable,
        };
      }),
    [Columns]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        useControlledState: (state) => {
          return React.useMemo(
            () => ({
              ...state,
              pageIndex: currentpage,
            }),
            [state, currentpage]
          );
        },
        initialState: { pageIndex: currentpage },
        manualPagination: true,
        pageCount: totalPage,
        autoResetHiddenColumns: false,
        autoResetSortBy: false,
        autoResetPage: false,
      },

      useGlobalFilter,
      useSortBy,
      usePagination
    );

  return (
    <Stack gap={3}>
      <Header
        Title={Title}
        isSearch={isSearch}
        setFilterObj={setFilterObj}
        searchAttribute={searchAttribute}
        createBtnTitle={createBtnTitle}
        createBtnFun={createBtnFun}
        CreateBtnIcon={CreateBtnIcon}
        handlePageChange={handlePageChange}
        searchInputPlaceholder={searchInputPlaceholder}
        children={headerChildren}
        filterList={extraFilters}
        handleApplyFilter={handleApplyFilter}
      />
      <DataFiltration
        filterList={filterList}
        handlePageChange={handlePageChange}
      />

      {Data.length == 0 ? (
        <EmptyList emptyListMsg={emptyListMsg} />
      ) : (
        <>
          <TableContainer
            component={Paper}
            className={className}
            sx={TableContainerStyle}
          >
            <Table
              {...getTableProps()}
              bg="white"
              mb="6"
              size="small"
              aria-label="simple table"
            >
              <TableHead bg="#efeeee" color="#645f65">
                {headerGroups.map((headerGroup, index) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <TableCell
                          key={index}
                          bg="inherit"
                          {...column.getHeaderProps(
                            column.Header === "" || !column.sort
                              ? ""
                              : column.getSortByToggleProps()
                          )}
                          sx={
                            index == 0 || index == 1
                              ? StickyClass(index, {
                                  py: 2,
                                  minWidth: index == 0 ? "50px" : "200px",
                                })
                              : {
                                  py: 2,
                                  minWidth: "170px",
                                }
                          }
                        >
                          <Stack direction="row" alignItems={"center"}>
                            <>
                              <Typography
                                fontWeight="500"
                                fontSize="16px"
                                color={index === 0 ? "#1A1A1A" : "#9F9F9F"}
                              >
                                {column.render("Header")}
                              </Typography>
                              {column.Header === "" ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    paddingLeft: "10px",
                                    margin: "-1px",
                                  }}
                                  display="inline-block"
                                >
                                  {column.sort &&
                                    (column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <KeyboardArrowDownIcon
                                          sx={{ color: "#9F9F9F" }}
                                        />
                                      ) : (
                                        <KeyboardArrowUpIcon
                                          sx={{ color: "#9F9F9F" }}
                                        />
                                      )
                                    ) : (
                                      <UnfoldMoreIcon
                                        sx={{ color: "#9F9F9F" }}
                                      />
                                    ))}
                                </span>
                              )}
                            </>
                          </Stack>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()} color="black">
                {page?.map((row, index) => {
                  prepareRow(row);
                  // console.log(row.original);
                  return (
                    <StyledTableRow
                      key={index}
                      {...row.getRowProps()}
                      onClick={() => {
                        // console.log(row);
                        selectedData(row.original);
                      }}
                    >
                      {row.cells.map((cell, index) => {
                        // console.log("cell", cell?.row?.original?.id?.props?.onClick);
                        return (
                          <TableCell
                            key={index}
                            style={
                              index == 0 || index == 1 ? StickyClass(index) : {}
                            }
                            sx={TableCellStyle(index, columns, isDetails)}
                            onClick={() => {
                              // console.log(cell);
                              if (index == 0) {
                                isDetails &&
                                  navigate(
                                    `?type=details&id=${row?.original?.id}`
                                  );
                              }
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Footer
            totalPage={totalPage}
            total_count={total_count}
            paginationPage={paginationPage}
            handlePageChange={handlePageChange}
          />
        </>
      )}
    </Stack>
  );
};

export default CTable;
