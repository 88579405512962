/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  betweenZonesFees: [],
  betweenZonesFee: {},
  count: 0,
  load: null,
};

export function feesBetweenZonesReudcer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.FEES_BETWEEN_ZONES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_BETWEEN_ZONES_FEES_SUCCESS: {
      return {
        ...state,
        betweenZonesFees: payload?.data?.between_zones_fees,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_BETWEEN_ZONES_FEE_DETAILS_SUCCESS: {
      return {
        ...state,
        betweenZonesFee: payload?.data?.between_zones_fee,
      };
    }
    default: {
      return state;
    }
  }
}
