import Types from "./Types";

const INIT_STATE = {
    productsOptionTypes: [],
    filterProductsOptionTypes: [],
    productOptionType: {},
    listCount: 0,
    count: 0,
    load: null,
    error: null,
};
export function productOptionTypesReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.PRODUCT_OPTION_TYPES_LOADING: {
            return {
                ...state,
                load: payload
            }
        }
        case Types.GET_PRODUCTS_OPTION_TYPES_SUCCESS: {
            return {
                ...state,
                productsOptionTypes: payload?.data?.option_types,
                count: payload?.extra?.total_count,

            }
        }
        case Types.GET_PRODUCTS_OPTION_TYPES_DETAILS_SUCCESS: {
            return {
                ...state,
                productOptionType: payload?.data?.option_type
            }
        }
        case Types.SET_PRODUCT_OPTION_TYPES_SUCCESS: {
            return {
                ...state
            }
        }
        case Types.UPDATE_PRODUCTS_OPTION_TYPES_SUCCESS: {
            return {
                ...state
            }
        }
        case Types.DELETE_PRODUCTS_OPTION_TYPES_SUCCESS: {
            return {
                ...state
            }
        }
        case Types.GET_SCROLL_PRODUCTS_OPTION_TYPES_SUCCESS: {
            return {
                ...state,
                filterProductsOptionTypes: payload?.extra?.page_number == 1
                    ? payload?.data?.option_types
                    : [...state.filterProductsOptionTypes, ...payload?.data?.option_types],
                listCount: payload?.extra?.total_count,
            };
        }
        default:
            return state
    }
}