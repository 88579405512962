import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import setAuthToken from "services/modules/utils/handel_api";

import { updateAdminUserRequest } from "../../services/modules/users";
import AdminProfileTemplate from "../../templates/admin-profile";
import { UpdateUserProfileSuccess } from "services/modules/authenticate/Actions";

function AdminProfilePage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams, setSearchParam] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const details = useSelector((state) => state.authenticate.currentUserProfile);
  const load = useSelector((state) => state.users.load);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [filterObj, setFilterObj] = useState(null);

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = {};

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/admin-profile?type=details",
      title: "Profile",
    },
    type && {
      active: false,
      title: type === "form" && "Edit Profile",
    },
  ];
  const handelSubmit = (data, formik) => {
    const action = (data) => {
      // searchParams.delete("id");
      // searchParams.delete("type");
      navigate(`/admin-profile?type=details&id=${id}`);
      dispatch(UpdateUserProfileSuccess(data?.data?.user));
      !id && formik?.resetForm();
      toast.success(`Changes have been saved successfully`);
    };

    const requestData = {
      body: {
        user: {
          ...formik?.values,
          avatar: formik?.values?.image,
          role_id: formik?.values?.role?.id,
        },
      },
      action,
    };
    dispatch(updateAdminUserRequest({ ...requestData, id }));
  };

  const filterList = [];
  const handleDelete = (id) => {};
  const actionsList = ({ index, data }) => {};
  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];
  useEffect(() => {
    // Check if the current path is 'admin-profile' without any query parameters
    if (location.pathname === "/admin-profile" && !location.search) {
      // If it is, navigate to 'admin-profile?type=details'
      navigate(`/admin-profile?type=details&id=${details?.id}`);
    }
  }, [location.pathname, location.search, navigate]);

  console.log(load, "DATADATA");
  return (
    <>
      <Loader open={load?.loading} />
      <AdminProfileTemplate
        data={mappedData}
        paginationPage={page}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        handelSubmit={handelSubmit}
        headerChildren={headerChildren}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        selectedRow={selectedRow}
        setFilterObj={setFilterObj}
      />
    </>
  );
}

export default AdminProfilePage;
