import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { detailsData } from "./details";
import { useEffect } from "react";

const BannersTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  selectedRow,
  can_create,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");

  const [isClickable, setIsClickable] = useState(false);

  useEffect(() => {
    setIsClickable(selectedRow?.formikData?.values?.need_action);
  }, [selectedRow?.formikData?.values?.need_action]);
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Featured Ads details",
            data: {
              images: [
                { id: 0, img: details?.localized_data?.en?.banner },
                { id: 1, img: details?.localized_data?.ar?.banner },
              ],
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data,
            listTitle: "Featured Ads List",
            createBtnTitle: can_create && "New Featured Ad",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "featuredAdEn",
                Header: "Featured Ad(EN)",
              },
              {
                accessor: "featuredAdAr",
                Header: "Featured Ad(AR)",
              },
              { accessor: "data", Header: "Data", sort: true },
              {
                accessor: "data_id",
                Header: "Data Id",
              },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id
              ? { is_visible: false, need_action: false }
              : initialValues(details, supportedLang),
            structure: () => structure(supportedLang, isClickable),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit Featured Ad" : "Add Featured Ad",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default BannersTemplate;
