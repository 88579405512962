import { Stack, TextField, Input } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import { useThemePalette } from "common/hooks/theme_palette";

const PositiveNumberInput = React.forwardRef((props, ref) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    // Only allow positive numbers and disallow "-"
    if (
      inputValue === "" ||
      ( !inputValue.includes("-"))
    ) {
      props.onChange(event);
    }
  };

  return <Input {...props} ref={ref} onChange={handleChange} />;
});

const CInput = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  ...rest
}) => {
  const { inputBg } = useThemePalette();
  const multinames = name?.split(".")?.map((data) => data);
  const value =
    multinames?.length === 2
      ? formik?.values?.[multinames[0]]?.[multinames[1]]
        ? formik?.values?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik?.values[name];
  const errorsMsg =
    multinames?.length === 2
      ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik.errors[name];

  return (
    <Stack mb='5' position='relative'>

      <TextFieldInput
        InputProps={{
          inputComponent:type=="number" && PositiveNumberInput,
        }}
        id={name}
        type={type}
        label={label}
        aria-autocomplete="none"
        autoComplete={false}
        inputProps={{
          autoComplete: "new-password",
          form: {
            autocomplete: "off",
          },
        }}
        bgColor={inputBg}
        labelStyle={{
          color: "#565656",
          fontSize: "18px",
          fontFamily: "Jost",
          mb: 2,
        }}
        placeholder={placeholder}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
            },
            "&:hover fieldset": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        }}
        onChange={formik?.handleChange}
        value={value || ""}
        onBlur={formik?.handleBlur}
        name={name}
        {...rest}
      />

      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CInput;
