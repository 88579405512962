import { Routes, Route, useSearchParams, useParams } from "react-router-dom";
import Layout from "./layout";
import React, { useEffect, useState } from "react";
import ForgetPasswordPage from "pages/auth/forgetPassword";
import VerifyOTPPage from "pages/auth/verifyOTP";
import ResetPasswordPage from "pages/auth/resetPassword";
import ResetPasswordSuccessPage from "pages/auth/resetPasswordSuccess";
import LoginPage from "pages/auth/login";
import AdminUsersPage from "pages/users/admin-users/AdminUsers";
import AuthLayout from "components/Organisms/login/layout/AuthLayout";
import TagPage from "pages/look-ups/Tag";
import RolesPage from "pages/roles/Roles";
import { useDispatch, useSelector } from "react-redux";
import { getSystemConfigRequest } from "services/modules/systemConfig";
import OccasionsTypes from "pages/occasions/occasionsTypes";
import ChangePasswordPage from "pages/users/admin-users/ChangePassword";
import CitiesPage from "pages/cities/Cities";
import ZonesPage from "pages/zones/Zones";
import BetweenZonesFeesPage from "pages/between-zones-fees/BetweenZonesFees";
import GiftCardsPage from "pages/gift-cards/GiftCards";
import BannersPage from "pages/look-ups/Banners";
import OrderDeclinationPage from "../pages/look-ups/OrderDeclinationReasons";
import SystemConfigurationPage from "pages/look-ups/SystemConfiguration";

import Wraps from "pages/wrappers/wraps";
import Shapes from "pages/wrappers/shapes";
import Colors from "pages/wrappers/colors";
import WrapsVariants from "pages/wrappers/wrapsVariant";
import RibbonColor from "pages/wrappers/ribbonColor";
import WrapsExtras from "pages/wrappers/wrapsExtras";
import { useNavigate } from "react-router";
import UsersOccasionsPage from "../pages/occasions/usersOccasions";
import OrdersPage from "pages/orders/orders.page";
import SuggestedWraps from "../pages/wrappers/suggestedWraps";
import ProductsOptionTypesPage from "../pages/look-ups/ProductsOptionTypes";
import ProductsOptionValuesPage from "../pages/look-ups/ProductsOptionValues";
import CustomerComplaintsPage from "../pages/complaints/customerComplaints";
import DriverComplaintsPage from "../pages/complaints/driverComplaints";
import DeliverTimesPage from "../pages/look-ups/DeliveryTimes";
import CategoriesPage from "pages/look-ups/categories";
import Vendor from "../pages/vendor/vendor";
import VendorRegistration from "../pages/vendor/vendorRegistration";
import CustomersPage from "pages/users/customers/customers";
import ProductsPage from "pages/products/Products";
import EmployeePage from "pages/users/drivers/employee/employee";
import ChangeUserPasswordPage from "pages/users/drivers/employee/changeUserPasswordPage";
import FreeLancerPage from "pages/users/drivers/freelancer/freelancer";
import EditProductPage from "pages/products/editProduct";
import CustomerComplaintsReasonsPage from "../pages/look-ups/complaints-reasons/CustomerComplaintsReasons";
import DriverComplaintsReasonsPage from "../pages/look-ups/complaints-reasons/DriverComplaintsReasons";
import EditVendorPage from "../pages/vendor/editVendor";
import MessengerPage from "pages/chat/messenger";
import { getUserProfileRequest } from "services/modules/authenticate";
// import {GetUserProfileSuccess} from "../services/modules/authenticate";
import { db, onMessageListener, requestForFCMToken } from "services/utils/firebase";
import { SetFCMToken } from "services/modules/notifications/notifications/Action";
import { getUnreadCountRequest } from "services/modules/notifications/notifications";
import PushNotificationsPage from "pages/push-notifications/PushNotifications";
import { onValue, ref } from "firebase/database";
import { StoreUser } from "services/modules/utils/constant";
import CreateReturnRequestPage from "pages/orders/return-order/createReturnRequestPage";
import ReturnRequestsPage from "pages/orders/return-order/returnRequestPage";
import ReturnRequestDetailsPage from "pages/orders/return-order/returnRequestDetailsPage";
import OrderDetailsPage from "pages/orders/ordersDetails.page";
import VendorChangePasswordPage from "pages/vendor/ChangePasswprd";
import AdminProfilePage from "pages/admin-profile/adminProfile";

const AppRoute = () => {
  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector((state) => state.authenticate.currentUserProfile.permissions);
  const isSuperAdmin = currentUser?.privilege == "super_admin";
  const userType = localStorage.getItem("user_type");
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notificationUnseenCount, setNotificationUnseenCount] = useState(0);

  let [searchParams, setSearchParam] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    dispatch(
      getSystemConfigRequest({
        params: {
          page_size: 10,
          page_number: 1,
          keyword: "",
          sort_by: "id",
        },
      })
    );
  }, []);

  useEffect(() => {
    isAuth && dispatch(getUserProfileRequest({}));
  }, [isAuth]);

  useEffect(() => {
    // on page load
    const token = localStorage.getItem("token");
    setIsAuth(Boolean(token));

    // when the storage changes
    const handleStorageListener = () => {
      const token = localStorage.getItem("token");
      setIsAuth(Boolean(token));
    };
    window.addEventListener("storage", handleStorageListener);

    //clean up
    return () => {
      window.removeEventListener("storage", handleStorageListener);
    };
  }, []);

  function ProtectedRoute({ children, isAllowed = false }) {
    if (!localStorage.getItem("token")) {
      // Navigate to the login page when the user is not authenticated
      navigate("/login");
      // Return null or some loading indicator if you prefer
      return null;
    } else {
      if (isSuperAdmin || isAllowed) {
        return children;
      }
    }
  }
  const FCMToken = useSelector((state) => state.notifications.FCMToken);
  const [newFCMToken, setNewFCMToken] = useState(null);

  useEffect(() => {
    requestForFCMToken(setNewFCMToken);
    if (newFCMToken && newFCMToken != FCMToken) {
      // send a request to set the FCMToken
      dispatch(SetFCMToken({ token: newFCMToken }));
    }
  }, [newFCMToken]);

  // useEffect(() => {
  //   onMessageListener()
  //     .then((payload) => {
  //       dispatch(getUnreadCountRequest({}));
  //     })
  //     .catch((err) => console.error("Error receiving notification:", err));
  // }, []);

  useEffect(() => {
    const userNode = localStorage.getItem("firebase_uid");
    const starCountRef = ref(db, `users/${userNode}/notification_count`);
    onValue(starCountRef, (snapshot) => {
      // dispatch(getUnreadCountRequest({}));
      setNotificationUnseenCount(snapshot?.val());
    });
  }, []);
  return (
    <Routes>
      {isAuth ? (
        <Route path="/" element={<Layout notificationUnseenCount={notificationUnseenCount} />}>
          <Route
            path={"admin-profile"}
            element={
              <ProtectedRoute isAllowed={true}>
                <AdminProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"vendors"}
            element={
              <ProtectedRoute isAllowed={permissions?.stores?.can_read || (userType == "Store" && currentUser?.id == id)}>
                <Vendor />
              </ProtectedRoute>
            }
          />
          <Route
            path="vendors/:id"
            element={
              <ProtectedRoute isAllowed={permissions?.stores?.can_read || userType == "Store"}>
                <EditVendorPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="tags"
            element={
              <ProtectedRoute isAllowed={permissions?.tags?.can_read}>
                <TagPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="featured-ads"
            element={
              <ProtectedRoute isAllowed={permissions?.featured_banners?.can_read}>
                <BannersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/delivery-times"
            element={
              <ProtectedRoute isAllowed={permissions?.delivery_times?.can_read}>
                <DeliverTimesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="wraps"
            element={
              <ProtectedRoute isAllowed={permissions?.wraps?.can_read}>
                <Wraps />
              </ProtectedRoute>
            }
          />
          <Route
            path="shapes"
            element={
              <ProtectedRoute isAllowed={permissions?.shapes?.can_read}>
                <Shapes />
              </ProtectedRoute>
            }
          />
          <Route
            path="colors"
            element={
              <ProtectedRoute isAllowed={permissions?.colors?.can_read}>
                <Colors />
              </ProtectedRoute>
            }
          />
          <Route
            path="wrap-variants"
            element={
              <ProtectedRoute isAllowed={permissions?.wrap_variants?.can_read}>
                <WrapsVariants />
              </ProtectedRoute>
            }
          />
          <Route
            path="ribbon-colors"
            element={
              <ProtectedRoute isAllowed={permissions?.ribbon_colors?.can_read}>
                <RibbonColor />
              </ProtectedRoute>
            }
          />
          <Route
            path="wraps-extras"
            element={
              <ProtectedRoute isAllowed={permissions?.wrap_extras?.can_read}>
                <WrapsExtras />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/suggested-wrappers"}
            element={
              <ProtectedRoute isAllowed={permissions?.suggested_wrappers?.can_read}>
                <SuggestedWraps />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="orders"
            element={
              <ProtectedRoute isAllowed={permissions?.orders?.can_read}>
                <OrdersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="order-details"
            element={
              <ProtectedRoute isAllowed={permissions?.orders?.can_read}>
                <OrderDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-return-request"
            element={
              <ProtectedRoute isAllowed={userType != StoreUser}>
                <CreateReturnRequestPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="return-request-details"
            element={
              <ProtectedRoute isAllowed={userType != StoreUser}>
                <ReturnRequestDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"return-request"}
            element={
              <ProtectedRoute>
                <ReturnRequestsPage isAllowed={permissions?.return_requests?.can_read} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories"
            element={
              <ProtectedRoute isAllowed={permissions?.categories?.can_read}>
                <CategoriesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/occasions"
            element={
              <ProtectedRoute isAllowed={permissions?.occasions?.can_read}>
                <UsersOccasionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="occasions-types"
            element={
              <ProtectedRoute isAllowed={permissions?.occasion_types?.can_read}>
                <OccasionsTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path={"products-option-types"}
            element={
              <ProtectedRoute isAllowed={permissions?.option_types?.can_read}>
                <ProductsOptionTypesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="customers"
            element={
              <ProtectedRoute isAllowed={permissions?.customers?.can_read}>
                <CustomersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="messenger"
            element={
              <ProtectedRoute isAllowed={permissions?.chat_rooms?.can_read}>
                <MessengerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="customer-complaints"
            element={
              <ProtectedRoute isAllowed={{}}>
                <CustomerComplaintsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="driver-complaints"
            element={
              <ProtectedRoute isAllowed={{}}>
                <DriverComplaintsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={"products-option-values"}
            element={
              <ProtectedRoute isAllowed={permissions?.option_values?.can_read}>
                <ProductsOptionValuesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="system-configurations"
            element={
              <ProtectedRoute isAllowed={permissions?.system_configurations?.can_read}>
                <SystemConfigurationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin-users"
            element={
              <ProtectedRoute isAllowed={permissions?.admin_users?.can_read}>
                <AdminUsersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin-users/change-password/:id"
            element={
              <ProtectedRoute isAllowed={permissions?.admin_users?.can_update}>
                <ChangePasswordPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/change-password/:id"
            element={
              <ProtectedRoute>
                <ChangeUserPasswordPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vendors/change-password/:id"
            element={
              <ProtectedRoute>
                <VendorChangePasswordPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="customers"
            element={
              <ProtectedRoute isAllowed={permissions?.customers?.can_read}>
                <CustomersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="employee-drivers"
            element={
              <ProtectedRoute isAllowed={permissions?.drivers?.can_read}>
                <EmployeePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="freelancer-drivers"
            element={
              <ProtectedRoute isAllowed={permissions?.drivers?.can_read}>
                <FreeLancerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="admin-users/roles"
            element={
              <ProtectedRoute>
                <RolesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="products"
            element={
              <ProtectedRoute isAllowed={permissions?.products?.can_read}>
                <ProductsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="products/:id"
            element={
              <ProtectedRoute isAllowed={permissions?.products?.can_update}>
                <EditProductPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="gift-cards"
            element={
              <ProtectedRoute isAllowed={permissions?.cart_gift_cards?.can_read}>
                <GiftCardsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="cities"
            element={
              <ProtectedRoute isAllowed={permissions?.google_cities?.can_read}>
                <CitiesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="zones"
            element={
              <ProtectedRoute isAllowed={permissions?.zones?.can_read}>
                <ZonesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="between-zones-fees"
            element={
              <ProtectedRoute isAllowed={permissions?.between_zones_fees?.can_read}>
                <BetweenZonesFeesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="customer-complaint-reasons"
            element={
              <ProtectedRoute isAllowed={permissions?.order_complaints?.can_read}>
                <CustomerComplaintsReasonsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="driver-complaint-reasons"
            element={
              <ProtectedRoute isAllowed={permissions?.route_complaints?.can_read}>
                <DriverComplaintsReasonsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="order-declination-reasons"
            element={
              <ProtectedRoute isAllowed={permissions?.order_declination_reasons?.can_read}>
                <OrderDeclinationPage />
              </ProtectedRoute>
            }
          />
          <Route path="push-notifications" element={<PushNotificationsPage />} />
        </Route>
      ) : (
        <React.Fragment>
          <Route path="/vendor-registration" element={<VendorRegistration />} />
          <Route path="/" element={<AuthLayout />}>
            <Route index element={<LoginPage />} />
            <Route path="forget-password" element={<ForgetPasswordPage />} />
            <Route path="verify-otp" element={<VerifyOTPPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="reset-password-success" element={<ResetPasswordSuccessPage />} />
          </Route>
        </React.Fragment>
      )}
    </Routes>
  );
};

export default AppRoute;
