import { Box, Stack, Typography } from "@mui/material";
import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";

export default function OrderSummary({
  title,
  columns,
  details,
  getReturnRequestItem,
  products,
  wrapper,
  giftCard,
  currency
}) {
  // const products = details?.cart?.cart_items;
  // const wrapper = details?.cart?.wrapper;
  // const giftCard = details?.cart?.gift_card;
  // const currency = details?.cart?.currency?.name;

  const prepareList = () => {
    let list = [];
    products && list?.push(...products);
    // wrapper and giftcard are not a part of the cart items
    // and have different structure
    // !so they have to be manually structured & added
    wrapper &&
      list?.returnable?.push({
        variant: {
          name: wrapper?.title,
          image: wrapper?.wrapper_image,
          price_after_discount: wrapper?.wrap_variant?.price_after_discount,
        },
        total_amount: wrapper?.cost_after_discount,
      });
    giftCard &&
      list?.push({
        variant: {
          name: giftCard?.card?.name,
          image: giftCard?.card?.image,
          price_after_discount: giftCard?.card?.price_after_discount,
        },
        total_amount: giftCard?.card?.price_after_discount,
      });
    return list;
  };

  const mappedData = prepareList()?.map((data, index) => {
    const isWrapper = data?.wrapper;
    const height = "70px";
    return {
      ...data,
      id: (
        <Stack gap={2}>
          {index + 1}
          {isWrapper && <Stack sx={{ height }} />}
        </Stack>
      ),
      product: (
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Box component="img" src={data?.variant?.image} width="50px" />
            <Typography fontWeight="500">{data?.variant?.name}</Typography>
          </Stack>
          {isWrapper && (
            <Stack>
              <Typography sx={{ color: "#9F9F9F" }}>Wrapping</Typography>
              <Stack direction="row" alignItems="center">
                <Box
                  component="img"
                  src={data?.wrapper?.wrapper_image}
                  width="50px"
                />
                <Typography fontWeight="500">{data?.wrapper?.title}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      ),
      sku: (
        <Stack gap={2}>
          <Typography fontWeight="500">{data?.variant?.sku}</Typography>
          {isWrapper && <Stack sx={{ height }}></Stack>}
        </Stack>
      ),
      price: (
        <Stack gap={2}>
          {data?.variant?.price_after_discount} {currency}
          {isWrapper && (
            <Stack sx={{ height }}>
              <Typography sx={{ color: "#9F9F9F" }}>Price</Typography>
              <Typography fontWeight="400">
                {data?.wrapper?.cost_after_discount} {currency}
              </Typography>
            </Stack>
          )}
        </Stack>
      ),
      quantity: (
        <Stack gap={2}>
          {data?.quantity ? data?.quantity : "-"}
          {isWrapper && <Stack sx={{ height }} />}
        </Stack>
      ),
      total_price: (
        <Stack gap={2}>
          {isWrapper
            ? data?.total_amount + data?.wrapper?.cost_after_discount
            : data?.total_amount}{" "}
          {currency}
          {isWrapper && <Stack sx={{ height }} />}
        </Stack>
      ),
    };
  });

  return (
    <Stack sx={{ backgroundColor: "white" }}>
      <Stack width="50%" px={3} pt={3} mb={-3}>
        <Typography fontSize="32px" fontWeight="500" fontFamily="Jost">
          {title}
        </Typography>
      </Stack>

      <TableUse
        list={mappedData}
        columnsData={columns}
        setTableData={() => void 0}
        setFilterObj={() => void 0}
      />
    </Stack>
  );
}
