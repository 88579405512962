/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  wraps: [],
  filterWraps: [],
  listCount:0,
  wrap: {},
  count: 0,
  load: null,
  error: null,
};

export function wrappersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
   

    case Types.GET_WRAPS_SUCCESS: {
      return {
        ...state,
        wraps: payload?.data?.wraps,
        count: payload?.extra?.total_count,
      };
    }
    case Types.WRAPS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_WRAPS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_WRAPS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_WRAPS_FAILED: {
      return {
        ...state,
        error: payload?.response?.data?.message,
      };
    }
    case Types.UPDATE_WRAPS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_WRAPS_DETAILS_SUCCESS: {
      return {
        ...state,
        wrap: payload?.data?.wrap,
      };
    }
    case Types.GET_SCROLL_WRAPS_SUCCESS: {
      return {
        ...state,
        filterWraps: payload?.extra?.page_number == 1
          ? payload?.data?.wraps
          : [...state.filterWraps, ...payload?.data?.wraps],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
