import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AddminUsersTemplate from "templates/users/admin-users";
import { Button } from "@mui/material";
import {
  createAdminUserRequest,
  deleteAdminUserRequest,
  getAdminUserDetailsRequest,
  getAdminUsersRequest,
  getRolesRequest,
  getScrollRolesRequest,
  updateAdminUserRequest,
} from "services/modules/users";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";

function AdminUsersPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.users.adminUsers);
  const count = useSelector((state) => state.users.count);
  const filterRoles = useSelector((state) => state.users.filterRoles);
  const rolesCount = useSelector((state) => state.users.listCount);
  const load = useSelector((state) => state.users.load);
  const details = useSelector((state) => state.users.adminUser);
  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector((state) => state.authenticate.currentUserProfile.permissions.admin_users);
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getAdminUserDetailsRequest({ id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getAdminUsersRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = !type
    ? [
        {
          active: true,
          path: "/",
          title: "Home",
        },
        {
          active: false,
          path: "/admin-users",
          title: "Users/Admin Users",
        },
      ]
    : [
        {
          active: true,
          path: "/",
          title: "Home",
        },
        {
          active: true,
          path: "/admin-users",
          title: "Users/Admin Users",
        },
        {
          active: false,
          title: type === "details" ? "Details" : id ? "Edit User" : "New User",
        },
      ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/admin-users");
      !id && formik?.resetForm();
      toast.success(` ${id ? "User" : "New user"} has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: {
        user: { ...data, privilege: "admin" },

        device: {
          fcm_token: "fcm_token",
          device_type: 1,
        },
      },

      action,
    };

    if (id) {
      const requestData = {
        body: {
          user: {
            ...data,
            privilege: "admin",
          },
          device: {
            fcm_token: "fcm_token",
            device_type: 1,
          },
        },

        action,
      };
      //update call
      dispatch(updateAdminUserRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createAdminUserRequest(requestData));
    }
  };

  const getRoles = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        ...filterObj,
      },
    };
    dispatch(getScrollRolesRequest(requestData));
  };
  const filterList = [
    {
      type: "search",
      placeholder: "Search user name",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
        setFilterObj,
    },
    {
      type: "filter",
      placeholder: "Role",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, role_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterRoles?.length == 0 && getRoles({});
      },
      options: filterRoles && filterRoles,
      getList: getRoles,
      applySearchFromRequest: true,
      count: rolesCount,
      isLoading: load?.loading && load?.type == "filter-roles-list",
    },
  ];

  const handleDelete = (id) => {
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/admin-users");
      toast.success("User has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteAdminUserRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`change-password/${data?.id}`);
        },
        text: "Change password",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = (
    <>
      <Button
        variant="outlined"
        sx={{
          border: "1px solid black",
          color: "black",
          fontSize: "16px",
          "&:hover": {
            backgroundColor: "transparent",
            border: "1px solid black",
          },
        }}
        onClick={() => {
          navigate("/admin-users/roles");
        }}
      >
        View Roles
      </Button>
    </>
  );

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all user
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  // details of a single user
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader
        open={
          (load?.loading && load?.type == "list") ||
          (load?.loading && load?.type == "get user") ||
          (load?.loading && load?.type == "create user")
        }
      />
      <AddminUsersTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        rolesOptions={filterRoles}
        can_create={isSuperAdmin || permissions?.can_create}
        getRoles={getRoles}
        rolesCount={rolesCount}
        load={load}
      />
    </>
  );
}

export default AdminUsersPage;
