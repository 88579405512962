import { Stack, Typography } from "@mui/material";
import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import CActions from "components/Molecules/Crud/components/actions";
import DeleteModal from "components/Molecules/Models/DeleteModal";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useThemePalette } from "common/hooks/theme_palette";
import { getCustomerComplaintsDetailsRequest, updateCustomerComplaintsRequest } from "services/modules/complaints/customerComplaints";
import { isOpenViewComplain } from "services/modules/modal/Actions";
import ViewComplainModal from "components/Organisms/orders/Models/viewComplainModal";
import ComplainsUpdateModel from "components/Molecules/Orders/complains/complainsUpdateModel";
import { toast } from "react-toastify";
import { getDriverComplaintsDetailsRequest, getDriverComplaintsRequest, updateDriverComplaintsRequest } from "services/modules/complaints/driverComplaints";

export default function DriverComplainHistory() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const list = useSelector((state) => state.driverComplaints.driverComplaints);
    const details = useSelector((state) => state.driverComplaints.driverComplaint);
    const count = useSelector((state) => state.driverComplaints.count);
    const { viewComplain } = useSelector((state) => state?.modal);
    const [openResplveModal, setOpenResplveModal] = useState(false);
    const [updateComplaintData, setUpdateComplaintData] = useState({});

    const { oliveGreen, } = useThemePalette()
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [tableData, setTableData] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [page, setPage] = useState(1);
    let [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorRef = useRef(null);
    const open = Boolean(anchorEl);
    const [key, setKey] = useState(0)
    const [filterObj, setFilterObj] = useState(null);

    const columns = [
        { accessor: "id", Header: "#" },
        { accessor: "order_number", Header: "Order Number" },
        {
            accessor: "created_at",
            Header: "Date and time",
            sort: true,
        },
        {
            accessor: "status",
            Header: "Status",
        },
        { accessor: "Actions", Header: "Action" },
    ];

    const actionsList = ({ index, data }) => {
        return [
            {
                condition: () => {
                    return true;
                },
                click: () => {
                    getDetails()
                },
                text: <Typography color={oliveGreen}>View Complaint details</Typography>,
            },
        ];
    };

    const filterList = [
        {
            type: "filter",
            placeholder: "Status",
            onChange: (value) => {
                setFilterObj((s) => {
                    return { ...s, status: value?.value, page_number: 1 };
                });
            },
            options: [
                { id: 'closed', name: "Resolved" },
                { id: 'open', name: "Not resolved" },
            ],
        },
    ];


    const rowData = (data) => {
        setSelectedRow(data);
    };

    const handlePageChange = (page) => {
        setPage(page);
        setFilterObj((prev) => {
            return { ...prev, page_number: page };
        });
    };

    const handleDelete = () => { };

    const handleClick = (event, index, data) => {
        anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Actions = (id, elmIndex, data) => {
        return (
            <CActions
                id={id}
                index={elmIndex}
                data={data}
                handleClick={handleClick}
                handleClose={handleClose}
                anchorRef={anchorRef}
                open={open}
                anchorEl={anchorEl}
                actionsList={actionsList}
            />
        );
    };

    const mappedData = list?.map((data, index) => {
        const isResolved = data?.status == 'closed'
        return {
            ...data,
            status: <Stack
                sx={{
                    p: 0.8,
                    width: 'fit-content',
                    borderRadius: '4px',
                    backgroundColor: isResolved ? "#E1F5E8" : "#FAE3E3",
                }}
                justifyContent='center'
                alignItems='center'
            >
                <Typography
                    sx={{
                        color: isResolved ? "#29B95F" : "#D20000",
                    }}
                >
                    {isResolved ? 'Resolved' : 'Unresolved'}
                </Typography>
            </Stack>,
            created_at: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            Actions: Actions(data?.id, index, data),
        };
    });

    const headerChildren = []

    const getList = async (filterObj) => {
        const requestData = {
            params: {
                page_size: 10,
                page_number: 1,
                keyword: "",
                sort_by: "id",
                driver_id: id,
                ...filterObj
            },
        };
        dispatch(getDriverComplaintsRequest(requestData));
    };

    useEffect(() => {
        if (filterObj) getList(filterObj);
    }, [filterObj]);

    useEffect(() => {
        rowData({ ...tableData });
    }, [tableData]);

    const getDetails = async () => {
        const action = () => {
            dispatch(isOpenViewComplain())
        }
        const requestData = {
            id: selectedRow?.rowData?.id,
            action
        };
        dispatch(getDriverComplaintsDetailsRequest({ ...requestData }));
    };

    const handleUpdateComplains = ({ status }) => {
        const action = () => {
            setKey(key + 1)
            toast.success(
                `Complain ${status == "open" ? "unresolved" : "resolved"} successfully `
            );
        };
        const requestData = {
            id: details?.id,
            body: {
                route_complaint: {
                    status,
                },
            },
            action,
        };
        dispatch(updateDriverComplaintsRequest({ ...requestData }));
    };

    return (
        <Stack sx={{ backgroundColor: "white", p: '20px' }}>
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                handelDeleteRequest={() => {
                    handleDelete(tableData?.rowData?.id && tableData?.rowData?.id);
                }}
                handleCancelDelete={() => setOpenDeleteModal(false)}
                customClass="organizations-delete-modal"
                text={[
                    `Delete variant`,
                    `Are you sure that you want to delete this variant?`,
                ]}
            />
            <TableUse
                list={mappedData}
                columnsData={columns}
                setTableData={setTableData}
                setFilterObj={setFilterObj}
                filterList={filterList}
                headerChildren={headerChildren}
                actionsList={actionsList}
                rowData={rowData}
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                handlePageChange={handlePageChange}
                paginationPage={page}
                total_count={count}
            />
            {viewComplain && details?.id && (
                <ViewComplainModal
                    complaintId={details?.id}
                    setOpenResplveModal={setOpenResplveModal}
                    openResplveModal={openResplveModal}
                    handleUpdateComplains={handleUpdateComplains}
                    setUpdateComplaintData={setUpdateComplaintData}
                    complaint={details}
                    getComplaintDetails={getDriverComplaintsDetailsRequest}
                />
            )}
            <ComplainsUpdateModel
                updateComplaintData={updateComplaintData}
                handleUpdateComplains={handleUpdateComplains}
                openResplveModal={openResplveModal}
                setOpenResplveModal={setOpenResplveModal}
            />
        </Stack>
    )
}
