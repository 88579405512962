const orderDetailsTypes = {
  //details
  RETURN_ORDER_LOADING: "RETURN_ORDER_LOADING",
  GET_RETURN_ORDER_SUCCESS: "GET_RETURN_ORDER_SUCCESS",
  GET_ITEMS_ORDER_SUCCESS: "GET_ITEMS_ORDER_SUCCESS",
  ITEMS_ORDER_LOADING: "ITEMS_ORDER_LOADING",
  GET_EMPLOPYEES_SUCCESS: `GET_EMPLOPYEES_SUCCESS`,
  ASSIGN_TO_EMPLOYEE_SUCCESS: "ASSIGN_TO_EMPLOYEE_SUCCESS",
  SET_CREATE_RETURN_SUCCESS: "SET_CREATE_RETURN_SUCCESS",
  GET_RETURN_REQUEST_SUCCES: "GET_RETURN_REQUEST_SUCCES",
  GET_RETURN_REQUEST_ITEMS_REQUEST: "GET_RETURN_REQUEST_ITEMS_REQUEST",
  UPDATE_RETURN_REQUEST_ITEM_SUCCESS: 'UPDATE_RETURN_REQUEST_ITEM_SUCCESS',
  DELETE_RETURN_REQUEST_SUCCESS: 'DELETE_RETURN_REQUEST_SUCCESS',
  UNASSIGN_RETURN_REQUEST_DRIVER_SUCCESS:"UNASSIGN_RETURN_REQUEST_DRIVER_SUCCESS",
  SET_ORDER_READY_FOR_DELIVERY: 'SET_ORDER_READY_FOR_DELIVERY',
};



export default orderDetailsTypes;
