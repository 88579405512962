import { Stack, Typography } from "@mui/material";
import React from "react";
// import { RiErrorWarningFill } from 'react-icons/ri';

const CFormErrors = ({ errorsMsg }) => {
  return (
    <>
      {errorsMsg ? (
        <Stack mt="2">
          {/* <RiErrorWarningFill fontSize="20px" color='red' /> */}
          <Typography
            as="span"
            color="error"
            size="sm"
            fontWeight="medium"
            ml="2"
            textTransform='capitalize'
          >
            {errorsMsg}
          </Typography>
        </Stack>
      ) : null}
    </>
  );
};
export default CFormErrors;
