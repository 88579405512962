import { Rating, Stack, Typography } from "@mui/material";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import ApprovalStatus from "components/Molecules/Products/approvalStatus";
import OrderStatus from "components/Molecules/Products/orderStatus";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetScrollCategoriesRequest } from "services/modules/lookups/categories";
import { getScrollProductsOptionTypesRequest } from "services/modules/lookups/productOptionTypes";
import { GetScrollOccasionTypesRequest } from "services/modules/occasions/occasionsTypes";
import { createProductRequest, getProductDetailsRequest, getProductsRequest, updateProductRequest } from "services/modules/products";
import { StoreUser } from "services/modules/utils/constant";
import setAuthToken from "services/modules/utils/handel_api";
import { GetScrollVendorsRequest } from "services/modules/vendors";
import ProductsTemplate from "templates/products/productsListing";
import "./products.css";
import { ClearProductDetailsSuccess } from "services/modules/products/Action";

function ProductsPage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const userType = localStorage.getItem("user_type");
  const isStore = userType == StoreUser;

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector((state) => state.authenticate.currentUserProfile.permissions.products);
  const variantPermissions = useSelector((state) => state.authenticate.currentUserProfile.permissions.variants);
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const list = useSelector((state) => state.products.products);
  const count = useSelector((state) => state.products.count);
  const details = useSelector((state) => state.products.product);
  const load = useSelector((state) => state.products.load);

  const occassions = useSelector((state) => state.occasionsTypes.filterOccation);
  const occassionsCount = useSelector((state) => state.occasionsTypes.listCount);
  const occassionsLoading = useSelector((state) => state.occasionsTypes.load);

  const vendors = useSelector((state) => state.vendors.filterVendors);
  const vendorsCount = useSelector((state) => state.vendors.listCount);
  const vendorsLoading = useSelector((state) => state.vendors.load);

  const optionTypes = useSelector((state) => state.productsOptionTypes.filterProductsOptionTypes);
  const optionTypesCount = useSelector((state) => state.productsOptionTypes.listCount);
  const optionTypesLoading = useSelector((state) => state.productsOptionTypes.load);

  const categories = useSelector((state) => state.categories.filterCategories);
  const categoriesCount = useSelector((state) => state.categories.listCount);
  const categoriesLoading = useSelector((state) => state.categories.load);

  const fetchVendors = (filterObj) => {
    if (isStore) return;
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(GetScrollVendorsRequest(requestData));
  };

  const fetchOccassions = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(GetScrollOccasionTypesRequest(requestData));
  };

  const fetchOptionTypes = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(getScrollProductsOptionTypesRequest(requestData));
  };

  const fetchCategories = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(GetScrollCategoriesRequest(requestData));
  };

  const prepareVendorsOptions = () => {
    if (isStore) {
      //to display only the logged in store in the vendors DDL when adding a product
      return [{ id: currentUser?.id, name: currentUser?.name }];
    } else {
      return vendors;
    }
  };

  const options = {
    vendors: {
      options: prepareVendorsOptions(),
      onOpen: vendors?.length <= 1 && fetchVendors,
      isLoading: vendorsLoading?.loading,
      getList: fetchVendors,
      count: vendorsCount,
    },
    occassions: {
      options: occassions,
      onOpen: occassions?.length <= 1 && fetchOccassions,
      isLoading: occassionsLoading?.loading,
      getList: fetchOccassions,
      count: occassionsCount,
    },
    optionTypes: {
      options: optionTypes,
      onOpen: optionTypes?.length <= 1 && fetchOptionTypes,
      isLoading: optionTypesLoading?.loading,
      getList: fetchOptionTypes,
      count: optionTypesCount,
    },
    categories: {
      options: categories,
      onOpen: categories?.length <= 1 && fetchCategories,
      isLoading: categoriesLoading?.loading,
      getList: fetchCategories,
      count: categoriesCount,
    },
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getProductDetailsRequest({ id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getProductsRequest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      nameEN: <Stack width="150px">{data?.localized_data.en.name}</Stack>,
      nameAR: <Stack width="150px">{data?.localized_data.ar.name}</Stack>,
      vendor: <Stack width="150px">{data?.store}</Stack>,
      occasion: <Stack width="200px">{data?.occasion_types}</Stack>,
      category: <Stack width="150px">{data?.category_names}</Stack>,
      price: (
        <Stack width="130px">
          {data?.price} {data?.currency?.name}
        </Stack>
      ),
      price_after_discount: (
        <Stack width="200px">
          {data?.price_after_discount} {data?.currency?.name}
        </Stack>
      ),
      rate: (
        <Stack direction="row" alignItems="center" width="250px">
          <Rating name="read-only" value={data?.avg_rate} readOnly precision={0.5} />
          <Typography>{data?.avg_rate}</Typography>
          <Typography>({data?.reviews_count})</Typography>
        </Stack>
      ),
      status: (
        <Stack width="120px" justifyContent="start" direction="row">
          <OrderStatus data={data} />
        </Stack>
      ),
      approval_status: (
        <Stack width="120px" justifyContent="start" direction="row">
          <ApprovalStatus data={data} />
        </Stack>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/products",
      title: "Products",
    },
    type && {
      active: false,
      title: type === "details" ? "Product details" : id ? "Edit Product" : "Add Product",
    },
  ];
  const prepareAddProductData = (data) => {
    const genders = {
      1: "male",
      2: "female",
      3: "any",
    };

    const master_attributes = {
      media_attributes: data?.images
        ?.map((url, i) => {
          return {
            media_type: "photo",
            url,
            file_name: `image ${i}`,
          };
        })
        .filter((img) => img?.url),
    };
    const option_type_ids = data?.option_types?.map((option) => option?.value);
    const occasion_type_ids = data?.occasion_types?.map((option) => option?.value);
    const category_ids = data?.categories?.map((option) => option?.value);

    return {
      ...data,
      master_attributes,
      option_type_ids,
      occasion_type_ids,
      category_ids,
      target_gender: genders[data?.target_gender],
      thumbnail: data?.thumbnail,
    };
  };

  const handelSubmit = (data, formik) => {
    const addProductData = {
      ...prepareAddProductData(data),
    };

    const action = (data) => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate(`/products/${data?.data?.product?.id}?type=edit`);
      !id && formik?.resetForm();
      //try to clear details here
      dispatch(ClearProductDetailsSuccess());
      toast.success("product has been successfully added You can now proceed to add variant");
    };

    const requestData = {
      body: {
        product: {
          ...addProductData,
        },
      },
      action,
    };

    dispatch(createProductRequest(requestData));
  };

  const apporval_statuses = {
    Approved: "available",
    Pending: "unverified",
    Deleted: "deleted",
    Rejected: "rejected",
  };

  const filterList = [
    !isStore && {
      placeholder: "Vendor",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, store_id: value?.value, page_number: 1 };
        });
      },
      options: vendors && vendors,
      getList: fetchVendors,
      applySearchFromRequest: true,
      count: vendorsCount,
      isLoading: vendorsLoading?.loading,
      onMenuOpen: () => {
        vendors?.length <= 1 && fetchVendors({});
      },
    },
    {
      type: "filter",
      placeholder: "Occasions",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, occasion_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        occassions?.length == 0 && fetchOccassions({});
      },
      options: occassions && occassions,
      getList: fetchOccassions,
      applySearchFromRequest: true,
      count: occassionsCount,
      isLoading: occassionsLoading?.loading,
    },
    {
      type: "filter",
      placeholder: "Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value, page_number: 1 };
        });
      },
      options: [
        { id: true, name: "Active" },
        { id: false, name: "Disabled" },
      ],
    },
    {
      type: "filter",
      placeholder: "Approval status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, approval_status: apporval_statuses[value?.label], page_number: 1 };
        });
      },
      options: [
        { id: 1, name: "Approved" },
        { id: 2, name: "Deleted" },
        { id: 3, name: "Rejected" },
        { id: 4, name: "Pending" },
      ],
    },
    {
      type: "date",
      placeholder: "",

      onChange: (value) => {
        //if no value
        if (value != "01-01-1970") {
          setFilterObj((s) => {
            return { ...s, date: value };
          });
        } else {
          setFilterObj((s) => {
            return { ...s, date: "" };
          });
        }
      },
      sx: {
        backgroundColor: "white",
      },
    },
  ].filter((item) => item);

  const handleDelete = () => {
    const id = selectedRow?.rowData?.id;
    const action = () => {
      toast.success("Product Deleted Successfully");
    };
    const requestData = {
      body: {
        status: "deleted",
      },
      id,
      action,
    };
    dispatch(updateProductRequest(requestData));
    setOpenDeleteModal(false);
  };

  const handleProductApprove = (id) => {
    const action = () => {
      toast.success("Product Approved Successfully");
    };
    const requestData = {
      body: {
        status: "available",
      },
      id,
      action,
    };
    dispatch(updateProductRequest(requestData));
  };

  const handleProductReject = (id) => {
    const action = () => {
      toast.success("Product Rejected Successfully");
    };
    const requestData = {
      body: {
        status: "rejected",
      },
      id,
      action,
    };
    dispatch(updateProductRequest(requestData));
  };


  const actionsList = ({ index, data }) => {
    const status = data?.approval_status?.props?.children?.props?.data?.approval_status;
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View Product",
      },
      {
        condition: () => {
          return status == "available" && (variantPermissions?.can_read || isSuperAdmin);
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}&tab=1`);
        },
        text: "View Variants",
      },
      {
        condition: () => {
          return (status == "unverified" || status == "rejected") && (permissions?.can_update || isSuperAdmin) && !isStore;
        },
        click: () => {
          handleProductApprove(data?.id);
        },
        text: "Approve",
      },
      {
        condition: () => {
          return (status == "unverified" || status == "available") && (permissions?.can_update || isSuperAdmin) && !isStore;
        },
        click: () => {
          handleProductReject(data?.id);
        },
        text: "Reject",
      },
      {
        condition: () => {
          return status == "available" && (permissions?.can_update || isSuperAdmin);
        },
        click: () => {
          navigate(`/products/${data?.id}?type=edit`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return (status == "available" || status == "rejected") && (permissions?.can_delete || isSuperAdmin);
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const headerChildren = <></>;

  const modalComponents = [];

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader
        open={(load?.loading && load?.type == "list") || (load?.loading && load?.type == "create") || (load?.loading && load?.type == "update")}
      />
      <ProductsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        can_create={isSuperAdmin || permissions?.can_create}
        isStore={isStore}
        options={options}
      />
    </>
  );
}

export default ProductsPage;
