import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import { toast } from "react-toastify";
import SuggestedWrapsTemplate from "../../templates/wrappers/suggestedWraps";
import {
  addSuggestedWrapRequest,
  deleteSuggestedWrapRequest,
  getSuggestedWrapDetailsRequest,
  getSuggestedWrapsRequest,
  updateSuggestedWrapRequest,
} from "../../services/modules/Wrappers/suggestedWraps";
import { GetWrapsRequest, getScrollWrapsRequest } from "../../services/modules/Wrappers/Wraps";
import { GetWrapVarintsRequest, getScrollWrapVarintRequest } from "../../services/modules/Wrappers/wrapVariants";
import { GetWrapExtrasRequest, getScrollWrapExtraRequest } from "../../services/modules/Wrappers/wrapExtras";
import { GetRibbonColorsRequest, getScrollRibbonColorRequest } from "../../services/modules/Wrappers/ribbonColors";
import { GetScrollColorsRequest } from "services/modules/Wrappers/colors";

function SuggestedWraps() {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.suggestedWraps.suggestedWraps);
  const count = useSelector((state) => state.suggestedWraps.count);
  const details = useSelector((state) => state.suggestedWraps.suggestedWrap);
  const load = useSelector((state) => state.suggestedWraps.load);

  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.suggested_wrappers
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);


  const filterWraps = useSelector((state) => state.wrappers.filterWraps);
  const wrapsLoad = useSelector((state) => state.wrappers.load);
  const wrappersCount = useSelector((state) => state.wrappers.listCount);

  const filterWrapExtra = useSelector((state) => state.wrapsExtras.filterWrapExtra);
  const wrapExtraCount = useSelector((state) => state.wrapsExtras.listCount);
  const wrapExtraLoading = useSelector((state) => state.wrapsExtras.load);

  const filterRibbonColor = useSelector((state) => state.ribbonColors.filterRibbonColor);
  const ribbonColorCount = useSelector((state) => state.ribbonColors.listCount);
  const ribbonColorLoading = useSelector((state) => state.ribbonColors.load);

  const filterWrapVariant = useSelector((state) => state.wrapVariant.filterWrapVariant);
  const wrapVariantCount = useSelector((state) => state.wrapVariant.listCount);
  const wrapVariantLoading = useSelector((state) => state.wrapVariant.load);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getSuggestedWrapDetailsRequest({ id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getSuggestedWrapsRequest(requestData));
  };
  const getWraps = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollWrapsRequest(requestData));
  };
  const getWrapVariant = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollWrapVarintRequest(requestData));
  };
  const getWrapExtra = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollWrapExtraRequest(requestData));
  };
  const getRibbonColors = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollRibbonColorRequest(requestData));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      // button: <Button variant="outline">test</Button>,
      image: <img src={data?.image} alt={data?.name} width="108px" height="108px" />,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/suggested-wrappers",
      title: "wrappers/Suggested Wraps",
    },
    type && {
      active: false,
      title:
        type === "details" ? "Wrap details" : id ? "Edit Suggested Wrap" : "New Suggested Wrap",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      !id && formik?.resetForm();
      navigate("/suggested-wrappers");
      toast.success(`Suggested Wrap has been ${id ? "updated" : "created"} successfully`);
    };
    const deletedImages = details?.media?.filter((image) => !data?.images.includes(image.url));
    const backImages = data?.images || [];

    const newImages = [];
    data?.images.forEach((url) => {
      const existsInObjects = details?.media?.some((obj) => obj.url === url);
      if (!existsInObjects) {
        newImages.push(url);
      }
    });

    const images = newImages.length > 0 ? deletedImages.concat(newImages) : deletedImages;
    const requestData = {
      body: {
        suggested_wrapper: {
          en: {
            name: data?.en.name,
            description: data?.en.description,
          },
          ar: {
            name: data?.ar.name,
            description: data?.ar.description,
          },
          media_attributes: images?.map((image) => ({
            id: image?.id,
            media_type: 0,
            url: typeof image === "object" ? image.url : image,
            _destroy: image?.id && true,
          })),
          image: data?.image,
          wrap_variant_id: data?.wrap_variant_id,
          ribbon_color_id: data?.ribbon_color_id,
          wrappable_type: data?.wrappable_type === 1 ? "cart" : "cart_item",
          wrap_extra_ids: data?.wrap_extra_ids,
        },
      },
      action,
    };
    if (id) {
      //update call
      dispatch(updateSuggestedWrapRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(addSuggestedWrapRequest(requestData));
    }
  };

  const filterList = [];

  const handleDelete = (id) => {
    //action to be called after successfull deletion
    const action = () => {
      navigate("/suggested-wrappers");

      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      if (type == "details") {
        navigate(-1);
      }
      toast.success("Suggested Wrap deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteSuggestedWrapRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);
  const options = {
    wraps: {
      options: [details?.wrap_variant?.wrap, ...filterWraps],
      onOpen: filterWraps?.length <= 1 && getWraps,
      isLoading: wrapsLoad?.loading && wrapsLoad?.type == "wraps-scroll-list",
      getList: getWraps,
      count: wrappersCount,
    },
    wrapVarinats: {
      options: [details?.wrap_variant, ...filterWrapVariant],
      onOpen: filterWrapVariant?.length <= 1 && getWrapVariant,
      isLoading:
        wrapVariantLoading?.loading && wrapVariantLoading?.type == "wrap-variant-scroll-list",
      getList: getWrapVariant,
      count: wrapVariantCount,
    },
    wrapExtra: {
      options: filterWrapExtra,
      onOpen: filterWrapExtra?.length <= 1 && getWrapExtra,
      isLoading:
        wrapExtraLoading?.loading && wrapExtraLoading?.type == "wrap-extra-scroll-list",
      getList: getWrapExtra,
      count: wrapExtraCount,
    },
    ribbonColors: {
      options: [details?.ribbon_color, ...filterRibbonColor],
      onOpen: filterRibbonColor?.length <= 1 && getRibbonColors,
      isLoading:
        ribbonColorLoading?.loading && ribbonColorLoading?.type == "ribbon-color-scroll-list",
      getList: getRibbonColors,
      count: ribbonColorCount,
    },
  };
  console.log(filterWrapVariant?.length, filterWrapVariant, 'filterWrapVariant?.length');
  // console.log(details, 'details');
  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <SuggestedWrapsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        selectedRow={selectedRow}
        can_create={isSuperAdmin || permissions?.can_create}
        options={options}
      />
    </>
  );
}

export default SuggestedWraps;
