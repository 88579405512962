import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OrderStatusTimelineDetails from "./orderStatusTimelineDetails";
import { useState } from "react";
import dayjs from "dayjs";
import CancelledStatus from "components/Molecules/Order/cancelledStatus";
import { useSelector } from "react-redux";
import { StoreUser } from "services/modules/utils/constant";

export default function OrderStatus({
  details,
  userType,
  routesStatusChanges,
  adminPossibleStatus,
  width,
  title,
  orderStatus,
  onClick,
  isClickable = false,
  height,
}) {
  const [openTimelineDetails, setOpenTimelineDetails] = useState(false);
  const { orange, red } = useThemePalette();
  const green = "#09CE7D";
  const statusColor = {
    nextStep: "#FFCC00",
    completedStep: "#09CE7D",
    cancelledStep: red,
  };
  // const routesStatusChanges = useSelector((state) => state?.orders?.routesStatusChanges);
  const latestStatus = routesStatusChanges?.[0] || {};

  const { next_state, prev_state, created_at } = latestStatus;
  const storePossibleStatus = {
    processing: { status: "Processing", step: 1, next: "Assigned" },
    start_route_assigned: { status: "Assigned", step: 2, next: "Started" },
    started: { status: "Started", step: 3, next: "Assigned" },
    collected: {
      status: "Delivered from supplier(s)to Fulfillment",
      step: 4,
      next: "Assigned",
    },
  };
  const possibleStatus =
    userType != StoreUser ? adminPossibleStatus : storePossibleStatus;
  //to assign date of each change to the possibleStatus obj
  const updateDate = routesStatusChanges?.forEach((status, i) => {
    const routeStatus = possibleStatus[status?.next_state];
    if (routeStatus) {
      routeStatus.date = status.created_at;
    }
  });

  const Bars = () => {
    return (
      <>
        {Object.values(possibleStatus).map((elem, index) => (
          <Box
            key={index}
            sx={{
              width: "25%",
              height: "6px",
              backgroundColor:
                elem.step <= possibleStatus[next_state]?.step
                  ? statusColor.completedStep
                  : "#E3E3E3",
              borderRadius: "3px",
            }}
          />
        ))}
      </>
    );
  };
  return (
    <>
      <OrderStatusTimelineDetails
        statusColor={statusColor}
        open={openTimelineDetails}
        setOpen={setOpenTimelineDetails}
        data={Object.values(possibleStatus)}
        completed={possibleStatus[next_state]?.step}
        cancelledStep={
          orderStatus == "canceled" && {
            step: possibleStatus[prev_state]?.step,
            date: created_at,
          }
        }
      />
      <Stack
        sx={{
          backgroundColor: "white",
          py: 3,
          px: 4,
          width: width || "100%",
          height: height && height,
          boxSizing: "border-box",
        }}
        gap={2}
        justifyContent={"space-between"}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* ------------------ CARD HEADER ------------------ */}
          <Stack
            direction="row"
            onClick={(_) => onClick && onClick()}
            alignItems="center"
            gap={2}
            sx={{          cursor: isClickable && "pointer"}}
          >
            <Box component="img" src={icons.box} width="48px" />
            <Typography fontSize="24px" fontWeight="500">
              {title}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ cursor: "pointer", gap: 1 }}
            onClick={() => setOpenTimelineDetails(true)}
          >
            <Typography
              sx={{ color: orange, fontWeight: "400", fontSize: "16px" }}
            >
              View timeline details
            </Typography>
            <ArrowForwardIosIcon fontSize="small" sx={{ color: orange }} />
          </Stack>
        </Stack>
        {orderStatus !== "canceled" ? (
          <>
            {/* ------------------ PROGRESS BARS ------------------ */}
            <Stack direction="row" gap={0.5}>
              <Bars />
            </Stack>
            {/* ------------------ STATUS INFO START------------------ */}
            <Stack direction="row" justifyContent="space-between">
              <Typography
                fontWeight="700"
                fontSize="20px"
                sx={{
                  color: statusColor.completedStep,
                  textTransform: "capitalize",
                }}
              >
                {possibleStatus[next_state]?.status}
              </Typography>
              <Typography fontWeight="500" fontSize="14px">
                {dayjs(created_at).format("DD-MM-YYYY, hh:mm a")}
              </Typography>
            </Stack>
            {/* ------------------ STATUS INFO END------------------ */}
            {/* ------------------ NEXT STEP START ------------------ */}
            <Stack>
              {possibleStatus[next_state]?.status && (
                <Typography fontWeight="400" fontSize="18px">
                  {possibleStatus[next_state]?.next &&
                    `Next : ${possibleStatus[next_state]?.next}`}
                </Typography>
              )}
            </Stack>
            {/* ------------------ NEXT STEP END ------------------ */}
          </>
        ) : (
          <CancelledStatus createdAt={created_at} />
        )}
      </Stack>
    </>
  );
}
