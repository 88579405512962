/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  zones: [],
  filterZones: [],
  listCount: 0,
  zone: {},
  count: 0,
  load: null,
};

export function zonesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.ZONES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }

    case Types.GET_ZONES_SUCCESS: {
      return {
        ...state,
        zones: payload?.data?.zones,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_ZONE_DETAILS_SUCCESS: {
      return {
        ...state,
        zone: payload?.data?.zone,
      };
    }
    case Types.GET_SCROLL_ZONES_SUCCESS: {
      return {
        ...state,
        filterZones: payload?.extra?.page_number == 1
          ? payload?.data?.zones
          : [...state.filterZones, ...payload?.data?.zones],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
