import { Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import Media from "components/Molecules/Products/details/media";
import Thumbnail from "components/Molecules/Products/details/thumbnail";
import dayjs from "dayjs";

export const detailsData = ({ data }) => {
  return [
    {
      head: "Images",
      icon: icons?.media,
      details: [
        {
          label: "",
          name: (
            <Stack direction="row" gap={2}>
              <Thumbnail src={data?.thumbnail} />
              <Media media={data?.media} />
            </Stack>
          ),
          width: "100%",
        },
      ],
    },
    {
      head: "Dates",
      icon: icons?.dates,
      details: [
        {
          label: "Created At",
          name: dayjs(data?.created_at).format("DD-MM-YYYY HH-MM a"),
        },
        {
          label: "Updated At",
          name: dayjs(data?.updated_at).format("DD-MM-YYYY HH-MM a"),
        },
      ],
    },
    {
      head: "Details",
      icon: icons?.details,
      details: [
        {
          label: "Vendor",
          name: data?.store?.name,
          width: "100%",
        },
        {
          label: "Product Name (EN)",
          name: data?.localized_data?.en?.name,
        },
        {
          label: "Product Name (AR)",
          name: data?.localized_data?.ar?.name,
        },
        {
          label: "Description(EN)",
          name: data?.localized_data?.en?.description,
        },
        {
          label: "Description(AR)",
          name: data?.localized_data?.ar?.description ?? "-",
        },
        {
          label: "Gender",
          name: (
            <Stack
              sx={{
                border: "1px solid #E6E6E6",
                borderRadius: "4px",
                width: "fit-content",
                p: 1,
              }}
            >
              {data?.target_gender}
            </Stack>
          ),
        },
        {
          label: "Age",
          name: (
            <Stack
              sx={{
                border: "1px solid #E6E6E6",
                borderRadius: "4px",
                width: "fit-content",
                p: 1,
              }}
            >
              {data?.target_age}
            </Stack>
          ),
        },
        {
          label: "Occasions",
          name: (
            <Stack direction="row" gap={1}>
              <Stack
                sx={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "4px",
                  width: "fit-content",
                  p: 1,
                }}
              >
                {data?.occasion_types_details?.length > 0 ? (
                  data?.occasion_types_details?.map((oc) => <>{oc.name}</>)
                ) : (
                  <Typography color="red"> No occasion selected </Typography>
                )}
              </Stack>
            </Stack>
          ),
        },
      ],
    },
    {
      head: "Prices",
      icon: icons?.fees,
      details: [
        {
          label: "Price",
          name: (
            <Stack>
              {data?.price} {data?.currency?.name}
            </Stack>
          ),
          width: "30%",
        },
        {
          label: "Price after discount",
          name: (
            <Stack>
              {data?.price_after_discount} {data?.currency?.name}
            </Stack>
          ),
          width: "30%",
        },
        {
          label: "Quantity",
          name: data?.total_on_hand,
          width: "30%",
        },
      ],
    },
    {
      head: "Dates",
      icon: icons?.dates,
      details: [
        {
          label: "Created At",
          name: dayjs(data?.created_at).format("DD-MM-YYYY HH-MM a"),
        },
        {
          label: "Updated At",
          name: dayjs(data?.updated_at).format("DD-MM-YYYY HH-MM a"),
        },
      ],
    },
  ];
};
