import { makeFilterString } from "services/modules/utils/Helper";
import { DeleteDriverSuccess, DriversLoading, GetDriverDetailsSuccess, GetDriversSuccess, UpdateDriverSuccess ,AddDriverSuccess} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getDriversRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DriversLoading,
    loadingType: "list",
    successFunction: GetDriversSuccess,
    url: `/admin/drivers${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const addDriverRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DriversLoading,
    loadingType: "add",
    successFunction: AddDriverSuccess,
    action: variables?.action,
    url: `/admin/drivers`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getDriverDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DriversLoading,
    loadingType: "details",
    successFunction: GetDriverDetailsSuccess,
    action: variables?.action,
    url: `/admin/drivers/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateDriverRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DriversLoading,
    loadingType: "update",
    successFunction: UpdateDriverSuccess,
    action: variables?.action,
    url: `/admin/drivers/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const deleteDriverRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DriversLoading,
    loadingType: "delete",
    successFunction: DeleteDriverSuccess,
    action: variables?.action,
    url: `/admin/drivers/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



