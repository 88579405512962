import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import SuccessModal from "components/Molecules/Models/SuccessModal";

import dayjs from "dayjs";
import {
  getDriverComplaintsDetailsRequest,
  getDriverComplaintsRequest,
  updateDriverComplaintsRequest,
} from "../../services/modules/complaints/driverComplaints";
import Status from "../../components/Atoms/SharedComponents/Vendor/Status/status";
import { toast } from "react-toastify";
import DriverComplaintsTemplate from "../../templates/complaints/driverComplaints";
import ComplaintStatus from "../../components/Atoms/SharedComponents/Complaints/complaintsStatus";

function DriverComplaintsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.driverComplaints.driverComplaints);
  const count = useSelector((state) => state.driverComplaints.count);
  const details = useSelector(
    (state) => state.driverComplaints.driverComplaint
  );
  const load = useSelector((state) => state.driverComplaints.load);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const statusOptions = [
    { name: "Resolved", id: 1 },
    { name: "Not Resolved", id: 2 },
  ];
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getDriverComplaintsDetailsRequest({ id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getDriverComplaintsRequest(requestData));
  };

  const handleStatus = () => {
    const requestData = {
      body: {
        route_complaint: {
          status: details?.status === "closed" ? "open" : "closed",
        },
      },
      action: () => {
        details?.status === "open"
          ? toast.success("Complaint marked as resolved Succesfully")
          : toast.success("Complaint marked as not resolved Succesfully");
        navigate("/driver-complaints");
      },
    };

    dispatch(updateDriverComplaintsRequest({ ...requestData, id }));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      // button: <Button variant="outline">test</Button>,
      status: <ComplaintStatus data={data} />,
      route_id: "#" + data?.route_id,
      created_at: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/driver-Complaints",
      title: "Driver Complaints",
    },
    type && {
      active: false,
      title: type === "details" ? "Details" : id ? "Edit Tag" : "New Tag",
    },
  ];
  const handelSubmit = (data, formik) => {};

  const filterList = [
    {
      type: "search",
      placeholder: "Search by Driver",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
        setFilterObj,
    },
    {
      type: "filter",
      placeholder: "Status",
      onChange: (value) => {
        console.log(value);
        setFilterObj((s) => {
          return { ...s, status: value?.value, page_number: 1 };
        });
      },
      options: [
        { id: "closed", name: "Resolved" },
        { id: "open", name: "Not Resolved" },
      ],
    },
  ];

  const handleDelete = () => {};

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <DriverComplaintsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        handleConfirmation={handleStatus}
        confirmBtnTitle={details?.status === "open" ? "Resolved" : "Unresolved"}
        color="black"
        btnColor={details?.status === "open" && "#3FABAE"}
      />
    </>
  );
}

export default DriverComplaintsPage;
