import {makeFilterString} from "../../utils/Helper";
import {
    GetProductOptionValueDetails,
    GetProductsOptionValues,
    ProductsOptionValuesLoading,
    SetProductOptionValue
} from "./Actions";
import {reduxRequest} from "../../../../common/utils/reduxRequest";

export const getProductsOptionValuesRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: ProductsOptionValuesLoading,
        loadingType: "list",
        successFunction: GetProductsOptionValues,
        url: `/admin/product_options/option_values?${makeFilterString(
            variables?.params
        )}`,
        method: "get",
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const createProductOptionValueRequest = ({ ...variables }) => {
    const requestData = {
        isLoading:ProductsOptionValuesLoading ,
        loadingType: "add",
        successFunction: SetProductOptionValue,
        action: variables?.action,
        url: `/admin/product_options/option_values`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const getProductOptionValueDetailsRequest = ({ ...variables }) => {
    console.log(variables)
    const requestData = {
        isLoading: ProductsOptionValuesLoading,
        loadingType: "details",
        successFunction: GetProductOptionValueDetails,
        url: `/admin/product_options/option_values/${variables?.option_value_id}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const deleteProductOptionValueRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: ProductsOptionValuesLoading,
        loadingType: "delete option type",
        action: variables?.action,
        url: `/admin/product_options/option_values/${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const updateProductValueRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: ProductsOptionValuesLoading,
        loadingType: "update option type",
        url: `/admin/product_options/option_values/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};