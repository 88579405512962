import { Stack, Typography } from "@mui/material";

export default function OrderStatus({ data }) {
  const orderStatusColor = {
    green: "#E1F5E8",
    red: "#FAE3E3",
    lightGreen: "#29B95F",
    lightRed: "#D20000",
  };
  return (
    <Stack
      sx={{
        p: 0.2,
        px: 3,
        backgroundColor: data?.is_visible ? orderStatusColor.green : orderStatusColor.red,
      }}
    >
      <Typography
        sx={{
          color: data?.is_visible ? orderStatusColor.lightGreen : orderStatusColor.lightRed,
        }}
      >
        {data?.is_visible ? "Active" : "Disable"}
      </Typography>
    </Stack>
  );
}
