import { Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  isOpenFollowesModel,
  isOpenFollowingModel,
} from "services/modules/modal/Actions";
import {
  clearFollowersLits,
  clearFollowingLits,
} from "services/modules/users/customers/Action";

export default function FollowersData({
  data,
  getFollowingList,
  getFollowersList,
  followingslist,
  followersList,
}) {
  const dispatch = useDispatch();
  console.log(data, "data");
  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      gap="30px"
      justifyContent="space-around"
      bgcolor="#F9F9F9"
      borderRadius="12px"
      p="15px"
    >
      <Stack
        sx={{ cursor: "pointer" }}
        alignItems="center"
        onClick={(_) => {
          dispatch(isOpenFollowesModel());
          // lw 3dd el followers aw el following == 0 -> clear el list
          data?.num_of_followers == 0 && dispatch(clearFollowersLits()); //clear el followers
          followersList?.length <= 1  &&
            data?.num_of_followers != 0 &&
            getFollowersList();
        }}
      >
        <Typography fontWeight="700" fontSize="22px">
          {data?.num_of_followers}
        </Typography>
        <Typography color="#626262" fontSize="18px">
          Followers
        </Typography>
      </Stack>
      <Stack bgcolor="#CBCBCB" width="1px" height="23px"></Stack>
      <Stack
        sx={{ cursor: "pointer" }}
        alignItems="center"
        onClick={(_) => {
          dispatch(isOpenFollowingModel());
          data?.num_of_following == 0 && dispatch(clearFollowingLits()); //clear el followers
          followingslist?.length <= 1 &&
            data?.num_of_following != 0 &&
            getFollowingList();
        }}
      >
        <Typography fontWeight="700" fontSize="22px">
          {data?.num_of_following}
        </Typography>
        <Typography color="#626262" fontSize="18px">
          Following
        </Typography>
      </Stack>
      <Stack bgcolor="#CBCBCB" width="1px" height="23px"></Stack>
      <Stack alignItems="center">
        <Stack direction="row" alignItems="center" gap="5px">
          <Typography fontWeight="700" fontSize="22px">
            {data?.wallet_balance?.balance || 0}
          </Typography>
          <Typography fontSize="18px">
            {data?.wallet_balance?.currency?.name || "SAR"}
          </Typography>
        </Stack>
        <Typography color="#626262" fontSize="18px">
          Wallet
        </Typography>
      </Stack>
    </Stack>
  );
}
