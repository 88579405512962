/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  wrapVariants: [],
  filterWrapVariant: [],
  wrapVariant: {},
  count: 0,
  listCount: 0,
  load: null,
  error: null,
};

export function wrapVariantsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_WRAP_VARIANTS_SUCCESS: {
      return {
        ...state,
        wrapVariants: payload?.data?.wrap_variants,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_SCROLL_WRAP_VARIANT_SUCCESS: {
      return {
        ...state,
        filterWrapVariant: payload?.extra?.page_number == 1
          ? payload?.data?.wrap_variants
          : [...state.filterWrapVariant, ...payload?.data?.wrap_variants],
        listCount: payload?.extra?.total_count,
      };
    }
    case Types.WRAP_VARIANTS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_WRAP_VARIANT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_WRAP_VARIANT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_WRAP_VARIANT_FAILED: {
      return {
        ...state,
        error: payload?.response?.data?.message,
      };
    }
    case Types.UPDATE_WRAP_VARIANT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_WRAP_VARIANT_DETAILS_SUCCESS: {
      return {
        ...state,
        wrapVariant: payload?.data?.wrap_variant,
      };
    }

    default: {
      return state;
    }
  }
}
