const Types = {
  // list
  GET_GIFT_CARDS_SUCCESS: "GET_GIFT_CARDS_SUCCESS",
  // details
  GET_GIFT_CARD_DETAILS_SUCCESS: "GET_GIFT_CARD_DETAILS_SUCCESS",
  //loading
  GIFT_CARDS_LOADING: "CITIES_LOADING",

};

export default Types;
