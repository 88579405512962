import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { images } from "assets/AssetHelper";
import { useState } from "react";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";
import { useSelector } from "react-redux";
import Loading from "components/Atoms/SharedComponents/Loading/Loading";

const SideNavbar = () => {
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions
  );
  const load = useSelector((state) => state.authenticate.load);
  const isSuperAdmin = currentUser?.privilege == "super_admin";
  const { oliveGreen } = useThemePalette();
  const location = useLocation();
  const path = location.pathname;
  const isStore = localStorage?.getItem("user_type") == "Store";
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let sidebarNavs = [
    { title: "Dashboard", path: "/", type: "normal", icon: icons.dashboard },
    //* DONE EXCEPT ROLES
    {
      title: "Users",
      icon: icons.users,
      path: "",
      type: "",
      list: [
        (isSuperAdmin || permissions?.admin_users?.can_read) && {
          title: "Admin users",
          path: "/admin-users",
          type: "normal",
        },
        (isSuperAdmin || permissions?.customers?.can_read) && {
          title: "Customers",
          path: "/customers",
          type: "normal",
        },
        (isSuperAdmin || permissions?.drivers?.can_read) && {
          title: "Employee drivers",
          path: "/employee-drivers",
          type: "normal",
        },
        (isSuperAdmin || permissions?.drivers?.can_read) && {
          title: "Freelancer drivers",
          path: "/freelancer-drivers",
          type: "normal",
        },
      ],
    },
    //* DONE
    (isSuperAdmin || permissions?.stores?.can_read) && {
      title: "Vendors",
      icon: icons.vendorSidebarIcon,
      path: "/vendors",
      type: "normal",
    },
    {
      title: "Products",
      path: "",
      type: "",
      icon: icons.products,
      list: [
        (isSuperAdmin || permissions?.products?.can_read) && {
          title: "Products",
          path: "/products",
          type: "normal",
        },
      ],
    },
    //! NOT DONE
    (isSuperAdmin || permissions?.orders?.can_read) && {
      title: "Orders",
      path: "/orders",
      type: "normal",
      icon: icons.orders,
    },
    //* DONE
    (isSuperAdmin || permissions?.cart_gift_cards?.can_read) && {
      title: "Gift cards",
      path: "/gift-cards",
      type: "normal",
      icon: icons.giftcards,
    },
    //* DONE
    {
      title: "Cities",
      icon: icons.cities,
      path: "",
      type: "",
      list: [
        (isSuperAdmin || permissions?.google_cities?.can_read) && {
          title: "Cities",
          path: "/cities",
          type: "normal",
        },
        (isSuperAdmin || permissions?.zones?.can_read) && {
          title: "Zones",
          path: "/zones",
          type: "normal",
        },
        (isSuperAdmin || permissions?.between_zones_fees?.can_read) && {
          title: "Between zones fees",
          path: "/between-zones-fees",
          type: "normal",
        },
      ],
    },

    {
      title: isStore ? "Chat with admin" : "Chat with stores",
      type: "normal",
      path: "/messenger",
      icon: icons.chat,
    },

    // { title: "Country", path: "/country", type: "normal" },
    // { title: "Vendors", path: "/vendors", type: "normal" },
    //* DONE
    (isSuperAdmin || permissions?.system_configurations?.can_read) && {
      title: "System configuration",
      path: "/system-configurations",
      type: "normal",
      icon: icons.gear,
    },
    //* DONE
    {
      title: "Predefined Lookups",
      icon: icons.reportData,
      path: "",
      type: "",
      list: [
        (isSuperAdmin || permissions?.featured_banners?.can_read) && {
          title: "Featured Ads",
          path: "/featured-ads",
          type: "normal",
        },
        (isSuperAdmin || permissions?.tags?.can_read) && {
          title: "Tags",
          path: "/tags",
          type: "normal",
        },
        (isSuperAdmin || permissions?.option_types?.can_read) && {
          title: "Products Option Types",
          path: "/products-option-types",
          type: "normal",
        },
        (isSuperAdmin || permissions?.order_complaints?.can_read) && {
          title: "Complaints Reasons For Customer",
          path: "/customer-complaint-reasons",
          type: "normal",
        },
        (isSuperAdmin || permissions?.route_complaints?.can_read) && {
          title: "Complaints Reasons For Driver",
          path: "/driver-complaint-reasons",
          type: "normal",
        },
        (isSuperAdmin || permissions?.option_values?.can_read) && {
          title: "Products Option Values",
          path: "/products-option-values",
          type: "normal",
        },
        (isSuperAdmin || permissions?.order_declination_reasons?.can_read) && {
          title: "Order Declination Reasons",
          path: "/order-declination-reasons",
          type: "normal",
        },

        (isSuperAdmin || permissions?.categories?.can_read) && {
          title: "Categories",
          path: "/categories",
          type: "normal",
        },
        (isSuperAdmin || permissions?.delivery_times?.can_read) && {
          title: "Delivery Times",
          path: "/delivery-times",
          type: "normal",
        },
      ],
    },
    //*DONE
    {
      title: "wrappers",
      icon: icons.wrappers,
      path: "",
      type: "",
      list: [
        (isSuperAdmin || permissions?.wraps?.can_read) && {
          title: "Wraps",
          path: "/wraps",
          type: "normal",
        },
        (isSuperAdmin || permissions?.shapes?.can_read) && {
          title: "Shapes",
          path: "/shapes",
          type: "normal",
        },
        (isSuperAdmin || permissions?.colors?.can_read) && {
          title: "Colors",
          path: "/colors",
          type: "normal",
        },
        (isSuperAdmin || permissions?.wrap_variants?.can_read) && {
          title: "Wrap variants",
          path: "/wrap-variants",
          type: "normal",
        },
        (isSuperAdmin || permissions?.ribbon_colors?.can_read) && {
          title: "Ribbon colors",
          path: "/ribbon-colors",
          type: "normal",
        },
        (isSuperAdmin || permissions?.wrap_extras?.can_read) && {
          title: "Wraps extras",
          path: "/wraps-extras",
          type: "normal",
        },
        (isSuperAdmin || permissions?.suggested_wrappers?.can_read) && {
          title: "Suggested wrappers",
          path: "/suggested-wrappers",
          type: "normal",
        },
      ].filter((elem) => elem),
    },
    //*DONE
    {
      title: "Occasions",
      icon: icons.occassions,
      path: "",
      type: "",
      list: [
        (isSuperAdmin || permissions?.occasions?.can_read) && {
          title: "Occasions",
          path: "/occasions",
          type: "normal",
        },
        (isSuperAdmin || permissions?.occasion_types?.can_read) && {
          title: "Occasions types",
          path: "/occasions-types",
          type: "normal",
        },
      ],
    },
    {
      title: "Complaints",
      icon: icons.complaintsIcon,
      path: "",
      type: "",
      list: [
        (isSuperAdmin || permissions?.order_complaints?.can_read) && {
          title: "Customer Complaints ",
          path: "/customer-Complaints",
          type: "normal",
        },
        (isSuperAdmin || permissions?.route_complaints?.can_read) && {
          title: "Driver Complaints ",
          path: "/driver-Complaints",
          type: "normal",
        },
      ],
    },
    (isSuperAdmin || permissions?.notifications?.can_read) && {
      title: "Notifications",
      path: "/push-notifications",
      type: "normal",
      icon: icons.document,
    },
  ];

  sidebarNavs = sidebarNavs
    .map((elem) => {
      if (elem && elem?.type !== "normal") {
        let newList = elem?.list?.filter((el) => el);
        if (newList?.length !== 0) {
          return {
            ...elem,
            list: newList,
          };
        }
        return false;
      } else {
        return elem;
      }
    })
    .filter((elem) => elem);

  return (
    <Stack
      width="100%"
      height="100vh"
      direction="column"
      sx={{ backgroundColor: "#1A1A1A", overflowY: "scroll" }}
    >
      <Stack
        // width="fit-content"
        width="100%"
        // minHeight="100vh"
        direction="column"
        sx={{
          backgroundColor: "#1A1A1A",
          // height: "100%",
          // overflowY: "scroll",
          // position: "fixed",
          left: 0,
        }}
      >
        {/* -------------------- LOGO -------------------- */}
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderBottom: "1px solid #404040", px: 3, pt: 2, pb: 5 }}
        >
          <Box
            component="img"
            src={images.Logo2}
            loading="lazy"
            width="150px"
            alt=""
          />
        </Stack>
        {/* -------------------- NAVS -------------------- */}
        <Loading
          isLoading={load?.type == "get user" && load?.loading}
          sx={{ bgcolor: "#1A1A1A !important" }}
        >
          <Stack direction="column" spacing={2}>
            {sidebarNavs?.map((data, index) => {
              return data?.type === "normal" ? (
                <Stack
                  direction="row"
                  key={index}
                  px={3}
                  sx={{
                    bgcolor: path === data.path ? oliveGreen : "",
                    height: "50px",
                    // border:'1px solid red'
                  }}
                  onClick={() => setExpanded(false)}
                >
                  <img src={data?.icon} alt="" width="24px" />
                  <NavLink
                    to={data?.path}
                    style={{
                      fontSize: "16px",
                      textDecoration: "none",
                      color: "white",
                      marginInlineStart: "5px",
                      // border: "1px solid blue",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {data?.title}
                  </NavLink>
                </Stack>
              ) : (
                <Accordion
                  key={index}
                  sx={{
                    width: "100%",
                    border: "none !important",
                    boxShadow: "none",
                    background: "transparent",
                    padding: "0",
                  }}
                  className="nav-accordion"
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowForwardIosIcon
                        sx={{ transform: "rotate(90deg)", color: "white" }}
                        fontSize="small"
                      />
                    }
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                    sx={{ padding: "0", paddingX: "15px" }}
                  >
                    <Stack direction="row" alignItems="center">
                      <img
                        src={data?.icon}
                        alt=""
                        width="24px"
                        style={{ marginLeft: "10px" }}
                      />
                      <NavLink
                        to={data?.path ? data?.path : null}
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textDecoration: "none",
                          color: "white",
                          marginInlineStart: "5px",
                        }}
                      >
                        {data?.title}
                      </NavLink>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      background: "#F0F3FF",
                      height: "100%",
                      padding: "0",
                      width: "80%",
                      mx: "auto",
                      backgroundColor: "transparent",
                    }}
                  >
                    {data?.list?.map((val, idx) => {
                      return (
                        <Typography
                          // padding="15px"
                          // paddingX="25px"
                          key={idx}
                          sx={{
                            display: "flex",
                            borderRadius: "4px",
                            overflow: "hidden",
                          }}
                        >
                          <NavLink
                            to={val?.path}
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              textDecoration: "none",
                              color: "white",
                              padding: "10px",
                              width: "100%",
                              backgroundColor: path === val?.path && oliveGreen,
                            }}
                          >
                            <Stack flexDirection={"row"}>
                              <Typography
                                component="span"
                                sx={{
                                  color:
                                    path === val?.path ? "white" : "#727272",
                                  mr: "5px",
                                  fontSize: "20px",
                                  textAlign: "start",
                                }}
                              >
                                &#x2022;
                              </Typography>
                              <Typography> {val?.title}</Typography>
                            </Stack>
                          </NavLink>
                        </Typography>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        </Loading>
      </Stack>
    </Stack>
  );
};

export default SideNavbar;
