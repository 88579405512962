import { GetChatRoomRequest, GetChatRoomsRequest, createChatRoomRequest, searchChatRoomsRequest } from "services/modules/chat";
import { GetMessages, visitChatRoom } from "services/modules/chat/LiveChat";
import { getAdminUsersRequest } from "services/modules/users";



export const searchChats = ({ searchChatsFilterObj, dispatch, isSearchOpen }) => {
    const requestData = {
        params: { ...searchChatsFilterObj, },
    };
    searchChatsFilterObj && isSearchOpen && dispatch(searchChatRoomsRequest(requestData));
}

export const getMessages = async ({ setMessagesList, messagesList }) => {
    const messagesNode = localStorage.getItem("Messages_node");
    if (messagesNode) {
        await GetMessages(messagesNode, setMessagesList, messagesList);
    }
};

export const updateMessages = ({ chat_id, dispatch, setMessagesList, messagesList }) => {
    visitChatRoom({ chat_id, dispatch })
    getMessages({ setMessagesList, messagesList })
};

export const visitChatRoomWhenClick = ({ messages_url, chat_id, name, image, setFirstMessageCount, setMessagesList, messagesList, dispatch, setMessage }) => {
    try {
        new URL(messages_url);
    } catch (error) {
        return;
    }
    setFirstMessageCount(0);
    let url = new URL(messages_url);
    //Its purpose is to clear the current messagesList before updating it with messages
    // to void getting mixed up or displayed in the wrong chat after switching
    setMessagesList([])
    localStorage.setItem("Messages_node", url?.pathname?.slice(1));
    updateMessages({ chat_id, dispatch, setMessagesList, messagesList });
    localStorage.setItem("chatName", name);
    localStorage.setItem("chatProfileImg", image);
    localStorage.setItem("chatId", chat_id);
    setMessage('')
};

export const getChatRoom = async ({ id, dispatch, isStore, setMessage }) => {
    const action = (data) => {
        visitChatRoomWhenClick(data?.data?.chat_room?.messages_url, data?.data?.chat_room?.id, data?.data?.chat_room?.name, data?.data?.chat_room?.image, setMessage)
    }
    const requestData = {
        chatId: id,
        action
    };
    !isStore && dispatch(GetChatRoomRequest(requestData));
};

export const createChatRoom = ({ userId, userType = 'Store', setFirstMessageCount, setMessagesList, messagesList, dispatch, setMessage }) => {
    const action = (data) => {
        visitChatRoomWhenClick({
            messages_url: data?.data?.chat_room?.messages_url,
            chat_id: data?.data?.chat_room?.id,
            name: data?.data?.chat_room?.name,
            image: data?.data?.chat_room?.image,
            setFirstMessageCount,
            setMessagesList,
            messagesList,
            dispatch,
            setMessage
        })
    }
    const requestData = {
        body: {
            chat_room: {
                chat_members: [
                    {
                        user_id: userId,
                        user_type: userType
                    }
                ]
            }
        },
        action
    }
    dispatch(createChatRoomRequest({ ...requestData }))
}


// get admin data to auto create chat with admin by admin id
export const getAdminUser = async ({ setFirstMessageCount, setMessagesList, messagesList, dispatch, setMessage }) => {
    const action = (data) => {
        createChatRoom({ userId: data?.data?.user?.[0]?.id, userType: 'AdminUser', setFirstMessageCount, setMessagesList, messagesList, dispatch, setMessage })
    }
    const requestData = {
        params: {
            page_number: 1,
            privilege: 'super_admin',
        },
        action
    };
    dispatch(getAdminUsersRequest(requestData));
}