import { Stack, Typography } from '@mui/material';
import { useThemePalette } from 'common/hooks/theme_palette';
import DetailsList from 'components/Atoms/SharedComponents/Details/detailsList';
import React from 'react'
import { useState } from 'react';

export default function CustomerAddresses({ data, addressesToShow }) {
    const { labelColor, textGray } = useThemePalette()
    return <>
        <Stack>
            {data?.map((data) =>
                <Stack bgcolor='#FCFCFC' p='24px' borderRadius='4px' width='100%' flexWrap='wrap' gap="24px" direction='row' mb='24px' >
                    <Stack
                        width={"42% !important"}
                        mr="20px"
                    >
                        <Typography fontSize="16px" fontFamily="Jost" color={labelColor}>
                            Country
                        </Typography>
                        <Typography fontSize="20px" fontFamily="Jost" color={textGray}>
                            {data?.country?.name}
                        </Typography>
                    </Stack>
                    <Stack
                        width={"42% !important"}
                        mr="20px"
                    >
                        <Typography fontSize="16px" fontFamily="Jost" color={labelColor}>
                            City
                        </Typography>
                        <Typography fontSize="20px" fontFamily="Jost" color={textGray}>
                            {data?.city?.name}
                        </Typography>
                    </Stack>
                    <Stack
                        width={"42% !important"}
                        mr="20px"
                    >
                        <Typography fontSize="16px" fontFamily="Jost" color={labelColor}>
                            District
                        </Typography>
                        <Typography fontSize="20px" fontFamily="Jost" color={textGray}>
                            {data?.district}
                        </Typography>
                    </Stack>
                    <Stack
                        width={"42% !important"}
                        mr="20px"
                    >
                        <Typography fontSize="16px" fontFamily="Jost" color={labelColor}>
                            Landmark
                        </Typography>
                        <Typography fontSize="20px" fontFamily="Jost" color={textGray}>
                            {data?.landmark}
                        </Typography>
                    </Stack>
                    <Stack
                        width={"42% !important"}
                        mr="20px"
                    >
                        <Typography fontSize="16px" fontFamily="Jost" color={labelColor}>
                            Location details
                        </Typography>
                        <Typography fontSize="20px" fontFamily="Jost" color={textGray}>
                            {data?.full_address}
                        </Typography>
                    </Stack>
                    <Stack
                        width={"42% !important"}
                        mr="20px"
                    >
                        <Typography fontSize="16px" fontFamily="Jost" color={labelColor}>
                            Street Information
                        </Typography>
                        <Typography fontSize="20px" fontFamily="Jost" color={textGray}>
                            {data?.street_information}
                        </Typography>
                    </Stack>
                </Stack>).slice(0, addressesToShow?.length)}
        </Stack>
    </>
}
