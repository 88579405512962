import * as Yup from "yup";
export const initialValues = (data) => {
    return {
        ...data,
        ...data?.localized_data,

    };
};
export const structure = () => [
    {
        head: "",
        width: "100%",
        list: [
            {
                name: `en.description`,
                kind: "input",
                type: "text",
                label: "Delivery Times (EN)",
                width: "47%",
            },
            {
                name: `ar.description`,
                kind: "input",
                type: "text",
                label: "Delivery Times (AR)",
                width: "47%",
            },
        ],
    },
];

export const validationSchema = () =>
    Yup.object({
        en: Yup.object().shape({
            description: Yup.string().required('Required'),
        }),
        ar: Yup.object().shape({
            description: Yup.string().required('Required'),
        }),
    });
