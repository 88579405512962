import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import ModelBox from "components/Atoms/Model/Model";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isOpenAssignToFreelancer } from "services/modules/modal/Actions";
import OpenRangeSwitch from "../openRangeSwitch";
import { icons } from "assets/AssetHelper";
import AvailableFreelancers from "../availableFreelancers";
import { db } from "services/utils/firebase";
import { onValue, ref } from "firebase/database";
import { getFreelancerRequest } from "services/modules/orders";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import Loading from "components/Atoms/SharedComponents/Loading/Loading";

const AssignToFreelancer = ({
  employees,
  selectedEmployee,
  setSelectedEmployee,
  handleAssignFreelancer,
  load,
  sendRangesRequest,
  isRouteOne,
}) => {
  const { assignToFreelancer, route } = useSelector((state) => state.modal);
  const { numberOfRequests } = useSelector((state) => state.orders);
  const { grayfour } = useThemePalette();
  const dispatch = useDispatch();
  const { systemConfigs } = useSelector((state) => state?.systemConfig);
  const [rangeOneChecked, setrangeOneChecked] = useState(false);
  const [rangeTwoChecked, setRangeTwoChecked] = useState(false);
  const ragngeOneSystemConfigId = systemConfigs?.filter(
    (data) => data?.key == "range_1"
  );
  const ragngeTwoSystemConfigId = systemConfigs?.filter(
    (data) => data?.key == "range_2"
  );

  useEffect(() => {
    onValue(ref(db, `/order_requests/${route?.id}`), (snapshot) => {
      const freelancerRequestData = {
        body: {
          route_id: route?.id,
        },
      };
      if (numberOfRequests > 0 && assignToFreelancer) {
        dispatch(getFreelancerRequest({ ...freelancerRequestData }));
      }
    });
  }, [numberOfRequests]);

  const handleSwitchRangeOneChange = () => {
    setrangeOneChecked((prev) => !prev);
    !rangeOneChecked &&
      sendRangesRequest({
        system_configuration_id:
          ragngeOneSystemConfigId && ragngeOneSystemConfigId[0]?.id,
        routeId: route?.id,
      });
  };

  const handleSwitchRangeTwoChange = () => {
    setRangeTwoChecked((prev) => !prev);
    !rangeTwoChecked &&
      rangeOneChecked &&
      sendRangesRequest({
        system_configuration_id:
          ragngeTwoSystemConfigId && ragngeTwoSystemConfigId[0]?.id,
        routeId: route?.id,
      });
  };
  const driverEmplyee = employees?.filter(
    (data) => data?.driver_type == "freelancer"
  );

  return (
    <>
      <ModelBox
        openVar={assignToFreelancer}
        closeFunc={() => dispatch(isOpenAssignToFreelancer())}
        title={"Assign Freelancer Driver"}
        titleStyle={{
          fontSize: { lg: "32px", xs: "18px" },
          fontWeight: "500",
          px: { lg: "36px", xs: "15px" },
        }}
        padding={false}
      >
        <Stack>
          <Stack p="36px">
            <Typography fontSize="18px" fontWeight="500" mb="19px">
              Available Ranges
            </Typography>
            <Stack direction="row" alignItems={"center"} gap="6px" mb="36px">
              <Typography fontSize="16px" fontWeight="400" color={grayfour}>
                You can send requests for both ranges at the same time, But you
                have to send requests for the range 1 first.
              </Typography>
            </Stack>

            <Stack
              gap="24px"
              direction="row"
              justifyContent="space-betweem"
              mb="24px"
            >
              <Stack
                width="48%"
                sx={{
                  boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.06)",
                  p: "16px",
                  borderRadius: "4px",
                }}
              >
                <OpenRangeSwitch
                  title={"Range 1"}
                  checked={rangeOneChecked}
                  handleSwitchChange={handleSwitchRangeOneChange}
                  systemConfig={ragngeOneSystemConfigId}
                  load={load}
                  requesNumber={route?.num_of_drivers_assigned}
                />
              </Stack>
              <Stack
                width="48%"
                sx={{
                  boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.06)",
                  p: "16px",
                  borderRadius: "4px",
                }}
              >
                <OpenRangeSwitch
                  title={"Range 2"}
                  checked={rangeTwoChecked}
                  handleSwitchChange={handleSwitchRangeTwoChange}
                  disabled={!rangeOneChecked}
                  systemConfig={ragngeTwoSystemConfigId}
                  load={load}
                  requesNumber={route?.num_of_drivers_assigned_range_2}
                />
              </Stack>
            </Stack>
          </Stack>

          <Loading isLoading={load?.loading && load?.type == "listFreelancer"}>
            {driverEmplyee?.length > 0 && (
              <Stack>
                <Stack p="36px">
                  <Typography fontSize="18px" fontWeight="500" mb="19px">
                    Drivers Offers
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    gap="6px"
                    mb="36px"
                  >
                    <img src={icons?.info} alt="info icon" />
                    <Typography
                      fontSize="16px"
                      fontWeight="400"
                      color={grayfour}
                    >
                      this distance is between driver and the furthest vendor 's
                      location
                    </Typography>
                  </Stack>
                  <Stack gap="16px" mb="24px">
                    {driverEmplyee?.map((data) => (
                      <AvailableFreelancers
                        data={data}
                        setSelectedEmployee={setSelectedEmployee}
                        selectedEmployee={selectedEmployee}
                        handleAssignFreelancer={handleAssignFreelancer}
                        isRouteOne={isRouteOne}
                        load={load}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Loading>
        </Stack>
      </ModelBox>
    </>
  );
};

export default AssignToFreelancer;
