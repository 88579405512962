import Types from "./Types";


// ----------------- WRAPS START-----------------
export const GetWrapsSuccess = (payload) => ({
  type: Types.GET_WRAPS_SUCCESS,
  payload,
});

export const GetWrapsLoading = (payload) => ({
  type: Types.WRAPS_LOADING,
  payload,
});


export const AddWrapSuccess = (payload) => ({
  type: Types.ADD_WRAPS_SUCCESS,
  payload,
});

export const DeleteWrapsSuccess = (payload) => ({
  type: Types.DELETE_WRAPS_SUCCESS,
  payload,
});


export const UpdateWrapsSuccess = (payload) => ({
  type: Types.UPDATE_WRAPS_SUCCESS,
  payload,
});

export const GetWrapDetailsSuccess = (payload) => ({
  type: Types.GET_WRAPS_DETAILS_SUCCESS,
  payload,
});

export const GetScrollWrapsSuccess = (payload) => ({
  type: Types.GET_SCROLL_WRAPS_SUCCESS,
  payload,
});

// ----------------- WRAPS END-----------------
