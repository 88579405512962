/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  orderDetails: {},
  employees: [],
  freelancers: [],
  assignedEmplpyee: {},
  assignedFreelancer: {},
  numberOfRequests: 0,
  route: {},
  routes: [],
  routeOne: {},
  routeTwo: {},
  routesStatusChanges: [],
  orderStatus: "",
  routeComplain: null,
  driver: null,
  orders: [],
  count: 0,
  load: null,
};

export function orderReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: payload?.data?.orders,
        count: payload?.extra?.total_count,
      };
    }
    case Types.ORDER_DETAILS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        orderDetails: payload?.data?.order,
        routes: payload?.data?.order?.routes,
        routeOne: payload?.data?.order?.routes[0],
        routeTwo: payload?.data?.order?.routes[1],
        orderStatus: payload?.data?.order?.status,
        routesStatusChanges: payload?.data?.order?.order_status_changes,
        drivers: {
          driverRouteOne:
            payload?.data?.order?.routes &&
            payload?.data?.order?.routes[0]?.driver,
          driverRouteTwo:
            payload?.data?.order?.routes &&
            payload?.data?.order?.routes[1]?.driver,
        },
      };
    }
    case Types.GET_EMPLOPYEES_SUCCESS: {
      return {
        ...state,
        employees: payload?.data?.employees,
      };
    }
    case Types.ASSIGN_TO_EMPLOYEE_SUCCESS:
    case Types.ASSIGN_TO_FREELANCER_SUCCESS: {
      let newRouteOneData = state?.routeOne;
      let newRouteTwoData = state?.routeTwo;

      if (payload?.data?.route?.leg_type == "start") {
        newRouteOneData = payload?.data?.route;
      } else {
        newRouteTwoData = payload?.data?.route;
      }
      return {
        ...state,
        route: payload?.data?.route,
        routeOne: newRouteOneData,
        routeTwo: newRouteTwoData,
        orderStatus: payload?.data?.route?.order?.status,
        numberOfRequests: payload?.data?.number_of_requests,
        driver: payload?.data?.route?.driver,
        drivers: {
          driverRouteOne:
            payload?.data?.route?.leg_type == "start" &&
            payload?.data?.route?.driver,
          driverRouteTwo:
            payload?.data?.route?.leg_type == "finished" &&
            payload?.data?.route?.driver,
        },
      };
    }
    case Types.CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
        orderStatus: payload?.data?.order?.status,
        routesStatusChanges: payload?.data?.order?.order_status_changes,
      };
    }
    case Types.UNASSIGN_DRIVER_SUCCESS: {
      let newRouteOneData = state?.routeOne;
      let newRouteTwoData = state?.routeTwo;

      if (payload?.data?.route?.leg_type == "start") {
        newRouteOneData = payload?.data?.route;
      } else {
        newRouteTwoData = payload?.data?.route;
      }
      return {
        ...state,
        route: payload?.data?.route,
        routeOne: newRouteOneData,
        routeTwo: newRouteTwoData,
        orderStatus: payload?.data?.route?.order?.status,
        driver: payload?.data?.route?.driver,
      };
    }
    case Types.SET_ORDER_READY_FOR_DELIVERY: {
      return {
        ...state,
        orderStatus: payload?.data?.order?.status,
      };
    }
    case Types.GET_DRIVER_COMPLAINS_DETAILS_SUCCESS: {
      return {
        ...state,
        routeComplain: payload?.data?.route_complaint,
      };
    }
    case Types.UPDATE_DRIVER_COMPLAINS_SUCCESS: {
      return {
        ...state,
        routeComplain: payload?.data?.route_complaint,
      };
    }
    case Types.GET_FREELANCER_SUCCESS: {
      let drivers = payload?.data?.order_requests?.map((data) => {
        return {
          ...data?.driver,
          fees: data && data?.delivery_fees,
        };
      });
      return {
        ...state,
        // need to test
        employees: drivers,
      };
    }
    case Types.GET_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        orderStatus: payload?.data?.order?.status
      };
    }
    default: {
      return state;
    }
  }
}
