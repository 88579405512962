import React, { useRef } from "react";
import "./FileInput.css";

function FileInput(props) {
    const hiddenFileInput = useRef(null);
    const resetValue = () => {
        hiddenFileInput.current.value = null
    }
    return (
        <>
            <div className={!props.errorTxt ? props.btnStyle : props.btnStyle + " error-border"} onClick={() => hiddenFileInput?.current?.click()} style={props?.style}>
                {props.icon && (
                    <img
                        className={props.iconStyle}
                        src={props.icon}
                    />
                )}
                {props.uploadText && !props.fileName && (
                    <span className={props.textStyle}>
                        {props.uploadText}
                    </span>
                )}
                {props.fileName && (
                    <span className={props.textStyle}>
                        {props.fileName.length > 15 ? props.fileName.slice(0, 15) + "..." : props.fileName}
                    </span>
                )}
                <input
                    ref={hiddenFileInput}
                    hidden
                    type="file"
                    onChange={(e) => props.change(e, resetValue)}
                    className="file-input"
                    accept={props.accept}
                />
            </div>
            {props.errorTxt && (
                <p
                    style={{
                        fontSize: "0.75rem",
                        color: "var(--formik-error)",
                        margin: "3px 14px"
                    }}
                >
                    {props.errorTxt}
                </p>
            )}
        </>
    );
}

export default FileInput;