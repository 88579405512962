import { useDispatch, useSelector } from "react-redux";
import VendorRegistrationTemplate from "../../templates/vendor/vendorRegistration/vendorRegistration";
import { getScrollTagsRequest } from "services/modules/lookups/tags";

function VendorRegistration() {
  const dispatch = useDispatch();

  const filterTags = useSelector((state) => state.tags.filterTags);
  const tagsCount = useSelector((state) => state.tags.listCount);
  const tagsLoad = useSelector((state) => state.tags.load);

  const getTags = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollTagsRequest(requestData));
  };

  const options = {
    tags: {
      options: filterTags,
      onOpen: filterTags?.length <= 1 && getTags,
      isLoading: tagsLoad?.loading && tagsLoad?.type == "list-filter-tags",
      getList: getTags,
      count: tagsCount,
    },
  };

  return (
    <>
      <VendorRegistrationTemplate options={options} />
    </>
  );
}

export default VendorRegistration;
