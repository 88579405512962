import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import ShapesTemplate from "templates/wrappers/Shapes";
import {
  GetShapeRequest,
  addShapeRequest,
  deleteShapeRequest,
  getShapeDetailsRequest,
  updateShapeRequest,
} from "services/modules/Wrappers/shapes";
import {
  GetWrapsRequest,
  getScrollWrapsRequest,
} from "services/modules/Wrappers/Wraps";
import { toast } from "react-toastify";

function Shapes() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.shapes.shapes);
  const filterWraps = useSelector((state) => state.wrappers.filterWraps);
  const count = useSelector((state) => state.shapes.count);
  const wrappersCount = useSelector((state) => state.wrappers.listCount);
  const wrapsList = useSelector((state) => state.wrappers.wraps);
  const wrapsLoad = useSelector((state) => state.wrappers.load);
  const details = useSelector((state) => state.shapes.shape);
  const load = useSelector((state) => state.shapes.load);

  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.shapes
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);

  const [filterObj, setFilterObj] = useState();

  const getWraps = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollWrapsRequest(requestData));
  };
  const getWrapsList = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetWrapsRequest(requestData));
  };
  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getShapeDetailsRequest({ shape_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetShapeRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      image: (
        <img src={data?.image} alt={data?.name} width="108px" height="108px" />
      ),
      wrap: data?.wrap?.name,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/shapes",
      title: "wrappers/shape",
    },
    type && {
      active: false,
      title:
        type === "details" ? "Shape details" : id ? "Edit Shape" : "New shape",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/shapes");
      !id && formik?.resetForm();
      toast.success(
        `New shape has been ${id ? "updated" : "created"} successfully`
      );
    };
    const requestData = {
      body: { shape: { ...formik?.values } },
      action,
    };
    if (id) {
      dispatch(updateShapeRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(addShapeRequest(requestData));
    }
  };

  const filterList = [
    {
      type: "filter",
      placeholder: "Wrap",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, role_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterWraps?.length == 0 && getWraps({});
      },
      options: filterWraps && filterWraps,
      getList: getWraps,
      applySearchFromRequest: true,
      count: wrappersCount,
    },
  ];

  const handleDelete = (id) => {
    // const id = selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      if (type == "details") {
        navigate(-1);
      }
      toast.success("Shape has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteShapeRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const headerChildren = <></>;
  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const options = {
    wraps: {
      options: [details?.wrap, ...filterWraps],
      onOpen: filterWraps?.length <= 1 && getWraps,
      isLoading: wrapsLoad?.loading && wrapsLoad?.type == "wraps-scroll-list",
      getList: getWraps,
      count: wrappersCount,
    },
  };
  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <ShapesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        wrapsOptionsListArray={wrapsList}
        selectedRow={selectedRow}
        can_create={isSuperAdmin || permissions?.can_create}
        getWrapsList={getWrapsList}
        options={options}
        filterWraps={filterWraps}
      />
    </>
  );
}

export default Shapes;
