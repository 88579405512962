import { Alert, Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import setAuthToken from "services/modules/utils/handel_api";
import CloseIcon from "@mui/icons-material/Close";
import CFormErrors from "./CFormErrors";

function CUpload({ placeholder, btnStyle, inputStyle, btnText, type, id, label, name, formik, changeAction, text }) {
  const {
    load: { loading },
  } = useSelector((state) => state?.files);
  const [fileUrl, setFileUrl] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const { orange, textGray, oliveGreen } = useThemePalette();
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);
  const maxUploadSize = useSelector((state) => state?.systemConfig?.systemConfigs).filter((ele) => ele?.key == "max_upload_size")[0];

  const dispatch = useDispatch();
  const errorsMsg = formik?.errors[name];

  useEffect(() => {
    formik?.values[name] && setFileUrl(formik?.values[name]);
  }, [formik?.values[name]]);

  const handleClose = () => {
    setFileUrl(null);
    changeAction("");
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      uploadFile(files[0], type);
    }
  };
  const uploadFile = async (file, type) => {
    const imageExe = ["png", "jpg", "jpeg"];
    const extension = file?.type.slice(file?.type.indexOf("/") + 1);
    try {
      if (file.size / 1024 > +maxUploadSize?.value) {
        setError(`file size must be less than ${maxUploadSize?.value}KB`);
        return;
      }
      if (!imageExe?.includes(extension)) {
        setError("file must be png , jpg or jpeg");
        return;
      }
      setError(null);
      setOpenLoader(true);
      const requestData = {
        body: {
          files: [
            {
              extension: file?.type.slice(file?.type.indexOf("/") + 1),
            },
          ],
        },
        file: file,
        updateData: (data) => {
          changeAction(data);
          setFileUrl(data);
        },
        action: (data) => {
          setOpenLoader(false);
          const token = localStorage.getItem("token");
          setAuthToken(token);
        },
      };
      dispatch(getPresignedUrlRequest({ ...requestData }));
    } catch (error) {
      console.error("Error in uploadFile:", error);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file, type);
    }
  };
  // console.log(formik?.errors[name]);
  useEffect(() => {
    setError(formik?.errors[name]);
  }, [formik?.errors]);

  return (
    <Grid container>
      <Stack sx={{ mb: "15px" }}>
        <Typography sx={{ fontFamily: "Jost", fontSize: "32px", fontWeight: "400" }}>{label}</Typography>
      </Stack>
      {fileUrl || formik?.values?.[name] ? (
        <Grid
          Grid
          item
          xs={12}
          sx={{
            border: " 1px dashed rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            position: "relative",
            minHeight: "200px",
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            // height='100%'
            minHeight="214px" // Set a fixed height for the stack
          >
            <Box
              component="img"
              src={fileUrl || formik?.values?.[name]}
              alt="img"
              width={type == "icon" ? "95px" : "100%"}
              height={type == "icon" ? "95px" : "100%"}
              maxHeight="214px"
              sx={{
                borderRadius: "10px",
              }}
            />
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "48px",
              height: "48px",
              bgcolor: "white",
              borderRadius: "50%",
              filter: "drop-shadow(0px 0px 4px rgba(185, 185, 185, 0.25))",
              transform: "translate(50%, -30%)",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Stack>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              border: " 1px dashed rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <label htmlFor={`upload-${id}`}>
              <input id={`upload-${id}`} name={`upload-${id}`} type="file" style={{ display: "none" }} onChange={handleFileInputChange} />
              <Stack alignItems="center" rowGap={"24px"} py="3.5rem">
                {loading && openLoader ? (
                  <CircularProgress sx={{ color: oliveGreen }} />
                ) : (
                  <>
                    <img src={icons?.uploadIcon} alt="upload icon" />
                    <Typography fontSize="13px">Select a file or drag and drop here</Typography>
                    {text && (
                      <Typography fontSize="12px" color={textGray}>
                        {text} {maxUploadSize?.value} KB
                      </Typography>
                    )}
                    <Button
                      component="span"
                      sx={{
                        color: orange,
                        border: `1px solid ${orange}`,
                        fontSize: "15px",
                        px: "16px",
                      }}
                    >
                      Select File
                    </Button>
                  </>
                )}
              </Stack>
            </label>
          </Grid>
          <CFormErrors errorsMsg={error || errorsMsg} />
        </>
      )}
    </Grid>
  );
}

export default CUpload;
