import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography } from "@mui/material";

export default function FormHeader({ title, handleClose, padding , textStyle,}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        // zIndex: 100,
        p: padding || 4,
      }}
    >
      {title && (
        <Typography fontSize="32px" fontWeight="500" sx={{...textStyle}}>
          {title}
        </Typography>
      )}
      <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
    </Stack>
  );
}
