import Types from "./Types";

export const GetDriversSuccess = (payload) => ({
  type: Types.GET_DRIVERS_SUCCESS,
  payload,
});

export const DriversLoading = (payload) => ({
  type: Types.DRIVERS_LOADING,
  payload,
});


export const AddDriverSuccess = (payload) => ({
  type: Types.ADD_DRIVER_SUCCESS,
  payload,
});

export const UpdateDriverSuccess = (payload) => ({
  type: Types.UPDATE_DRIVER_SUCCESS,
  payload,
});

export const GetDriverDetailsSuccess = (payload) => ({
  type: Types.GET_DRIVER_DETAILS_SUCCESS,
  payload,
});


export const DeleteDriverSuccess = (payload) => ({
  type: Types.DELETE_DRIVER_SUCCESS,
  payload,
});

