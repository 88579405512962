export const BaseURL = process.env.REACT_APP_BASE_URL;
export const ApiKey = process.env.REACT_APP_API_KEY;
export const GoogleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const AuthDomain = process.env.REACT_APP_AUTH_DOMAIN;
export const DatabaseUrl = process.env.REACT_APP_DATABASE_URL;
export const ProjectId = process.env.REACT_APP_PROJECT_ID;
export const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
export const MessagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const AppId = process.env.REACT_APP_APP_ID;
export const MeasurementId = process.env.REACT_APP_MEASUREMENT_ID;
export const FirebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const FirebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FirebaseDatabaseUrl = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FirebaseProjestId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FirebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FirebaseSenderID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FirebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
export const FirebaseMeasurmentId = process.env.REACT_APP_FIREBASE_MEASURMENT_ID;
export const FirebaseVAPIDKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
export const StoreUser = 'Store'
export const AdminUser = 'Admin'
export const SuperAdmin = 'AdminUser'
export const CustomerToCadeau = 'customer_to_cadeau'
export const CadeauToStore = 'cadeau_to_stores'
export const CadeauToCustomer = 'cadeau_to_customer'
export const Delivered = 'delivered'
export const Completed = 'completed'
