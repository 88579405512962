import Types from "./Types";

export const isOpenAddAddress = () => ({
  type: Types.IS_OPEN_ADD_ADDRESS,
});

export const isOpenAssignToEmployee = (payload) => ({
  type: Types.IS_OPEN_ASSIGN_TO_EMPLOYEE,
  payload,
});

export const isOpenAssignToFreelancer = (payload) => ({
  type: Types.IS_OPEN_ASSIGN_TO_FREELANCER,
  payload,
});

export const isOpenDriverDetails = () => ({
  type: Types.IS_OPEN_DRIVER_DETAILS,
});

export const isOpenViewComplain = (payload) => ({
  type: Types.IS_OPEN_VIEW_COMPLAIN,
  payload
});

export const isOpenFollowesModel = (payload) => ({
  type: Types.IS_OPEN_FOLLOWES_MODEL,
  payload
});

export const isOpenFollowingModel = (payload) => ({
  type: Types.IS_OPEN_FOLLOWING_MODEL,
  payload
});


export const CloseSuccessModal = () => ({
  type: Types.CLOSE_SUCCESS_MODAL,
});

export const isOpenPreviewChatImg = (payload) => ({
  type: Types.IS_OPEN_CHAT_PREVIEW_IMG,
  payload
})

export const isOpenApproveItemModel = (payload) => ({
  type: Types.IS_OPEN_APPROVE_ITEM_MODEL,
  payload
})

export const isOpenRejectItemModel = (payload) => ({
  type: Types.IS_OPEN_REJECT_ITEM_MODEL,
  payload
})

export const isOpenRejectionDetailsModel = (payload) => ({
  type: Types.IS_OPEN_REJECTION_DETAILS_MODEL,
  payload
})
export const isOpenViewDestinationModel = (payload) => ({
  type: Types.IS_OPEN_VIEW_DESTINATION_MODEL,
  payload
})