const usersTypes = {
  //! admin-users
  // list
  GET_ADMIN_USERS_SUCCESS: "GET_ADMIN_USERS_SUCCESS",
  // details
  GET_ADMIN_USER_DETAILS_SUCCESS: "GET_ADMIN_USER_DETAILS_SUCCESS",
  // create
  CREATE_ADMIN_USER_SUCCESS: "CREATE_ADMIN_USER_SUCCESS",
  // Delete
  DELETE_ADMIN_USER_SUCCESS: "DELETE_ADMIN_USER_SUCCESS",
  // update
  UPDATE_ADMIN_USER_SUCCESS: "UPDATE_ADMIN_USER_SUCCESS",
  //change password
  CHANGE_PASSWORD_SSUCCESS: "CHANGE_PASSWORD_SSUCCESS",
  //! roles
  // list
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  ROLES_LOADING: "ROLES_LOADING",
  // details
  GET_ROLE_DETAILS_SUCCESS: "GET_ROLE_DETAILS_SUCCESS",
  // create
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  // Delete
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  // update
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  //! resources
  // list
  GET_RESOURCES_SUCCESS: "GET_RESOURCES_SUCCESS",
  GET_SCROLL_ROLES_SUCCESS: "GET_SCROLL_ROLES_SUCCESS",

};

export default usersTypes;
