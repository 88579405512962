import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import DriverComplaintsReasonsTemplate from "../../../templates/driver-complaints-reasons";
import {
  addDriverComplaintReasonRequest,
  deleteDriverComplaintReasonRequest,
  getDriverComplaintReasonDetailsRequest,
  getDriverComplaintReasonsRequest,
  updateDriverComplaintReasonRequest,
} from "../../../services/modules/lookups/complaints/driverComplaintReasons";

function DriverComplaintsReasonsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.driverComplaintReasons.driverComplaintReasons);
  const count = useSelector((state) => state.driverComplaintReasons.count);
  const details = useSelector((state) => state.driverComplaintReasons.driverComplaintReason);
  const load = useSelector((state) => state.driverComplaintReasons.load);

  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.route_complaints
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getDriverComplaintReasonDetailsRequest({ id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getDriverComplaintReasonsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      created_at: <>{dayjs(details?.created_at).format("DD-MM-YYYY HH:mm")}</>,
      updated_at: <>{dayjs(details?.updated_at).format("DD-MM-YYYY HH:mm")}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/driver-complaint-reasons",
      title: "Complaints Reasons For Driver",
    },
    type && {
      active: false,
      title: type === "details" ? "Details" : id ? "Edit Complaint Reason" : "Add Complaint Reason",
    },
  ];
  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/driver-complaint-reasons");
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(`Complaint Reason has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: {
        route_complaint_reason: {
          ...formik?.values,
        },
      },
      action,
    };

    if (id) {
      //update call
      dispatch(updateDriverComplaintReasonRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(addDriverComplaintReasonRequest(requestData));
    }
  };

  const filterList = [];

  const handleDelete = (id) => {
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/driver-complaint-reasons");
      toast.success("Complaint Reason deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteDriverComplaintReasonRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all complaint Reasons
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single complaint reason
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const token = localStorage.getItem("token");
  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <DriverComplaintsReasonsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        can_create={isSuperAdmin || permissions?.can_create}
      />
    </>
  );
}

export default DriverComplaintsReasonsPage;
