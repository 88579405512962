/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  vendors: [],
  filterVendors: [],
  vendor: {},
  count: 0,
  listCount: 0,
  load: null,
  error: null,
};

export function vendorsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_VENDORS_SUCCESS: {
      return {
        ...state,
        vendors: payload?.data?.stores,
        count: payload?.extra?.total_count,
      };
    }
    case Types.VENDORS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_VENDORS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_VENDORS_SUCCESS: {
      return {
        ...state,
      };
    }

    case Types.UPDATE_VENDORS_SUCCESS: {
      const updatedVendor = payload?.data?.store;
      let vendors;
      if (updatedVendor?.details?.status == "deleted") {
        vendors = state?.vendors?.filter((vendor) => vendor?.id !== updatedVendor?.id);
      } else {
        vendors = state?.vendors?.map((vendor) => {
          if (vendor?.id === updatedVendor?.id) {
            return updatedVendor;
          }
          return vendor;
        });
      }
      return {
        ...state,
        vendors,
        vendor: updatedVendor,
      };
    }
    case Types.GET_VENDORS_DETAILS_SUCCESS: {
      return {
        ...state,
        vendor: payload?.data?.store,
      };
    }
    case Types.GET_SCROLL_VENDORS_SUCCESS: {
      return {
        ...state,
        filterVendors:
          payload?.extra?.page_number == 1 ? payload?.data?.stores : [...state.filterVendors, ...payload?.data?.stores],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
