import { makeFilterString } from "../utils/Helper";
import {
  GetSystemConfigLoading,
  GetSystemConfigSuccess,
  GetSystemConfigDetailsSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getSystemConfigRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetSystemConfigLoading,
    loadingType: "list",
    successFunction: GetSystemConfigSuccess,
    url: `/admin/lookups/system_configurations?${makeFilterString(
      variables?.params
    )}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateSystemConfigRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetSystemConfigLoading,
    loadingType: "update",
    url: `/admin/lookups/system_configurations/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "patch",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getSystemConfigDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetSystemConfigLoading,
    loadingType: "details",
    successFunction: GetSystemConfigDetailsSuccess,
    url: `/admin/lookups/system_configurations/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
