const modalTypes = {
  IS_OPEN_ADD_ADDRESS: "IS_OPEN_ADD_ADDRESS",
  IS_OPEN_ASSIGN_TO_EMPLOYEE: "IS_OPEN_ASSIGN_TO_EMPLOYEE",
  IS_OPEN_ASSIGN_TO_FREELANCER: 'IS_OPEN_ASSIGN_TO_FREELANCER',
  IS_OPEN_DRIVER_DETAILS: 'IS_OPEN_DRIVER_DETAILS',
  IS_OPEN_VIEW_COMPLAIN: 'IS_OPEN_VIEW_COMPLAIN',
  IS_OPEN_FOLLOWES_MODEL: 'IS_OPEN_FOLLOWES_MODEL',
  IS_OPEN_FOLLOWING_MODEL: 'IS_OPEN_FOLLOWING_MODEL',
  CLOSE_SUCCESS_MODAL: "CLOSE_SUCCESS_MODAL",
  IS_OPEN_CHAT_PREVIEW_IMG: 'IS_OPEN_CHAT_PREVIEW_IMG',
  IS_OPEN_APPROVE_ITEM_MODEL: 'IS_OPEN_APPROVE_ITEM_MODEL',
  IS_OPEN_REJECT_ITEM_MODEL: 'IS_OPEN_REJECT_ITEM_MODEL',
  IS_OPEN_REJECTION_DETAILS_MODEL: 'IS_OPEN_REJECTION_DETAILS_MODEL',
  IS_OPEN_VIEW_DESTINATION_MODEL: 'IS_OPEN_VIEW_DESTINATION_MODEL'
};
export default modalTypes;
