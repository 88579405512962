import { Box, Stack, Typography } from '@mui/material';
import { icons } from 'assets/AssetHelper'
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Color Name(EN)',
                name: data?.localized_data?.en?.name,
            },
            {
                label: 'Color Name(AR)',
                name: data?.localized_data?.ar?.name,
            },
            {
                label: 'Presentation',
                name: (<Stack direction='row' columnGap='8px' >
                    <Box width='24px' height='24px' bgcolor={data?.presentation} borderRadius='50%' >
                    </Box>
                    <Typography color={'#1a1a1a'} fontFamily='Jost' >{data?.presentation}</Typography>
                </Stack>),
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.datesIcons,
        details: [
            {
                label: 'Created AT',
                name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            },
            {
                label: 'Updated At',
                name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a")
            },
        ]
    },
];