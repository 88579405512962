import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import WrapsTemplate from "templates/wrappers/Wraps";
import {
  GetWrapsRequest,
  addWrapRequest,
  deleteWrapRequest,
  getWrapDetailsRequest,
  updateWrapRequest,
} from "services/modules/Wrappers/Wraps";
import setAuthToken from "services/modules/utils/handel_api";
import { toast } from "react-toastify";

function Wraps() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.wrappers.wraps);
  const count = useSelector((state) => state.wrappers.count);
  const details = useSelector((state) => state.wrappers.wrap);
  const load = useSelector((state) => state.wrappers.load);

  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.wraps
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getWrapDetailsRequest({ wrap_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetWrapsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      // button: <Button variant="outline">test</Button>,
      image: (
        <img src={data?.image} alt={data?.name} width='108px' height='108px' />
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/wraps",
      title: "wrappers/Wraps",
    },
    type && {
      active: false,
      title:
        type === "details" ? "Wrap details" : id ? "Edit Wrap" : "New Wrap",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      !id && formik?.resetForm();
      toast.success(`wrap ${id ? "updated" : "created"}  successfully`);
      navigate("/wraps");
    };
    const requestData = {
      body: {
        wrap: {
          ...formik?.values,
          dimensions: formik?.values?.dimensions == 1 ? 0 : 1,
        },
      },
      action,
    };
    if (id) {
      //update call
      dispatch(updateWrapRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(addWrapRequest(requestData));
    }
  };

  const filterList = [];

  const handleDelete = (id) => {
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      if (type == "details") {
        navigate(-1);
      }
      toast.success("Wrap deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteWrapRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <WrapsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        can_create={isSuperAdmin || permissions?.can_create}
      />
    </>
  );
}

export default Wraps;
