import {Box, Button, Modal, Stack, TextField, Typography} from '@mui/material'
import { useThemePalette } from 'common/hooks/theme_palette'
import React, {useState} from 'react'
import Model from "../../../Model/Model";
import TextFieldInput from "../../../Input/TextField/TextFieldInput";
import CModel from "../../Model/CModel";
import ModelBox from "../../../Model/Model";

export default function RejectionModel({openRejectionReason,setOpenRejectionReason,handleRejectionSubmit }) {
    const { textGray,oliveGreen } = useThemePalette()
    const openEdit=()=>{
        setOpenRejectionReason(prevState => ({
            ...prevState,
            type: 'edit',
        }))
    }


    return (
        <Stack flexDirection={'row'}>
        <CModel titleStyle={{fontSize:'32px',fontWeight:500,color:openRejectionReason.type==='reject'&&'red'}}
                title={openRejectionReason.type==='view'? 'Reject Reason':
                    openRejectionReason.type==='edit'?'Edit Rejection Reason':
                        'Reject Vendor' }
                openVar={openRejectionReason.action}
                closeFunc={()=>setOpenRejectionReason({action:false,reason:'',type:''})} >
        <Stack gap={3}>
            {(openRejectionReason.type==='reject')&&<Typography fontWeight={500} fontSize={20}>Write Rejection Reason</Typography>}
        <TextField InputProps={{
            readOnly:openRejectionReason.type==='view'&&true,
        }} multiline rows={4}  value={openRejectionReason.reason}
                   onChange={e=>setOpenRejectionReason(prevState => ({
            ...prevState,
            reason: e.target.value,
        }))}  ></TextField>
        <Stack flexDirection={'row'} gap={3} justifyContent={'center'}>
            <Button variant={'outlined'}
                    sx={{width:'5%',color:'black',borderColor:'black','&:hover':{color:'black',borderColor:'black'}}}
                    onClick={()=>{setOpenRejectionReason({action:false,reason:'',type:''})
                    }}
            >Cancel</Button>
            <Button
                variant={'contained'}
                sx={{width:'5%',background:oliveGreen,"&:hover":{background:oliveGreen}}}
                onClick={openRejectionReason.type==='view'?openEdit:handleRejectionSubmit}
            >{openRejectionReason.type==='view'?'Edit': 'Submit'}</Button>
        </Stack>
        </Stack>
    </CModel>
        </Stack>
        )
}
