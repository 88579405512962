import Types from "./Types";

export const FeesBetweenZonesLoading = (payload) => ({
  type: Types.FEES_BETWEEN_ZONES_LOADING,
  payload,
});

//! BETWEEN ZONES FEES
export const GetBetweenZonesFeesSuccess = (payload) => ({
  type: Types.GET_BETWEEN_ZONES_FEES_SUCCESS,
  payload,
});

export const GetBetweenZonesFeeDetailsSuccess = (payload) => ({
  type: Types.GET_BETWEEN_ZONES_FEE_DETAILS_SUCCESS,
  payload,
});
