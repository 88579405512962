/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  tags: [],
  filterTags: [],
  tag: {},
  count: 0,
  listCount: 0,
  load: null,
};

export function tagsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_TAGS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: payload?.data?.tags,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_TAG_DETAILS_SUCCESS: {
      return {
        ...state,
        tag: payload?.data?.tag,
      };
    }
    case Types.GET_SCROLL_TAGS_SUCCESS: {
      return {
        ...state,
        filterTags: payload?.extra?.page_number == 1
          ? payload?.data?.tags
          : [...state.filterTags, ...payload?.data?.tags],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
