import { makeFilterString } from "../../utils/Helper";
import {
  AddWrapSuccess,
  DeleteWrapsSuccess,
  GetScrollWrapsSuccess,
  GetWrapDetailsSuccess,
  GetWrapsLoading,
  GetWrapsSuccess,
  UpdateWrapsSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetWrapsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapsLoading,
    loadingType: "list",
    successFunction: GetWrapsSuccess,
    url: `/admin/wrappers/wraps${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getScrollWrapsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapsLoading,
    loadingType: "wraps-scroll-list",
    successFunction: GetScrollWrapsSuccess,
    url: `/admin/wrappers/wraps${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const deleteWrapRequest = ({ ...variables }) => {
  console.log(variables,'variables')
  const requestData = {
    isLoading: GetWrapsLoading,
    successFunction: DeleteWrapsSuccess,
    loadingType: "delete wrap",
    action: variables?.action,
    url: `/admin/wrappers/wraps/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const addWrapRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapsLoading,
    loadingType: "add",
    successFunction: AddWrapSuccess,
    action: variables?.action,
    url: `/admin/wrappers/wraps`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateWrapRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapsLoading,
    loadingType: "update wrap",
    successFunction: UpdateWrapsSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/wrappers/wraps/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getWrapDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapsLoading,
    loadingType: "details",
    successFunction: GetWrapDetailsSuccess,
    url: `/admin/wrappers/wraps/${variables?.wrap_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




