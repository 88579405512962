import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useEffect, useState } from "react";
import NotificationsList from "./NotificationsList";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationsRequest,
  getUnreadCountRequest,
} from "services/modules/notifications/notifications";

export default function Notifications({ notificationUnseenCount }) {
  const dispatch = useDispatch();
  const { orange } = useThemePalette();
  const userType = localStorage.getItem('user_type')
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const list = useSelector((state) => state.notifications.notifications);
  const count = useSelector((state) => state.notifications.count);
  const countOfUnread = useSelector((state) => state.notifications.countOfUnread);
  const load = useSelector((state) => state.notifications.load);
  const token = localStorage.getItem('token')
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getList(filterObj);
  };

  const handleClose = () => {
    getUnreadCount();
    setFilterObj(null);
    setAnchorEl(null);
  };



  const notificationTypes = ({ id, type }) => {
    switch (type) {
      case 'new_message':
        return { path: `/messenger?id=${id}`, icon: icons.messengerIcon }
      case 'new_vendor':
        return { path: `/vendors?type=details&id=${id}`, icon: icons.vendorIcon }
      case 'order_status_changed':
      case 'driver_arrived':
      case 'change_order':
      case 'order_status_change':
        return { path: `/orders?type=details&id=${id}`, icon: icons.ordersIcon }
      case 'product_status_update':
      case 'new_product':
        return { path: `/products?type=details&id=${id}`, icon: icons.productsIcon }
      case 'return_request_status_changed':
      case 'return_request_in_progress':
        return { path: `/return-request?type=details&id=${id}`, icon: icons.productsIcon }
      //open return request detail screen
      case 'new_order_complaint':
        return { path: `/customer-Complaints?type=details&id=${id}`, icon: icons.complain }
      case 'new_driver_complaint':
        return { path: `/driver-Complaints?type=details&id=${id}`, icon: icons.complain }
      case 'vendor_status_update':
        return { path: false, icon: icons.vendorIcon }
      default:
    }
  };

  const handlePagination = () => {
    setPage((prev) => prev + 1);
    setFilterObj((prev) => {
      return { ...prev, page_number: page + 1 };
    });
  };

  const handleMarkAllAsRead = () => {
    // getUnreadCount();
    //to mark as seen the latest notifications
    getList({ ...filterObj, page_number: 1 });
  };

  const getUnreadCount = () => {
    dispatch(getUnreadCountRequest({}));
  };

  const getList = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    token && dispatch(getNotificationsRequest(requestData));
  };

  useEffect(() => {
    token && setTimeout(() => getList(filterObj), 500)
  }, [filterObj, token]);
  return (
    <Box sx={{ position: "relative" }}>
      <Stack sx={{ cursor: "pointer" }} onClick={handleClick}>
        {notificationUnseenCount !== 0 && (
          <Stack
            sx={{
              position: "absolute",
              backgroundColor: orange,
              borderRadius: "50%",
              width: "18px",
              height: "18px",
              justifyContent: "center",
              alignItems: "center",
              right: "-2px",
              color: "white",
            }}
          >
            <Typography fontSize={"12px"}>{notificationUnseenCount < 9 ? notificationUnseenCount : "+9"}</Typography>
          </Stack>
        )}

        <Box component="img" src={icons.notifications} />
      </Stack>
      <NotificationsList
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        notifications={list}
        load={load}
        count={count}
        filterObj={filterObj}
        handlePagination={handlePagination}
        isFetch={count > list?.length}
        notificationTypes={notificationTypes}
        handleMarkAllAsRead={handleMarkAllAsRead}
        countOfUnread={notificationUnseenCount}
      />
    </Box>
  );
}
