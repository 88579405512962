import Types from "./Types";


// ----------------- SHAPES START-----------------
export const GetRibbonColorsSuccess = (payload) => ({
  type: Types.GET_RIBBON_COLORS_SUCCESS,
  payload,
});

export const GetRibbonColorsLoading = (payload) => ({
  type: Types.RIBBON_COLORS_LOADING,
  payload,
});

export const AddRibbonColorSuccess = (payload) => ({
  type: Types.ADD_RIBBON_COLOR_SUCCESS,
  payload,
});

export const AddRibbonColorFailed = (payload) => ({
  type: Types.ADD_RIBBON_COLOR_FAILED,
  payload,
});


export const DeleteRibbonColorSuccess = (payload) => ({
  type: Types.DELETE_RIBBON_COLOR_SUCCESS,
  payload,
});

export const DeleteRibbonColorFailed = (payload) => ({
  type: Types.DELETE_RIBBON_COLOR_FAILED,
  payload,
});

export const UpdateRibbonColorSuccess = (payload) => ({
  type: Types.UPDATE_RIBBON_COLOR_SUCCESS,
  payload,
});

export const GetRibbonColorDetailsSuccess = (payload) => ({
  type: Types.GET_RIBBON_COLOR_DETAILS_SUCCESS,
  payload,
});

export const GetScrollRibbonColorSuccess = (payload) => ({
  type: Types.GET_SCROLL_RIBBON_COLOR_SUCCESS,
  payload,
});

// ----------------- SHAPES END-----------------
