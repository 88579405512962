import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Header from "../Details/header";
import Images from "../Details/images";
import DetailsList from "../Details/detailsList";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";
const DetailsUse = ({ data, setOpenDeleteModal, children, customNav }) => {
  const { borderColor, orange, textGray, graySex } =
    useThemePalette();
  return (
    <Stack>
      <Header
        title={data?.pageTitle}
        isBackward={data?.isBackward}
        backwardBtn={data?.backwardBtn}
        children={data?.children}
        editBtn={data?.editBtn}
        deleteBtn={data?.deleteBtn}
        setOpenDeleteModal={setOpenDeleteModal}
        customNav={customNav}
      />

      {data?.data?.images?.length > 0 && (
        <Stack
          direction="row"
          p="20px"
          sx={{ boxSizing: "border-box", gap: 1 }}
          bgcolor={"white"}
          borderRadius=" 4px"
          width={data?.data?.images?.length > 1 ? "100%" : "45%"}
          justifyContent="space-between"
          mb="20px"
        >
          {data?.data?.images?.map((data) => (
            <Images data={data} />
          ))}
        </Stack>
      )}
      <Stack bgcolor={"white"} borderRadius="4px">
        <Stack direction="row" justifyContent={"end"} gap="12px">
          {data?.data?.checkBox?.map(
            (checkBox, index, array) =>
              checkBox?.checked && (
                <Stack
                  direction="row"
                  gap="4px"
                  sx={{
                    bgcolor: checkBox?.bgcolor || graySex,
                    px: "21px",
                    py: "8px",
                  }}
                  borderRadius={array?.length - 1 == index && "0 4px 0 0"}
                >
                  {!checkBox?.icon ? (
                    <Box>
                      <Box
                        component="img"
                        src={checkBox?.checked && icons?.success}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <Box component="img" src={checkBox?.icon} />
                    </Box>
                  )}
                  <Typography
                    color={checkBox?.textColor || textGray}
                    fontSize="18px"
                  >
                    {checkBox?.label}
                  </Typography>
                </Stack>
              )
          )}
        </Stack>
        {data?.data?.list &&
          data?.data?.list?.map(
            (data, index, array) =>
              data && (
                <Box
                  borderBottom={
                    index !== array?.length - 1 && `1px solid ${borderColor}`
                  }
                >
                  <DetailsList data={data} />
                </Box>
              )
          )}
      </Stack>
    </Stack>
  );
};

export default DetailsUse;
