import { makeFilterString } from "../../utils/Helper";
import { reduxRequest } from "common/utils/reduxRequest";
import { AddOccasionsTypesSuccess, DeleteOccasionsTypesSuccess,GetOccasionTypesLoading, GetOccasionTypesSuccess, GetOccasionsTypesDetailsSuccess,  GetScrollOccasionsTypesSuccess,  UpdateOccasionsTypesSuccess,  } from "./Action";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetOccasionTypesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOccasionTypesLoading,
    loadingType: "list",
    successFunction: GetOccasionTypesSuccess,
    url: `/admin/lookups/occasion_types${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetScrollOccasionTypesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOccasionTypesLoading,
    loadingType: "occasion-type-filter-list",
    successFunction: GetScrollOccasionsTypesSuccess,
    url: `/admin/lookups/occasion_types${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteOccasionTypesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOccasionTypesLoading,
    successFunction: DeleteOccasionsTypesSuccess,
    loadingType: "delete wrap",
    action: variables?.action,
    url: `/admin/lookups/occasion_types/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const addOccasionTypesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOccasionTypesLoading,
    loadingType: "add",
    successFunction: AddOccasionsTypesSuccess,
    action: variables?.action,
    url: `/admin/lookups/occasion_types`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




export const updateOccasionTypesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOccasionTypesLoading,
    loadingType: "update shape",
    successFunction: UpdateOccasionsTypesSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/lookups/occasion_types/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const getOccasionTypesDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOccasionTypesLoading,
    loadingType: "details",
    successFunction: GetOccasionsTypesDetailsSuccess,
    url: `/admin/lookups/occasion_types/${variables?.occasion_type_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




