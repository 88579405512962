import styled from "@emotion/styled";
import { TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  borderTop: "5px solid #FAFAFA",
}));
export const StickyClass = (index, mainStyle) => {
  return {
    position: "sticky",
    left: index == 0 ? 0 : 70,
    zIndex: "10",
    background: "white",
    ...mainStyle,
  };
};
export const TableCellStyle = (index, columns,isDetails) => {
  return {
    color: "#1A1A1A",
    fontWeight: "400",
    fontSize: "16px",
    // fontFamily: "Nunito",
    position: "relative",
    textTransform: "capitalize",
    // cursor: index === 0 && columns[0]?.clickable ? "pointer" : "default",
    cursor:isDetails && index === 0 && "pointer",
  };
};
export const TableContainerStyle = {
  maxWidth: { xs: '100%', lg: "100%" },
  boxShadow: "none",
  borderRadius: "8px 8px 0px 0px",
  // overflowX: { xs: "scroll", lg: "hidden" },
  // overflowY: { xs: "scroll", lg: "hidden" },
};
