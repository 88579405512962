import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { images } from "assets/AssetHelper";
import { detailsData } from "./details";

const ProductsOptionTypesTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  can_create,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Products Option Types",
            data: {
              images: [{ id: 0, img: images.Logo }],
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data, // data
            listTitle: "Products Option Types", //title
            createBtnTitle: can_create && "Add Option Type", // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "localized_data.en.name",
                Header: "Option Type(EN)",
                sort: true,
              },
              {
                accessor: "localized_data.ar.name",
                Header: "Option Type(AR)",
                sort: true,
              },
              { accessor: "created_at", Header: "Created At", sort: true },
              { accessor: "updated_at", Header: "Updated At", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search by tag name",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(supportedLang, [{ id: "1", name: "test" }]),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit  Option Type" : "Add Option Type",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ProductsOptionTypesTemplate;
