import { Typography } from '@mui/material';
import { icons } from 'assets/AssetHelper'
import * as dayjs from "dayjs";
import ColorRow from "components/Atoms/SharedComponents/Wrappers/Colors/ColorRow";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Ribbon Color Name(EN)',
                name: data?.localized_data?.en?.name,
                width:'30%'
            },
            {
                label: 'Ribbon Color Name(AR)',
                name: data?.localized_data?.ar?.name,
                width:'30%'
            },
            {
                label: 'Presentation',
                name: <ColorRow data={data} />,
                width:'30%'
            },
        ]
    },
    {
        head: 'Prices',
        icon: icons?.priceIcon,
        details: [
            {
                label: 'Price',
                name: `${data?.price} ${data?.currency?.name}`,
                width:'30%'
            },
            {
                label: 'Price after discount',
                name: `${data?.price_after_discount} ${data?.currency?.name}`,
                width:'30%'
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.datesIcons,
        details: [
            {
                label: 'Created AT',
                name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            },
            {
                label: 'Updated At',
                name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a")
            },
        ]
    },
];