import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { images } from "../../../assets/AssetHelper";
import { detailsData } from "./details";
import ConfirmationModal from "../../../components/Molecules/Models/ConfirmationModal";
import { useThemePalette } from "../../../common/hooks/theme_palette";

const CustomerComplaintsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  rolesOptions,
  handleConfirmation,
  confirmBtnTitle,
  color,
  btnColor,
  can_create,
}) => {
  const navigate = useNavigate();
  const { red } = useThemePalette();

  console.log(confirmBtnTitle, color, btnColor);
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleClick = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };
  const handleBackward = () => {
    navigate("/customer-complaints");
  };

  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Complaints Details",
            isBackward: true,
            backwardBtn: handleBackward,
            children:
              details?.status === "open" ? (
                <Button
                  variant={"outlined"}
                  sx={{
                    color: "black",
                    borderColor: "black",
                    background: "white",
                    "&:hover": { borderColor: "black", background: "white" },
                  }}
                  onClick={handleClick}
                >
                  Mark as resolved
                </Button>
              ) : (
                <Button
                  variant={"outlined"}
                  sx={{
                    color: "red",
                    borderColor: "red",
                    "&:hover": { borderColor: "red", background: "transparent" },
                  }}
                  onClick={handleClick}
                >
                  Unresolved
                </Button>
              ),
            deleteBtn: false,
            editBtn: false,
            data: {
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data,
            listTitle: "Customer Complaints ",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              { accessor: "customer.name", Header: "Customer Name", sort: true },
              {
                accessor: "order_id",
                Header: "Order Num",
                sort: true,
              },
              { accessor: "created_at", Header: "Date And Time", sort: true },
              { accessor: "status", Header: "Status", sort: true },
              { accessor: "Actions", Header: "Action" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search by tag name",
            isChecked: false,
            total_count: count,
            isSort: true,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,

            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(supportedLang, rolesOptions),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit User" : "New User",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
        {openConfirmationModal && (
          <ConfirmationModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            handleCancellation={() => setOpenConfirmationModal(false)}
            text={[
              `Mark complain as  ${details?.status === "open" ? "resolved" : "unresolved"}`,
              `Are you sure you want to Mark complaint as ${
                details?.status === "open" ? "resolved" : "unresolved"
              }?`,
            ]}
            confirmBtnTitle={details?.status === "open" ? "Resolved" : "Unresolved"}
            color={"black"}
            btnColor={details?.status === "open" ? "#3FABAE" : red}
            handleConfirmation={handleConfirmation}
          ></ConfirmationModal>
        )}
      </Box>
    </>
  );
};

export default CustomerComplaintsTemplate;
