const productOptionValuesTypes={
    //LOADING
    PRODUCT_OPTION_VALUES_LOADING:"PRODUCT_OPTION_VALUES_LOADING",
    //LISTING
    GET_PRODUCTS_OPTION_VALUES_SUCCESS: "GET_PRODUCTS_VALUES_TYPES_SUCCESS",
    //CREATION
    SET_PRODUCT_OPTION_VALUE_SUCCESS: "SET_PRODUCT_OPTION_VALUE_SUCCESS",
    //DETAILS
    GET_PRODUCTS_OPTION_VALUE_DETAILS_SUCCESS: "GET_PRODUCTS_OPTION_VALUE_DETAILS_SUCCESS",
    //UPDATE
    UPDATE_PRODUCTS_OPTION_VALUE_SUCCESS: "UPDATE_PRODUCTS_OPTION_VALUE_SUCCESS",
    //DELETE
    DELETE_PRODUCTS_OPTION_VALUE_SUCCESS: "DELETE_PRODUCTS_OPTION_VALUE_SUCCESS",
}
export default productOptionValuesTypes